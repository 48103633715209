import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
// import moment from 'moment/min/moment-with-locales';
// import moment from 'moment'
import 'moment/min/locales'
import { useTheme } from "@material-ui/core/styles";
// import { makeStyles } from '@material-ui/core/styles';
import {
  // Avatar,
  // Button,
  // Chip,
  // Container,
  FormControlLabel,
  Switch,
  // TextField
} from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 200,
//   },
// }));

export default function UsersApp() {

  // const classes = useStyles();

  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    // addUser,
    editUserApp,
    deleteUserApp,
    updateState,
    updatePrime,
    updateLlevamos,
    updateNoLlevamos,
    updateBonos,
    updateStateWhatsappOrder,
    // updateAsignar,
    // lunesAct,
    // martesAct,
    // miercolesAct,
    // juevesAct,
    // viernesAct,
    // sabadoAct,
    // domingoAct,
    activoAct
    // checkUserExists
  } = api;
  const [data, setData] = useState([]);
  // console.log("setData " , setData)
  // const [cars, setCars] = useState({});
  const usersdata = useSelector(state => state.usersdata);
  const usersappdata = useSelector(state => state.usersappdata);
  // console.log("usersappdata " , usersappdata)
  // const cartypes = useSelector(state => state.cartypes);
  // const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  // console.log("settings " , settings)
  const dispatch = useDispatch();

  const theme = useTheme()

  useEffect(() => {
    if (usersappdata.users) {
      // setData(usersdata.users.filter(user => user.role ==='Manager' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin')|| auth.info.profile.usertype === 'admin')));
      // setData(usersappdata.users.filter(user => user.role ==='Manager' && user.city ==='CARTAGO' ));
      // setData(usersappdata.users.filter(user => user.city ==='CARTAGO' ).sort((a,b) => a.mobileNo-b.mobileNo));
      setData(usersappdata.users.filter(user => user.city === 'CARTAGO')
      // .sort(
      //   function(a, b) {          
      //      if (a.estado === b.estado) {
      //         // Price is only important when cities are the same
      //         return b.mobileNo - a.mobileNo;
      //      }
      //      return a.estado > b.estado ? -1 : 1;
      //   })
        );
      // setData(usersappdata.users);
    } else {
      setData([]);
    }
  }, [usersappdata.users]);
  // console.log("data", data)

  // const [newData, setNewData] = useState([]);
  // const [counter, setCounter] = useState(0);

  // useEffect(() => {
  //   if (newData.length) {
  //     changeRows(newData);
  //     //tableRef.current.onQueryChange(null);
  //   }
  // }, [counter]);

  const changeActiveBono = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    if (newRow) {
      dispatch(updateBonos({
        activeBonds: newRow.activeBonds,
        booking: newRow.id,
        // cancelledBy: role
      }));
    }
  };

  const changePrime = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    if (newRow) {
      dispatch(updatePrime({
        llevoacasaprime: newRow.llevoacasaprime,
        booking: newRow.id,
        // cancelledBy: role
      }));
    }
  };
  const changeState = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    if (newRow) {
      dispatch(updateState({
        estado: newRow.estado,
        booking: newRow.id,
        // cancelledBy: role
      }));
    }
  };

  const changeWhatsappOrder = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    if (newRow) {
      dispatch(updateStateWhatsappOrder({
        whatsappOrder: newRow.whatsappOrder,
        booking: newRow.id,
        // cancelledBy: role
      }));
    }
  };

  const changeLlevamos = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    if (newRow) {
      dispatch(updateLlevamos({
        llevamos: newRow.llevamos,
        booking: newRow.id,
        // cancelledBy: role
      }));
    }
  };

  const changeNoLlevamos = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    if (newRow) {
      dispatch(updateNoLlevamos({
        nollevamos: newRow.nollevamos,
        booking: newRow.id,
        // cancelledBy: role
      }));
    }
  };

  // const changeAsignar = (oldRow, e) => {
  //   const changeData = { [e.target.name]: e.target.checked };
  //   const newRow = { ...oldRow, ...changeData };
  //   // console.log(oldRow ,e);
  //   const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
  //   const newData = data;
  //   newData[index] = newRow;
  //   if (newRow) {
  //     dispatch(updateAsignar({
  //       asignar: newRow.llevamos,
  //       booking: newRow.id,
  //       // cancelledBy: role
  //     }));
  //   }
  // };

  const changeActivo = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    // console.log("changeData",changeData)
    // console.log("oldRow",oldRow)
    const newRow = { ...oldRow, ...changeData };
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    // console.log("newRow",newRow)
    if (newRow) {
      dispatch(activoAct({
        activo: newRow.activo,
        booking: newRow.id,
      }));
    }
  };

  // const changeLunesActivo = (oldRow, e) => {
  //   const changeData = { [e.target.name]: e.target.checked };
  //   console.log("changeData",changeData)
  //   console.log("oldRow",oldRow)
  //   const newRow = { ...oldRow, ...changeData };
  //   const newRowActive = { ...oldRow.deliveryInfo, ...changeData };
  //   const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
  //   console.log("index",index)
  //   const newData = data;
  //   console.log("newData",newData)
  //   newData[index] = newRow;
  //   console.log("newRow",newRow)
  //   newData[index] = newRowActive;
  //   console.log("newRowActive",newRowActive)
  //   if (newRow) {
  //     dispatch(lunesAct({
  //       lunesActive: newRowActive.lunesActive,
  //       booking: newRow.id,
  //     }));
  //   }
  // };
  // const changeMartesActivo = (oldRow, e) => {
  //   const changeData = { [e.target.name]: e.target.checked };
  //   const newRow = { ...oldRow, ...changeData };
  //   const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
  //   const newData = data;
  //   newData[index] = newRow;
  //   if (newRow) {
  //     dispatch(martesAct({
  //       martesActive: newRow.martesActive,
  //       booking: newRow.id,
  //     }));
  //   }
  // };
  // const changeMiercolesActivo = (oldRow, e) => {
  //   const changeData = { [e.target.name]: e.target.checked };
  //   const newRow = { ...oldRow, ...changeData };
  //   const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
  //   const newData = data;
  //   newData[index] = newRow;
  //   if (newRow) {
  //     dispatch(miercolesAct({
  //       miercolesActive: newRow.miercolesActive,
  //       booking: newRow.id,
  //     }));
  //   }
  // };
  // const changeJuevesActivo = (oldRow, e) => {
  //   const changeData = { [e.target.name]: e.target.checked };
  //   const newRow = { ...oldRow, ...changeData };
  //   const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
  //   const newData = data;
  //   newData[index] = newRow;
  //   if (newRow) {
  //     dispatch(juevesAct({
  //       juevesActive: newRow.juevesActive,
  //       booking: newRow.id,
  //     }));
  //   }
  // };
  // const changeViernesActivo = (oldRow, e) => {
  //   const changeData = { [e.target.name]: e.target.checked };
  //   const newRow = { ...oldRow, ...changeData };
  //   const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
  //   const newData = data;
  //   newData[index] = newRow;
  //   if (newRow) {
  //     dispatch(viernesAct({
  //       viernesActive: newRow.viernesActive,
  //       booking: newRow.id,
  //     }));
  //   }
  // };
  // const changeSabadoActivo = (oldRow, e) => {
  //   const changeData = { [e.target.name]: e.target.checked };
  //   const newRow = { ...oldRow, ...changeData };
  //   const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
  //   const newData = data;
  //   newData[index] = newRow;
  //   if (newRow) {
  //     dispatch(sabadoAct({
  //       sabadoActive: newRow.sabadoActive,
  //       booking: newRow.id,
  //     }));
  //   }
  // };
  // const changeDomingoActivo = (oldRow, e) => {
  //   const changeData = { [e.target.name]: e.target.checked };
  //   const newRow = { ...oldRow, ...changeData };
  //   const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
  //   const newData = data;
  //   newData[index] = newRow;
  //   if (newRow) {
  //     dispatch(domingoAct({
  //       domingoActive: newRow.domingoActive,
  //       booking: newRow.id,
  //     }));
  //   }
  // };


  const columns = [
    { title: "#", render: rowData => rowData.tableData.id + 1 },
    // { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null},
    { title: '%', field: 'percentage', type:'numeric', initialEditValue: 0 },
    { title: 'Orden', field: 'mobileNo', type:'numeric', initialEditValue: 0 },
    { title: 'Imágen', field: 'iconoPerfil', render: rowData => rowData.iconoPerfil ? <img alt='Profile' src={rowData.iconoPerfil} style={{ width: 50, borderRadius: '50%' }} /> : null },
    { title: 'Nombre', field: 'name', initialEditValue: '' },
    // { title: 'Cerrar/Abrir', field: 'estado', type: 'boolean', initialEditValue: false },
    // { title: "Estado", field: 'estado', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 16, color: rowData.estado === true ? 'green' : rowData.estado === false ? 'red' : null }}>{rowData.estado === true ? 'ABIERTO' : 'CERRADO'}</span> },
    {
      title: "Cerrar/Abrir",
      field: "estado",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.estado}
              onChange={e => changeState(data, e)}
              name="estado"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.estado === true ? 'green' : data.estado === false ? 'red' : null }}>{data.estado === true ? 'ABIERTO' : 'CERRADO'}</span>}
        />
      )
    },
    {
      title: "Pedido x whatsapp",
      field: "whatsappOrder",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.whatsappOrder}
              onChange={e => changeWhatsappOrder(data, e)}
              name="whatsappOrder"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.whatsappOrder === true ? 'green' : data.whatsappOrder === false ? 'red' : null }}>{data.whatsappOrder === true ? 'ACTIVO' : 'INACTIVO'}</span>}
        />
      )
    },
    // {
    //   title: "Asignar",
    //   field: "asignar",
    //   render: (data, id) => (
    //     <FormControlLabel
    //       control={
    //         <Switch
    //           checked={data.asignar}
    //           onChange={e => changeAsignar(data, e)}
    //           name="llevamos"
    //           color="primary"
    //         />
    //       }
    //       label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.asignar === true ? 'green' : data.asignar === false ? 'red' : null }}>{data.asignar === true ? 'ACTIVO' : 'INACTIVO'}</span>}
    //     />
    //   )
    // },
    {
      title: "Llevamos",
      field: "llevamos",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.llevamos}
              onChange={e => changeLlevamos(data, e)}
              name="llevamos"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.llevamos === true ? 'green' : data.llevamos === false ? 'red' : null }}>{data.llevamos === true ? 'ACTIVO' : 'INACTIVO'}</span>}
        />
      )
    },
    {
      title: "Llevamos Disponibilidad",
      field: "nollevamos",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.nollevamos}
              onChange={e => changeNoLlevamos(data, e)}
              name="nollevamos"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.nollevamos === true ? 'green' : data.nollevamos === false ? 'red' : null }}>{data.nollevamos === true ? 'SIN DOMI' : 'CON DOMI'}</span>}
        />
      )
    },
    {
      title: "Prime",
      field: "llevoacasaprime",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.llevoacasaprime}
              onChange={e => changePrime(data, e)}
              name="llevoacasaprime"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.llevoacasaprime === true ? 'green' : data.llevoacasaprime === false ? 'red' : null }}>{data.llevoacasaprime === true ? 'ACTIVO' : 'INACTIVO'}</span>}
        />
      )
    },
    {
      title: "Bono Activo",
      field: "activeBonds",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.activeBonds}
              onChange={e => changeActiveBono(data, e)}
              name="activeBonds"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.activeBonds === true ? 'green' : data.activeBonds === false ? 'red' : null }}>{data.activeBonds === true ? 'ACTIVO' : 'INACTIVO'}</span>}
        />
      )
    },
    { title: 'Correo', field: 'email', editable: 'never' },
    { title: 'Horario', field: 'address', render: rowData => settings.AllowCriticalEditsAdmin ? rowData.address : "Hidden for Demo" },
    // { title: 'Domicilio',  field: 'profile_image', render: rowData => rowData.profile_image?<img alt='Profile' src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null, editable:'never'},
    { title: 'Filtro 1', field: 'nuevoFiltro1.nuevoFiltro1filtro', initialEditValue: '' },
    { title: 'Filtro 1 orden', field: 'nuevoFiltro1.nuevoFiltro1orden', type:'numeric', initialEditValue: 0 },
    { title: 'Filtro 2', field: 'nuevoFiltro2.nuevoFiltro2filtro', initialEditValue: '' },
    { title: 'Filtro 2 orden', field: 'nuevoFiltro2.nuevoFiltro2orden', type:'numeric', initialEditValue: 0 },
    { title: 'Filtro 3', field: 'nuevoFiltro3.nuevoFiltro3filtro', initialEditValue: '' },
    { title: 'Filtro 3 orden', field: 'nuevoFiltro3.nuevoFiltro3orden',type:'numeric', initialEditValue: 0 },
    // { title: 'Filtro 3',  field: 'licenseImage',render: rowData => rowData.licenseImage?<img alt='License' src={rowData.licenseImage} style={{width: 100}}/>:null, editable:'never'},
    // { title: 'Filtro 3 orden',  field: 'walletBalance' , render: rowData => <span>{rowData.walletBalance ? "$" + rowData.walletBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    // { title: 'Categoria', render: rowData => <span>{rowData.ratings?rowData.ratings.userrating: "0"}</span>,  initialEditValue: 0 },
    { title: 'Categoria', field: 'superCategoria', initialEditValue: '' },
    { title: 'Ciudad', field: 'city', initialEditValue: '' },
    // { title: 'Ciudad', field: 'signupViaReferral', editable:'never' },
    { title: 'Dirección', field: 'direccion', initialEditValue: '' },
    { title: 'Ubicación', render: rowData => <span>{rowData.locationInfo ? rowData.locationInfo.lat + " " + rowData.locationInfo.lng + " " : ""}</span> },
    { title: 'Barrio', field: 'barrio', initialEditValue: '' },
    { title: 'Encargado y/o Administrador', field: 'administrador', initialEditValue: '' },
    { title: 'Teléfono', field: 'telefono', initialEditValue: '' },
    { title: 'Descripción', field: 'description', initialEditValue: '' },
    // { title: 'Domicilio Libre (DIRECTO)', field: 'domiDirecto', type: 'boolean', initialEditValue: false },
    // { title: 'Domicilio por LLevo a Casa (LLEVAMOS)', field: 'llevamos', type: 'boolean', initialEditValue: false },

    // { title: 'Asignar pedido', field: 'asignar', type: 'boolean', initialEditValue: false },

    { title: 'Costo Envío Mínimo', field: 'envioMinimo', type:'numeric', render: rowData => <span>{rowData.envioMinimo ? "$" + rowData.envioMinimo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: 'Costo por Tramo', field: 'envioKm', type:'numeric', render: rowData => <span>{rowData.envioKm ? "$" + rowData.envioKm.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },

    { title: 'Kms por Tramo', field: 'tramoKm', type:'numeric', initialEditValue: '' },

    { title: 'Costo Envío Mínimo Domi', type:'numeric', field: 'envioMinimoDomi', render: rowData => <span>{rowData.envioMinimoDomi ? "$" + rowData.envioMinimoDomi.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },

    { title: 'Costo por Tramo Domi', type:'numeric', field: 'envioKmDomi', render: rowData => <span>{rowData.envioKmDomi ? "$" + rowData.envioKmDomi.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },

    { title: 'Km por Tramo Domi', type:'numeric', field: 'tramoKmDomi', initialEditValue: '' },

    { title: 'Modificar Configuración', field: 'modificar', type: 'boolean', initialEditValue: false },
    // { title: 'Bono Activo', field: 'activeBonds', type: 'boolean', initialEditValue: false },
    // { title: 'Prime', field: 'llevoacasaprime', type: 'boolean', initialEditValue: false },

    // { title: 'Activar Establecimiento (Domicilios)', field: 'activo', type: 'boolean', initialEditValue: false },
    // { title: 'Activar Auto Abrir/Cerrar', field: 'activo', type: 'boolean', initialEditValue: false },
    {
      title: "Activar Auto Abrir/Cerrar",
      field: "activo",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.activo}
              onChange={e => changeActivo(data, e)}
              name="activo"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.activo === true ? 'green' : data.activo === false ? 'red' : null }}>{data.activo === true ? 'ACTIVO' : 'INACTIVO'}</span>}
        />
      )
    },

    { title: 'Lunes Activo', field: 'deliveryInfo.lunesActive', type: 'boolean', initialEditValue: false },
    // {
    //   title: "Lunes Activo",
    //   field: "deliveryInfo.lunesActive",
    //   render: (data, id) => (
    //     <FormControlLabel
    //       control={
    //         <Switch
    //           checked={data.deliveryInfo.lunesActive}
    //           onChange={e => changeLunesActivo(data, e)}
    //           name="deliveryInfo.lunesActive"
    //           color="primary"
    //         />
    //       }
    //       label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.deliveryInfo.lunesActive === true ? 'green' : data.deliveryInfo.lunesActive === false ? 'red' : null }}>{data.deliveryInfo.lunesActive === true ? 'ACTIVO' : 'INACTIVO'}</span>}
    //     />
    //   )
    // },
    { title: 'Lunes Apertura', field: 'deliveryInfo.lunes', initialEditValue: '' },
    { title: 'Lunes Cierre', field: 'deliveryInfo.lunesClose', initialEditValue: '' },

    { title: 'Martes Activo', field: 'deliveryInfo.martesActive', type: 'boolean', initialEditValue: false },
    // {
    //   title: "Martes Activo",
    //   field: "deliveryInfo.martesActive",
    //   render: (data, id) => (
    //     <FormControlLabel
    //       control={
    //         <Switch
    //           checked={data.deliveryInfo.martesActive}
    //           onChange={e => changeMartesActivo(data, e)}
    //           name="deliveryInfo.martesActive"
    //           color="primary"
    //         />
    //       }
    //       label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.deliveryInfo.martesActive === true ? 'green' : data.deliveryInfo.martesActive === false ? 'red' : null }}>{data.deliveryInfo.martesActive === true ? 'ACTIVO' : 'INACTIVO'}</span>}
    //     />
    //   )
    // },
    { title: 'Martes Apertura', field: 'deliveryInfo.martes', initialEditValue: '' },
    { title: 'Martes Cierre', field: 'deliveryInfo.martesClose', initialEditValue: '' },

    { title: 'Miercoles Activo', field: 'deliveryInfo.miercolesActive', type: 'boolean', initialEditValue: false },
    // {
    //   title: "Miercoles Activo",
    //   field: "deliveryInfo.miercolesActive",
    //   render: (data, id) => (
    //     <FormControlLabel
    //       control={
    //         <Switch
    //           checked={data.deliveryInfo.miercolesActive}
    //           onChange={e => changeMiercolesActivo(data, e)}
    //           name="deliveryInfo.miercolesActive"
    //           color="primary"
    //         />
    //       }
    //       label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.deliveryInfo.miercolesActive === true ? 'green' : data.deliveryInfo.miercolesActive === false ? 'red' : null }}>{data.deliveryInfo.miercolesActive === true ? 'ACTIVO' : 'INACTIVO'}</span>}
    //     />
    //   )
    // },
    { title: 'Miercoles Apertura', field: 'deliveryInfo.miercoles', initialEditValue: '' },
    { title: 'Miercoles Cierre', field: 'deliveryInfo.miercolesClose', initialEditValue: '' },

    { title: 'Jueves Activo', field: 'deliveryInfo.juevesActive', type: 'boolean', initialEditValue: false },
    // {
    //   title: "Jueves Activo",
    //   field: "deliveryInfo.juevesActive",
    //   render: (data, id) => (
    //     <FormControlLabel
    //       control={
    //         <Switch
    //           checked={data.deliveryInfo.juevesActive}
    //           onChange={e => changeJuevesActivo(data, e)}
    //           name="deliveryInfo.juevesActive"
    //           color="primary"
    //         />
    //       }
    //       label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.deliveryInfo.juevesActive === true ? 'green' : data.deliveryInfo.juevesActive === false ? 'red' : null }}>{data.deliveryInfo.juevesActive === true ? 'ACTIVO' : 'INACTIVO'}</span>}
    //     />
    //   )
    // },
    { title: 'Jueves Apertura', field: 'deliveryInfo.jueves', initialEditValue: '' },
    { title: 'Jueves Cierre', field: 'deliveryInfo.juevesClose', initialEditValue: '' },

    { title: 'Viernes Activo', field: 'deliveryInfo.viernesActive', type: 'boolean', initialEditValue: false },
    // {
    //   title: "Viernes Activo",
    //   field: "deliveryInfo.viernesActive",
    //   render: (data, id) => (
    //     <FormControlLabel
    //       control={
    //         <Switch
    //           checked={data.deliveryInfo.viernesActive}
    //           onChange={e => changeViernesActivo(data, e)}
    //           name="deliveryInfo.viernesActive"
    //           color="primary"
    //         />
    //       }
    //       label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.deliveryInfo.viernesActive === true ? 'green' : data.deliveryInfo.viernesActive === false ? 'red' : null }}>{data.deliveryInfo.viernesActive === true ? 'ACTIVO' : 'INACTIVO'}</span>}
    //     />
    //   )
    // },
    { title: 'Viernes Apertura', field: 'deliveryInfo.viernes', initialEditValue: '' },
    { title: 'Viernes Cierre', field: 'deliveryInfo.viernesClose', initialEditValue: '' },

    { title: 'Sabado Activo', field: 'deliveryInfo.sabadoActive', type: 'boolean', initialEditValue: false },
    // {
    //   title: "Sabado Activo",
    //   field: "deliveryInfo.sabadoActive",
    //   render: (data, id) => (
    //     <FormControlLabel
    //       control={
    //         <Switch
    //           checked={data.deliveryInfo.sabadoActive}
    //           onChange={e => changeSabadoActivo(data, e)}
    //           name="deliveryInfo.sabadoActive"
    //           color="primary"
    //         />
    //       }
    //       label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.deliveryInfo.sabadoActive === true ? 'green' : data.deliveryInfo.sabadoActive === false ? 'red' : null }}>{data.deliveryInfo.sabadoActive === true ? 'ACTIVO' : 'INACTIVO'}</span>}
    //     />
    //   )
    // },
    { title: 'Sabado Apertura', field: 'deliveryInfo.sabado', initialEditValue: '' },
    { title: 'Sabado Cierre', field: 'deliveryInfo.sabadoClose', initialEditValue: '' },

    { title: 'Domingo Activo', field: 'deliveryInfo.domingoActive', type: 'boolean', initialEditValue: false },
    // {
    //   title: "Domingo Activo",
    //   field: "deliveryInfo.domingoActive",
    //   render: (data, id) => (
    //     <FormControlLabel
    //       control={
    //         <Switch
    //           checked={data.deliveryInfo.domingoActive}
    //           onChange={e => changeDomingoActivo(data, e)}
    //           name="deliveryInfo.domingoActive"
    //           color="primary"
    //         />
    //       }
    //       label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.deliveryInfo.domingoActive === true ? 'green' : data.deliveryInfo.domingoActive === false ? 'red' : null }}>{data.deliveryInfo.domingoActive === true ? 'ACTIVO' : 'INACTIVO'}</span>}
    //     />
    //   )
    // },
    { title: 'Domingo Apertura', field: 'deliveryInfo.domingo', initialEditValue: '' },
    { title: 'Domingo Cierre', field: 'deliveryInfo.domingoClose', initialEditValue: '' },
    { title: 'Key', field: 'storeId', editable: 'never' },
    { title: 'PushToken', field: 'pushToken', editable: 'never', initialEditValue: '' },

  ];

  return (
    usersdata.loading ? <CircularLoading /> :
      <MaterialTable
        title={'Establecimientos App Usuarios'}
        columns={columns}
        data={data}
        options={{
          // toolbar: false,
          exportButton: settings.AllowCriticalEditsAdmin,
          // sorting: true,
          pageSize: 20,
          search: true,
          searchFieldAlignment: "left",
          // searchAutoFocus: true,
          // searchFieldVariant: 'outlined',
          headerStyle: {
            backgroundColor: "#EFE9E9",
            color: theme.palette.common.black,
            fontWeight: 'bold',
            textAlign: 'center',
            flexDirection: 'row',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'sticky',
            top: 0,
          },
          maxBodyHeight: '900px',
        }}
        editable={{
          // onRowAdd: newData =>
          // new Promise((resolve,reject) => {
          //   setTimeout(() => {
          //     checkUserExists(newData).then((res) => {
          //       if (res.users && res.users.length > 0) {
          //         alert(t('user_exists'));
          //         reject();
          //       }
          //       else if(res.error){
          //         alert(t('email_or_mobile_issue'));
          //         reject();
          //       }
          //       else{
          //         newData['createdByAdmin'] = true;
          //         newData['usertype'] = 'driver';
          //         newData['createdAt'] = new Date().toISOString();
          //         newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
          //         let role = auth.info.profile.usertype;
          //         if(role === 'fleetadmin'){
          //           newData['fleetadmin'] = auth.info.uid; 
          //         }
          //         dispatch(addUser(newData));
          //         resolve();
          //       }
          //     });
          //   }, 600);
          // }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editUserApp(oldData.id, newData));
              }, 600);
            }),
          onRowDelete: oldData =>
            settings.AllowCriticalEditsAdmin ?
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  dispatch(deleteUserApp(oldData.id));
                }, 600);
              })
              :
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  alert(t('demo_mode'));
                }, 600);
              })
          ,
        }}
      />
  );
}
