import React, { useState, useEffect } from 'react';
import {
    // Grid,
    Paper,
    Typography
} from '@material-ui/core';
// import DashboardCard from '../components/DashboardCard';
import Map from '../components/MapSector';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GridItem from "components/Grid/GridItem.js";

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DashboardSector = () => {
    const [mylocation, setMylocation] = useState(null);
    const [locations, setLocations] = useState([]);
    // console.log("locations", locations)

    const { t } = useTranslation();
    const usersdata = useSelector(state => state.usersdata);
    const bookinglistdata = useSelector(state => state.bookinglistdata);
    const sectordata = useSelector(state => state.sectordata.simple);
    // console.log("sectordata", sectordata)
    // const [data, setData] = useState([]);

    // useEffect(() => {
    //     if (sectordata.simple) {
    //         setData(sectordata.simple);
    //     } else {
    //         setData([]);
    //     }
    //     // setLocations(data);
    // }, [sectordata.simple]);
    // console.log("data", data)

    useEffect(() => {
        if (mylocation == null) {
            navigator.geolocation.getCurrentPosition(
                position => setMylocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }),
                error => console.log(error)
            );
        }
    }, [mylocation]);

    // const initialState = sectordata[13]
    const [input, setInput] = useState();
    // console.log("input", input)

    useEffect(() => {
        if (sectordata) {
            let locs = [];
            for (let i = 0; i < sectordata.length; i++) {
                const element = sectordata[i];
                locs.push({
                    id: i,
                    lat: element.lat,
                    lng: element.lng,
                    drivername: element.description,
                });
            }
            // setLocations(locs.slice(0,5));
            setLocations(locs.splice(Math.floor(Math.random() * locs.length),3));
        }
    }, [sectordata]);
    // console.log("locations", locations)




    return (
        bookinglistdata.loading || usersdata.loading ? <CircularLoading /> :
            <div>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Paper style={{ marginBottom: '10px' }} >
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            variant={"outlined"}
                            options={sectordata}
                            disableCloseOnSelect
                            // defaultValue={[sectordata[13], sectordata[12], sectordata[11]]}
                            // filterSelectedOptions
                            getOptionLabel={(valuesec) => valuesec.description}
                            // value={input}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.description}
                                </React.Fragment>
                            )}
                            onChange={(event, newValue) => {
                                setInput(newValue ? newValue : sectordata);
                                if (newValue) {
                                    newValue = { lat: newValue.lat, lng: newValue.lng, drivername: newValue.description }
                                }
                                // console.log("newValue ", newValue)
                            }}
                            renderInput={params => (
                                <TextField {...params} label="Buscar barrio" variant="outlined" fullWidth />
                            )}
                        />
                    </Paper>
                </GridItem>
                {mylocation ?
                    <Paper style={{ marginTop: '1px' }}>
                        {/* <Typography variant="h4" style={{ margin: "20px 0 0 15px" }}>{t('real_time_driver_section_text')}</Typography> */}
                        <Map mapcenter={mylocation} locations={input ? input : locations}
                            loadingElement={<div style={{ height: `600px` }} />}
                            containerElement={<div style={{ height: `600px` }} />}
                            mapElement={<div style={{ height: `600px` }} />}
                        />
                    </Paper>
                    :
                    <Typography variant="h6" style={{ margin: "20px 0 0 15px", color: '#FF0000' }}>{t('allow_location')}</Typography>
                }
            </div>

    )
}

export default DashboardSector;