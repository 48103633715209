import React, {
  useState,
  useEffect,
  useContext,
  useRef
} from 'react';
import MaterialTable from 'material-table';
import {
  useSelector,
  useDispatch
} from "react-redux";
import CircularLoading from "../components/CircularLoading";
// import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
// import moment from 'moment/min/moment-with-locales';
import { useTheme } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import DashboardCard from '../components/DashboardCard';
import moment from 'moment/min/moment-with-locales';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { FirebaseContext } from 'common';

import {
  Grid,
  // Paper,
  // Typography
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from '@material-ui/icons/AddBox';
import GetAppIcon from '@material-ui/icons/GetApp';
// import PaymentIcon from '@material-ui/icons/Payment';


const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 480,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: 'center'
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: 'center'
  },
  items: {
    margin: 0,
    width: '100%'
  },
  input: {
    fontSize: 18,
    color: "#000"
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373"
  },
  carphoto: {
    height: '18px',
    marginRight: '10px'
  },
  buttonStyle: {
    margin: 0,
    width: '100%',
    height: '100%'
  }
}));

export default function BookingHistoryPayment() {
  const { t, i18n } = useTranslation();
  const { api } = useContext(FirebaseContext);
  const {
    senAllOrders,
    // editSms,
  } = api;
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const [dataUsers, setDataUsers] = useState([]);
  const [dataUsersComision, setDataUsersComision] = useState([]);
  const [dataMesConsulta, setDataMesConsulta] = useState(0);
  const [dataMesComision, setDataMesComision] = useState(0);
  // const [dataTotalPedidos, setDataTotalPedidos] = useState(0);

  const [dataBooking, setDataBooking] = useState([]);
  const [dataBookingComision, setDataBookingComision] = useState([]);

  const allorderslistdata = useSelector(state => state.allorderslistdata);
  // console.log("allorderslistdata", allorderslistdata)

  const orderslistdata = useSelector(state => state.orderslistdata);
  // console.log("orderslistdata", orderslistdata)
  const usersappdatacomplex = useSelector(state => state.usersappdatacomplex);
  // console.log("usersappdatacomplex", usersappdatacomplex)
  const loaded = useRef(false);
  const loadedComision = useRef(false);
  const classes = useStyles();
  const [dailyCount, setDailyCount] = useState(0);
  const [yesterdayCount, setYesterdayCount] = useState(0);
  const [monthlyCount, setMonthlyCount] = useState(0);
  const [dailygrossValor, setDailygrossValor] = useState(0);
  const [yesterdayGrossValor, setYesterdaygrossValor] = useState(0);
  const [monthlygrossValor, setMonthlygrossValor] = useState(0);

  const [selectedDateInicio, setSelectedDateInicio] = useState();
  // console.log("selectedDateInicio ", selectedDateInicio)
  const [selectedDateInicioValue, setSelectedDateInicioValue] = useState(null);
  const [selectedDateInicioMoment, setSelectedDateInicioMoment] = useState(null);
  // console.log("selectedDateInicioMoment ", selectedDateInicioMoment)
  const [selectedDateFin, setSelectedDateFin] = useState();
  // console.log("selectedDateFin ", selectedDateFin)
  const [selectedDateFinValue, setSelectedDateFinValue] = useState(null);
  const [selectedDateFinMoment, setSelectedDateFinMoment] = useState(null);
  const [servicesCount, setServicesCount] = useState(0);
  const [totalgrossSelected, setTotalgrossCompleteSelected] = useState(0);

  const [dataFecha, setDataFecha] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (usersappdatacomplex.users) {
      setDataUsers(usersappdatacomplex.users.filter(user => user.city === 'CARTAGO'));
    } else {
      setDataUsers([]);
    }
    loaded.current = true;
  }, [usersappdatacomplex.users]);
  // console.log("dataUsers", dataUsers)

  useEffect(() => {
    if (usersappdatacomplex.users) {
      setDataUsersComision(usersappdatacomplex.users.filter(user => user.city === 'CARTAGO'));
    } else {
      setDataUsersComision([]);
    }
    loadedComision.current = true;
  }, [usersappdatacomplex.users]);
  // console.log("dataUsersComision", dataUsersComision)



  useEffect(() => {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let primerDiaMesActual = Math.floor(new Date(firstDay).getTime());
    // console.log("primerDiaMesActual", primerDiaMesActual)
    if (orderslistdata.bookings) {
      setDataBookingComision(orderslistdata.bookings.filter(order => order.createdAt > primerDiaMesActual));
    } else {
      setDataBookingComision([]);
    }
  }, [orderslistdata.bookings]);
  //   console.log("dataBooking", dataBooking)
  useEffect(() => {
    if (dataBookingComision && dataUsersComision) {
      const usersBooking = [];
      const valueBooking = [];
      const renderobj = {};
      const valueobj = {};
      for (let j = 0; j < dataBookingComision.length; j++) {
        if (dataBookingComision[j].status !== "Cancelado") {
          let uniqueKey = dataBookingComision[j].restId;
          usersBooking.push({
            uniqueKey: uniqueKey,
            bookings: dataBookingComision[j]
          })
          valueBooking.push({
            uniqueKey: uniqueKey,
            value: dataBookingComision[j].subTotal
          })
          // console.log("usersBooking", usersBooking)
          // console.log("valueBooking", valueBooking)

          let values = [];
          for (let i = 0; i < usersBooking.length; i++) {
            if (usersBooking[i].uniqueKey === uniqueKey) {
              values.push(usersBooking[i].bookings);
            }
            renderobj[uniqueKey] = {};
            renderobj[uniqueKey] = values;
          }
          // console.log("values", values)

          let val = [];
          for (let i = 0; i < valueBooking.length; i++) {
            if (usersBooking[i].uniqueKey === uniqueKey) {
              val.push(valueBooking[i].value);
            }
            // console.log("val", val)
            valueobj[uniqueKey] = {};
            valueobj[uniqueKey] = val;
          }
          // console.log("renderobj", renderobj)
          // console.log("valueobj", valueobj)

          for (const key in renderobj) {
            // console.log(`${key}: ${(renderobj[key])}`);
            for (let index = 0; index < dataUsersComision.length; index++) {
              const element = dataUsersComision[index].id;
              if (element === key) {
                dataUsersComision[index].bookings = renderobj[key];
              }
            }
          }

          for (const key in valueobj) {
            // console.log(`${key}: ${(renderobj[key])}`);
            for (let index = 0; index < dataUsersComision.length; index++) {
              const element = dataUsersComision[index].id;
              // let total = 0;
              if (element === key) {
                dataUsersComision[index].value = (valueobj[key]);
                dataUsersComision[index].totalPedidos = (valueobj[key]).reduce((a, b) => a + b, 0);
                dataUsersComision[index].comision = ((valueobj[key]).reduce((a, b) => a + b, 0) * dataUsersComision[index].percentage / 100);
                // dataUsers[index].totalPedidos = (valueobj[key])++;
              }

            }
          }
        }
      }
      // setDataUsersCredit(dataUsers);
    }
  }, [dataBookingComision, dataUsersComision]);
  // console.log("dataUsersCredit", dataUsersCredit)


  useEffect(() => {
    // console.log("dataUsers", dataUsers)
    if (dataUsersComision) {
      let suma = 0;
      for (let i = 0; i < dataUsersComision.length; i++) {
        if (dataUsersComision[i].comision) {
          let comision = parseInt(dataUsersComision[i].comision);//Convert to numbers with parseFloat
          suma += comision;
          // console.log("suma", suma)
          setDataMesComision(suma);
        }
      }
    }
  }, [dataUsersComision]);
  // console.log("dataMesComision", dataMesComision)
  // console.log("dataTotalPedidos", dataTotalPedidos)

  useEffect(() => {
    if (allorderslistdata.bookings) {
      let totconvenienceTransselected = 0;
      let countServices = 0;
      for (let i = 0; i < allorderslistdata.bookings.length; i++) {
        if (selectedDateFin && selectedDateInicio) {
          if ((allorderslistdata.bookings[i].status !== 'Cancelado') && (allorderslistdata.bookings[i].createdAt > selectedDateInicio && allorderslistdata.bookings[i].createdAt < selectedDateFin)) {
            const { subTotal } = allorderslistdata.bookings[i];
            if (subTotal > 0) {
              totconvenienceTransselected = totconvenienceTransselected + parseFloat(subTotal);
              countServices = countServices + 1;
            }
          }
        }
      }
      setServicesCount(countServices)
      setTotalgrossCompleteSelected((totconvenienceTransselected).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    }
  }, [allorderslistdata.bookings, settings, selectedDateFin, selectedDateInicio, dataFecha]);
  // console.log("totalgrossSelected", totalgrossSelected)
  // console.log("servicesCount", servicesCount)

  useEffect(() => {
    // let date = new Date();
    // let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // let primerDiaMesActual = Math.floor(new Date(firstDay).getTime());
    // console.log("primerDiaMesActual", primerDiaMesActual)
    if (allorderslistdata.bookings && allorderslistdata.bookings.length > 0) {
      // setDataBooking(orderslistdata.bookings.filter(order => order.createdAt > primerDiaMesActual));
      setDataBooking(allorderslistdata.bookings);
    } else {
      setDataBooking([]);
    }
  }, [allorderslistdata.bookings]);
    // console.log("dataBooking", dataBooking)

  useEffect(() => {
    if ((dataBooking && dataBooking.length > 0) && dataUsers) {
      const usersBooking = [];
      const valueBooking = [];
      const renderobj = {};
      const valueobj = {};
      for (let j = 0; j < dataBooking.length; j++) {
        if (dataBooking[j].status !== "Cancelado") {
          let uniqueKey = dataBooking[j].restId;
          usersBooking.push({
            uniqueKey: uniqueKey,
            bookings: dataBooking[j]
          })
          valueBooking.push({
            uniqueKey: uniqueKey,
            value: dataBooking[j].subTotal
          })
          // console.log("usersBooking", usersBooking)
          // console.log("valueBooking", valueBooking)

          let values = [];
          for (let i = 0; i < usersBooking.length; i++) {
            if (usersBooking[i].uniqueKey === uniqueKey) {
              values.push(usersBooking[i].bookings);
            }
            renderobj[uniqueKey] = {};
            renderobj[uniqueKey] = values;
          }
          // console.log("values", values)

          let val = [];
          for (let i = 0; i < valueBooking.length; i++) {
            if (usersBooking[i].uniqueKey === uniqueKey) {
              val.push(valueBooking[i].value);
            }
            // console.log("val", val)
            valueobj[uniqueKey] = {};
            valueobj[uniqueKey] = val;
          }
          // console.log("renderobj", renderobj)
          // console.log("valueobj", valueobj)

          for (const key in renderobj) {
            // console.log(`${key}: ${(renderobj[key])}`);
            for (let index = 0; index < dataUsers.length; index++) {
              const element = dataUsers[index].id;
              if (element === key) {
                dataUsers[index].bookings = renderobj[key];
              }
            }
          }

          for (const key in valueobj) {
            // console.log(`${key}: ${(renderobj[key])}`);
            for (let index = 0; index < dataUsers.length; index++) {
              const element = dataUsers[index].id;
              // let total = 0;
              if (element === key) {
                dataUsers[index].value = (valueobj[key]);
                dataUsers[index].totalPedidos = (valueobj[key]).reduce((a, b) => a + b, 0);
                dataUsers[index].comision = ((valueobj[key]).reduce((a, b) => a + b, 0) * dataUsers[index].percentage / 100);
                // dataUsers[index].totalPedidos = (valueobj[key])++;
              }

            }
          }
        }
      }
      // setDataUsersCredit(dataUsers);
    }
  }, [dataBooking, dataUsers]);
  // console.log("dataUsersCredit", dataUsersCredit)

  useEffect(() => {
    if (dataUsers && (dataBooking && dataBooking.length > 0) ) {
      let sumaConsulta = 0;
      for (let i = 0; i < dataUsers.length; i++) {
        if (dataUsers[i].comision) {
          let comision = parseInt(dataUsers[i].comision);//Convert to numbers with parseFloat
          sumaConsulta += comision;
          // console.log("sumaConsulta", sumaConsulta)
          // setDataMesConsulta(0);
          setDataMesConsulta(sumaConsulta);
        }
      }
    }
  }, [dataUsers,dataBooking]);
  // console.log("dataMesConsulta", dataMesConsulta)
  // console.log("dataTotalPedidos", dataTotalPedidos)


  useEffect(() => {
    if (orderslistdata.bookings) {
      let today = new Date();
      let convenniencefees = 0;
      let totconvenienceTrans = 0;
      let todayConvenience = 0;
      let yesterdayConvenience = 0;
      let countMont = 0;
      let countDate = 0;
      let yesterdaycountDate = 0;
      for (let i = 0; i < orderslistdata.bookings.length; i++) {
        if (orderslistdata.bookings[i].status !== 'Cancelado') {
          const { createdAt, subTotal } = orderslistdata.bookings[i];
          let tDate = new Date(createdAt);
          if (subTotal > 0) {

            if (tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
              convenniencefees = convenniencefees + parseFloat(subTotal);
              countMont = countMont + 1;
            }
            if (tDate.getDate() === today.getDate() && tDate.getMonth() === today.getMonth()) {
              todayConvenience = todayConvenience + parseFloat(subTotal);
              countDate = countDate + 1;
            }
            if ((tDate.getDate()) === (today.getDate() - 1) && tDate.getMonth() === today.getMonth()) {
              yesterdayConvenience = yesterdayConvenience + parseFloat(subTotal);
              yesterdaycountDate = yesterdaycountDate + 1;
            }
            totconvenienceTrans = totconvenienceTrans + parseFloat(subTotal);
          }
        }
      }
      setMonthlyCount(countMont);
      setDailyCount(countDate);
      setYesterdayCount(yesterdaycountDate);
      setDailygrossValor((todayConvenience).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
      setYesterdaygrossValor((yesterdayConvenience).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
      setMonthlygrossValor((convenniencefees).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
      // setTotalgross((totconvenienceTrans).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    }
  }, [orderslistdata.bookings]);
  // console.log("monthlyCount", monthlyCount)
  // console.log("dailyCount", dailyCount)
  // console.log("yesterdayCount", yesterdayCount)
  // console.log("dailygrossValor", dailygrossValor)
  // console.log("yesterdayGrossValor", yesterdayGrossValor)
  // console.log("monthlygrossValor", monthlygrossValor)


  useEffect(() => {
    if (selectedDateFin && selectedDateInicio) {
      let fecha = [
        {
          "selectedDateFin": selectedDateFin,
          "selectedDateInicio": selectedDateInicio
        }
      ]
      // setDataFecha(fecha)
      dispatch(senAllOrders(fecha))
    } else {
      setDataFecha([])
    }
  }, [selectedDateFin, selectedDateInicio, dispatch, senAllOrders])
  // console.log("dataFecha", dataFecha)

  const handleDateChangeInicio = (dateInicio) => {
    const start = moment(dateInicio).startOf('day');
    const unixTimestamp = Math.floor(start._d.getTime() / 1000) * 1000;
    setSelectedDateInicio(unixTimestamp);
    setSelectedDateInicioValue(dateInicio);
    setSelectedDateInicioMoment(moment(unixTimestamp).format(" MMM DD "))

    setDataMesConsulta(0);
    setSelectedDateFin(null);
    setSelectedDateFinValue(null);
    setSelectedDateFinMoment(null)
  };
  // console.log("selectedDateInicio", selectedDateInicio)

  const handleDateChangeFin = (dateFin) => {
    const end = moment(dateFin).endOf('day');
    const unixTimestampEnd = Math.floor(end._d.getTime() / 1000) * 1000;
    setSelectedDateFin(unixTimestampEnd);
    setSelectedDateFinValue(dateFin);
    setSelectedDateFinMoment(moment(unixTimestampEnd).format(" MMM DD "))
  };
  // console.log("selectedDateFin", selectedDateFin)

  const columns = [
    // { title: 'Nombre Administrador', field: 'firstName', cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
    // { title: 'Apellidos Administrador', field: 'lastName', cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
    // { title: 'Documento', field: 'document', render: rowData => settings.AllowCriticalEditsAdmin ? rowData.document : "Hidden for Demo", cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' } },
    { title: 'Empresa', field: 'name', render: rowData => rowData.name ? rowData.name : "0", cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' } },
    { title: 'Porcentaje', field: 'percentage', render: rowData => rowData.percentage ? rowData.percentage : "0", cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' } },
    { title: 'Pedidos', field: 'bookings', render: rowData => rowData.bookings ? rowData.bookings.length : "0", cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' } },
    { title: 'Subtotal', field: 'totalPedidos', render: rowData => rowData.totalPedidos && "$" + rowData.totalPedidos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') },
    { title: 'Comisión', field: 'comision', render: rowData => rowData.comision && "$" + rowData.comision.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') },
    // { title: 'Subtotal', field: 'value', render: rowData => rowData.value && rowData.value.length > 1 ? "$" + (rowData.value.reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')) : rowData.value && rowData.value.length === 1 ? "$" + rowData.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : "0", cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' } },
    // { title: 'Comisión', field: 'value', render: rowData => rowData.value && rowData.value.length > 1 ? "$" + (rowData.value.reduce((a, b) => a + b, 0) * rowData.percentage /100 ) : rowData.value && rowData.value.length === 1 ? "$" + rowData.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : "0", cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' } },

  ];

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const ddyesterday = String(today.getDate() - 1).padStart(2, '0');
  // const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  const weekday = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'][new Date().getDay()]
  const yesterday = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'][new Date().getDay() - 1]

  // console.log("weekday", weekday)
  const monthday = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'][new Date().getMonth()]
  // console.log("weekday", monthday)
  const hoy = weekday + ' ' + dd + '-' + monthday;
  const ayer = yesterday + ' ' + ddyesterday + '-' + monthday;
  const history = useHistory();
  const theme = useTheme()
  const rootRef = useRef(null);

  return (
    !loaded.current && !loadedComision.current ? <CircularLoading /> :
      <div className={classes.container} ref={rootRef}>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={2} >

            <Grid item xs={6} sm={4} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
              <DashboardCard title={`${ayer}`} image={require("../assets/img/money1.jpg").default}>
                <Typography variant="h5">
                  {` Pedidos:   ${+ ' ' + yesterdayCount}`}
                </Typography>
                <Typography variant="h5">
                  {` Total:   ${' ' + settings.symbol + ' ' + yesterdayGrossValor}`}
                </Typography>
              </DashboardCard>
            </Grid>

            <Grid item xs={12} sm={4} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
              <DashboardCard title={` ${hoy}`} image={require("../assets/img/money1.jpg").default}>
                <Typography variant="h5">
                  {` Pedidos: ${+ ' ' + dailyCount}`}
                </Typography>
                <Typography variant="h5">
                  {` Total:   ${' ' + settings.symbol + ' ' + dailygrossValor}`}
                </Typography>
              </DashboardCard>
            </Grid>

            <Grid item xs={12} sm={4} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
              <DashboardCard title={`${monthday} ${yyyy} - ${monthlyCount}`} image={require("../assets/img/money1.jpg").default}>
                {/* <Typography variant="h6">
                  {` Pedidos:   ${+ ' ' + monthlyCount}`}
                </Typography> */}
                <Typography variant="h5">
                  {`${' ' + settings.symbol + ' ' + monthlygrossValor}`}
                </Typography>
                <Typography variant="h5">
                  {` ${' ' + settings.symbol + ' ' + (dataMesComision).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
                </Typography>
              </DashboardCard>
            </Grid>


            <Grid item xs={12} sm={6} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
              <DashboardCard title={`Consulta por día`} image={require("../assets/img/money3.jpg").default}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">

                    <KeyboardDatePicker style={{ marginTop: 8, width: 200 }}
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="INICIO"
                      value={selectedDateInicioValue}
                      onChange={handleDateChangeInicio}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />

                    <KeyboardDatePicker style={{ paddingLeft: 20, marginTop: 8, width: 200 }}
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="FIN"
                      disabled={selectedDateInicio? false : true}
                      value={selectedDateFinValue}
                      onChange={handleDateChangeFin}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />

                  </Grid>
                </MuiPickersUtilsProvider>
              </DashboardCard>
            </Grid>

            <Grid item xs={12} sm={6} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
              <DashboardCard title={`${selectedDateInicioMoment ? selectedDateInicioMoment : "Total consulta"} ${selectedDateInicioMoment ? " - " : ""} ${selectedDateFinMoment ? selectedDateFinMoment : ""} - ${servicesCount}`} image={require("../assets/img/money2.jpg").default}>
                <Typography variant="h6">
                  {` Total:   ${' ' + settings.symbol + ' ' + totalgrossSelected}`}
                  {/* {` Pedidos:   ${+ ' ' + servicesCount}`} */}
                </Typography>
                <Typography variant="h6">
                  {` Comisión:   ${' ' + settings.symbol + ' ' + (dataMesConsulta).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
                </Typography>
              </DashboardCard>
            </Grid>

            {/* <Grid container direction="row" spacing={2}>
             <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
            <DashboardCard title={`${selectedDateInicioMoment ? selectedDateInicioMoment: "Totales"} ${selectedDateInicioMoment ? " - ": ""} ${selectedDateFinMoment ? selectedDateFinMoment : ""}`} image={require("../assets/img/money2.jpg").default}>
              <Typography variant="h6">
                {` Servicios:   ${+ ' ' + servicesCount}`}
              </Typography>
              <Typography variant="h6">
                {` Total:   ${' ' + settings.symbol + ' ' + totalgrossSelected}`}
              </Typography>
            </DashboardCard>
          </Grid>

          </Grid> */}
          </Grid>
        </Grid>

        <div style={{ paddingTop: 20 }}>
          <MaterialTable
            // title={`${monthday} Total $${dataTotalPedidos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} - Comisión $${dataMes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} `}
            title={` Establecimientos`}
            columns={columns}
            style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
            data={dataUsers}
            icons={{
              Edit: () => <EditIcon style={{ color: "e84fb7" }} />,
              Delete: () => <DeleteIcon style={{ color: "e84fb7" }} />,
              Add: () => <AddBoxIcon style={{ color: "e84fb7" }} />,
              Export: () => <GetAppIcon style={{ color: "e84fb7" }} />

            }}
            options={{
              exportButton: settings.AllowCriticalEditsAdmin,
              sorting: true,
              pageSize: 50,
              thirdSortClick: false,
              rowStyle: {
                overflowWrap: 'break-word'
              },
              headerStyle: {
                backgroundColor: '#ECE9E7',
                color: theme.palette.common.black,
                fontWeight: 'bold',
                fontSize: 15,
                textAlign: 'center',
                flexDirection: 'row',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            localization={{
              body: {
                addTooltip: (t('add')),
                deleteTooltip: (t('delete')),
                editTooltip: (t('edit')),
                emptyDataSourceMessage: (
                  (t('blank_message'))
                ),
                editRow: {
                  deleteText: (t('delete_message')),
                  cancelTooltip: (t('cancel')),
                  saveTooltip: (t('save'))
                },
              },
              toolbar: {
                searchPlaceholder: ('Buscar'),
                exportTitle: ('Exportar'),
              },
              header: {
                actions: ('Acciones')
              },
              pagination: {
                labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
                labelRowsSelect: (t('rows')),
                firstTooltip: (t('first_page_tooltip')),
                previousTooltip: (t('previous_page_tooltip')),
                nextTooltip: (t('next_page_tooltip')),
                lastTooltip: (t('last_page_tooltip'))
              },
            }}
            actions={[
              rowData => ({
                icon: () =>
                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <VisibilityIcon style={{ color: "e84fb7" }} />
                    {/* <Typography variant="subtitle2">{'Ver'}</Typography> */}
                  </div>,
                tooltip: 'Ver Cobro',
                disabled: rowData.bookings && rowData.bookings.length > 0 ? false : true,
                // disabled: rowData.cart ? false : true,
                onClick: (event, rowData) => {
                  history.push({
                    pathname: '/Payment',
                    state: rowData
                  })
                }
              })
            ]}
          />
        </div>
      </div>
  );
}
