import React, { useState, useEffect, useRef } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ConfirmationTime(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    const { t } = useTranslation();
    const [value, setValue] = useState(valueProp);
    const radioGroupRef = useRef(null);
    //   const options = useSelector(state => state.cancelreasondata.simple);
    //   console.log("options " , options)

      const confirmTime = [
        { value: "10",  name: '10 minutos' },
        { value: "15",  name: '15 minutos' },
        { value: "20",  name: '20 minutos' },
        { value: "25",  name: '25 minutos' },
        { value: "30",  name: '30 minutos' },
        { value: "35",  name: '35 minutos' },
        { value: "40",  name: '40 minutos' },
        { value: "50",  name: '50 minutos' }
      ];

    // const confirmOrder = [
    //     {
    //         "Restaurante": "RESTAURANTES",
    //         // "lastName": "Blow",
    //         // "age": 42,
    //         // "role": "clerk"
    //     },
    //     {
    //         "Llevo a Casa": "DOMICILIOS",
    //         // "lastName": "Jenkins",
    //         // "age": 36,
    //         // "role": "manager"
    //     }
    // ]

    //   console.log("status " , status)

    useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            {/* <DialogTitle id="confirmation-dialog-title">{t('select_reason')}</DialogTitle> */}
            <DialogTitle id="confirmation-dialog-title">{'Confirme tiempo'}</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="ringtone"
                    name="ringtone"
                    value={value}
                    onChange={handleChange}
                >
                    {confirmTime.map((confirmTime) => (
                        <FormControlLabel value={confirmTime.value} key={confirmTime.value} control={<Radio />} label={confirmTime.name} />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    {t('cancel')}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationTime.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};