import React, { useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
// import moment from 'moment/min/moment-with-locales';
import moment from 'moment/min/moment-with-locales';
import DateFnsUtils from '@date-io/date-fns';
import 'moment/locale/es';
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';
import DashboardCard from '../components/DashboardCard';

// import Typography from '@material-ui/core/Typography';
import {
    MuiPickersUtilsProvider,
    // KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    Grid,
    // Paper,
    // Typography
} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

const MyNewTitle = ({ text, variant }) => (
    <Typography
        variant={variant}
        style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",

        }}
    >
        {text}
    </Typography>
);

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: 480,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        alignContent: 'center'
    },
    title: {
        color: "#000",
    },
    gridcontainer: {
        alignContent: 'center'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    buttonStyle: {
        margin: 0,
        width: '100%',
        height: '100%'
    }
}));

export default function UsersAppOneSignal() {
    const { t, i18n } = useTranslation();
    const { api } = useContext(FirebaseContext);
    const {
        editUserAppDelivery,
        // sendSmsTemplate,
        sendOneSignalTemplate,
        senAllOrdersUsers
    } = api;
    const [data, setData] = useState([]);
    const [template, setTemplate] = useState([]);
    const [dataBooking, setDataBooking] = useState([]);
    const isRTL = i18n.dir();

    const usersdata = useSelector(state => state.usersdata);
    const usersappdata = useSelector(state => state.usersappdata);

    const templateonesignaldata = useSelector(state => state.templateonesignaldata);
    // console.log("templateonesignaldata", templateonesignaldata)
    const allordersuserslistdata = useSelector(state => state.allordersuserslistdata);

    const settings = useSelector(state => state.settingsdata.settings);

    const [selectedDateInicio, setSelectedDateInicio] = useState();
    const [selectedDateInicioValue, setSelectedDateInicioValue] = useState(null);
    const [selectedDateInicioMoment, setSelectedDateInicioMoment] = useState(null);
    const [selectedDateFin, setSelectedDateFin] = useState();
    const [selectedDateFinValue, setSelectedDateFinValue] = useState(null);
    const [selectedDateFinMoment, setSelectedDateFinMoment] = useState(null);
    const [total, setTotal] = useState(0);
    const [templateActions, setTemplateActions] = useState([]);




    const array = Array.from(Array(100).keys());
    // console.log("array", array)

    const dispatch = useDispatch();
    const tableRef = useRef()
    const theme = useTheme()
    const classes = useStyles();
    const rootRef = useRef(null);

    useEffect(() => {
        if (usersappdata.address) {
            // setData(usersdata.users.filter(user => user.role ==='Manager' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin')|| auth.info.profile.usertype === 'admin')));
            // setData(usersappdata.users.filter(user => user.role ==='Manager' && user.city ==='CARTAGO' ));
            setData(usersappdata.address.filter(user => user.playerId));
            //   setData(usersappdata.address);
        } else {
            setData([]);
        }
    }, [usersappdata.address]);

    useEffect(() => {

        if (allordersuserslistdata.bookings) {
            setDataBooking(allordersuserslistdata.bookings);
        } else {
            setDataBooking([]);
        }
    }, [allordersuserslistdata.bookings]);
    // console.log("dataBooking", dataBooking)

    useEffect(() => {
        if (templateonesignaldata.simple) {
            setTemplate(templateonesignaldata.simple);
        } else {
            setTemplate([]);
        }
    }, [templateonesignaldata.simple]);
    // console.log("template", template)

    useEffect(() => {
        const usersBooking = [];
        const renderobj = {};
        const renderFechas = {};
        moment.locale('es');
        if (dataBooking && data) {
            for (let j = 0; j < dataBooking.length; j++) {
                const userIdBooking = dataBooking[j].userId;
                usersBooking.push({
                    uniqueKey: userIdBooking,
                    bookings: dataBooking[j],
                    fechas: (moment(dataBooking[j].createdAt).format("MMMM")).toUpperCase()
                })
            }

            for (let i = 0; i < data.length; i++) {
                const userId = data[i].userId;
                let values = [];
                let fechas = [];

                for (let h = 0; h < usersBooking.length; h++) {
                    const uniqueKey = usersBooking[h].uniqueKey;
                    if (userId === uniqueKey) {
                        values.push(usersBooking[h].bookings);
                        if (!fechas.includes(usersBooking[h].fechas)) {
                            fechas.push(usersBooking[h].fechas);
                        }
                        // fechas.push(usersBooking[h].fechas);
                        renderobj[uniqueKey] = {};
                        renderobj[uniqueKey] = values;
                        renderFechas[uniqueKey] = {};
                        renderFechas[uniqueKey] = fechas;
                        data[i].booking = renderobj[uniqueKey];
                        data[i].fechas = (renderFechas[uniqueKey]).toString();
                        // data.map((item, h) => ({...item, booking: usersBooking[h]}))
                    }
                }
                if (!data[i].fechas) {
                    data[i].fechas = "SINPEDIDOS"
                }
            }
            // console.log("usersBooking", usersBooking)
        }
    }, [dataBooking, data]);

    useEffect(() => {
        if (selectedDateFin && selectedDateInicio) {
            let fecha = [
                {
                    "selectedDateFin": selectedDateFin,
                    "selectedDateInicio": selectedDateInicio
                }
            ]
            // setDataFecha(fecha)
            dispatch(senAllOrdersUsers(fecha))
        }
    }, [selectedDateFin, selectedDateInicio, dispatch, senAllOrdersUsers])
    // console.log("dataFecha", dataFecha)

    const handleDateChangeInicio = (dateInicio) => {
        const start = moment(dateInicio).startOf('day');
        const unixTimestamp = Math.floor(start._d.getTime() / 1000) * 1000;
        setSelectedDateInicio(unixTimestamp);
        setSelectedDateInicioValue(dateInicio);
        setSelectedDateInicioMoment(moment(unixTimestamp).format(" MMM DD "))

        // setDataMesConsulta(0);
        setSelectedDateFin(null);
        setSelectedDateFinValue(null);
        setSelectedDateFinMoment(null)
    };
    // console.log("selectedDateInicio", selectedDateInicio)

    const handleDateChangeFin = (dateFin) => {
        const end = moment(dateFin).endOf('day');
        const unixTimestampEnd = Math.floor(end._d.getTime() / 1000) * 1000;
        setSelectedDateFin(unixTimestampEnd);
        setSelectedDateFinValue(dateFin);
        setSelectedDateFinMoment(moment(unixTimestampEnd).format(" MMM DD "))
    };
    // console.log("selectedDateFin", selectedDateFin)

    const handleCheckboxClick = (rowData) => {
        if (rowData.length > 0) {
            // console.log("rowData", rowData.name)
            let actionsTemplate = [];
            for (let i = 0; i < template.length; i++) {
                // template[i].icon = iconos[i].icon;
                template[i].number = array[i];
                let templateId = template[i];
                actionsTemplate.push({
                    'icon': () =>
                        <div>
                            <span style={{
                                fontSize: "20px",
                                fontFamily: "sans-serif",
                                color: "#C60930",
                                fontWeight: "bold",
                            }} >
                                {templateId.number + 1}
                            </span>
                        </div>,
                    'tooltip': templateId.campaign,
                    'onClick': (event, rowData) => {
                        let arrSms = [];
                        for (let i = 0; i < rowData.length; i++) {
                            if (!rowData[i].name) {
                                arrSms.push({
                                    'big_picture':templateId.big_picture,
                                    'headings':templateId.headings,
                                    'contents':templateId.contents,
                                    'include_player_ids':rowData[i].playerId,
                                });
                            } else {
                                let firstWord = rowData[i].name.split(" ")[0]
                                arrSms.push({
                                    'big_picture': templateId.big_picture,
                                    'headings':`Hola ${(firstWord).toUpperCase()}. ${templateId.headings}`,
                                    'contents':`${templateId.contents}`,
                                    'include_player_ids': rowData[i].playerId,
                                });
                            }
                        }
                        // console.log("arrSms ", arrSms)
                        // console.log("rowData ", rowData)
                        // console.log("event ", event)
                        dispatch(sendOneSignalTemplate(arrSms));
                        clearSelection();
                        alert("Notificación enviada correctamente.");
                    },
                    'position': "toolbarOnSelect"
                });
            }
            setTemplateActions(actionsTemplate)
            setTotal(rowData.length);
        } else {
            setTotal(0);
            setTemplateActions([])
        }
    };

    const clearSelection = () => {
        if (tableRef.current) {
            tableRef.current.onAllSelected(false)
        }
    }


    const columns = [
        { title: 'Nombre', field: 'name' },
        { title: 'Correo', field: 'email', editable: 'never' },
        { title: 'Registro', field: 'createAtUser', editable: 'never', render: rowData => rowData.createAtUser ? moment(rowData.createAtUser).format('lll') : null },
        { title: 'PlayerId', field: 'playerId', editable: 'never' },
        { title: 'Meses', field: 'fechas', editable: 'never' },
        { title: 'Pedidos', render: rowData => <span>{rowData.booking ? rowData.booking.length : ""}</span> },
    ];


    return (
        usersdata.loading ? <CircularLoading /> :
            <div className={classes.container} ref={rootRef}>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2} >

                        <Grid item xs={12} sm={12} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                            <DashboardCard title={`${selectedDateInicioMoment ? "Fecha consulta: " + selectedDateInicioMoment : "Consulta por días"} ${selectedDateInicioMoment ? " - " : ""} ${selectedDateFinMoment ? selectedDateFinMoment : ""} `} image={require("../assets/img/money3.jpg").default}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justifyContent="space-around">

                                        <Grid item xs={12} sm={6} md={6} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                                            <KeyboardDatePicker style={{ marginTop: 8, width: 300 }}
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="INICIO"
                                                value={selectedDateInicioValue}
                                                onChange={handleDateChangeInicio}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', paddingLeft: 20 }}>
                                            <KeyboardDatePicker style={{ marginTop: 8, width: 300 }}
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="FIN"
                                                disabled={selectedDateInicio ? false : true}
                                                value={selectedDateFinValue}
                                                onChange={handleDateChangeFin}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>

                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </DashboardCard>
                        </Grid>

                    </Grid>
                </Grid>

                <div style={{ paddingTop: 20 }}>
                    <MaterialTable
                        // title={'Usuarios App Cartago'}
                        tableRef={tableRef}
                        title={<MyNewTitle variant="h6" text={total > 0 ? "seleccionados " + total : "seleccionados 0"} />}
                        columns={columns}
                        onSelectionChange={(event) => {
                            handleCheckboxClick(event);
                            // let totalBookings = event.reduce((accum, item) => accum + item.trip_cost, 0)
                            // setTotal(totalBookings);
                            // console.log("event ", event)
                            // console.log("total ",total)
                        }}
                        data={data}
                        actions={templateActions}
                        options={{
                            // toolbar: false,
                            //   filtering: true,
                            selection: true,
                            showTextRowsSelected: false,
                            exportButton: settings.AllowCriticalEditsAdmin,
                            sorting: true,
                            pageSize: 20,
                            textAlign: 'center',
                            showTitle: true,
                            search: true,
                            searchFieldAlignment: "right",
                            headerStyle: {
                                backgroundColor: theme.palette.action.hover,
                                color: theme.palette.common.black,
                                fontWeight: 'bold',
                                flexDirection: 'row',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }
                        }}
                        localization={{
                            body: {
                                addTooltip: (t('add')),
                                deleteTooltip: (t('delete')),
                                editTooltip: ('Editar Servicio'),
                                emptyDataSourceMessage: (
                                    (t('blank_message'))
                                ),
                                editRow: {
                                    deleteText: (t('delete_message')),
                                    cancelTooltip: (t('cancel')),
                                    saveTooltip: (t('save'))
                                },
                            },
                            toolbar: {
                                searchPlaceholder: ('Buscar usuario'),
                                exportTitle: ('Exportar'),
                            },
                            header: {
                                actions: ('Acciones')
                            },
                            pagination: {
                                labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
                                labelRowsSelect: (t('rows')),
                                firstTooltip: (t('first_page_tooltip')),
                                previousTooltip: (t('previous_page_tooltip')),
                                nextTooltip: (t('next_page_tooltip')),
                                lastTooltip: (t('last_page_tooltip'))
                            },
                        }}


                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        dispatch(editUserAppDelivery(oldData.id, newData));
                                    }, 600);
                                }),

                        }}
                    />
                </div>
            </div>
    );
}
