import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
// import { useTranslation } from "react-i18next";
import {
  // Avatar,
  // Button,
  // Chip,
  // Container,
  FormControlLabel,
  Switch
} from "@material-ui/core";

export default function Wp() {
  const { api } = useContext(FirebaseContext);
//   const { t } = useTranslation();
  const {
    editWp,
    addWp,
    deleteWp,
    updatewp
  } = api;
  const columns = [
    { title: 'Nombre', field: 'Name'  ,render: rowData => <span>{rowData.Name}</span>},
    { title: 'Numero', field: 'Age' , type: 'numeric'  ,render: rowData => <span>{rowData.Age}</span>},
    // { title: 'Estado',  field: 'Address', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 16, color: rowData.Address === true ? 'green' : rowData.Address === false ? 'red' : null }}>{rowData.Address === true ? 'ACTIVO': 'INACTIVO' }</span> },
    {
      title: "Activar/Desactivar",
      field: "Address",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.Address}
              onChange={e => changeWp(data, e)}
              name="Address"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.Address === true ? 'green' : data.Address === false ? 'red' : null }}>{data.Address === true ? 'ACTIVO' : 'INACTIVO'}</span>}
        />
      )
    },
    // { title: 'Activar/Desactivar',  field: 'Address', type:'boolean', initialEditValue: false },
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
//   const cancelreasondata = useSelector(state => state.cancelreasondata);
  const wpdata = useSelector(state => state.wpdata);
  // console.log("wpdata" , wpdata)
  const dispatch = useDispatch();

  useEffect(() => {
    if (wpdata.complex) {
      setData(wpdata.complex);
    }else{
      setData([]);
    }
  }, [wpdata.complex]);

  const changeWp = (oldRow ,e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    if (newRow) {
      dispatch(updatewp({
        Address: newRow.Address,
        booking: newRow.id,
        // cancelledBy: role
      }));
    }
  };

  return (
    wpdata.loading ? <CircularLoading /> :
      <MaterialTable
        title={'NUMERO DE WHATSAPP'}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          pageSize: 10
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
            onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // const tblData = data;
                // newData.value = tblData.length
                // tblData.push(newData);
                // console.log(newData);
                dispatch(addWp(newData));
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // const tblData = data;
                // tblData[tblData.indexOf(oldData)] = newData;
                dispatch(editWp(oldData.id,newData));
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // const tblData = data;
                // tblData.splice(tblData.indexOf(oldData), 1);
                // for(let i=0;i<tblData.length;i++){
                //   tblData[i].value = i;
                // }
                dispatch(deleteWp(oldData.id));
              }, 600);
            }),
        } : null}
      />
  );
}
