import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
// import { 
//   features,
//   language
// } from 'config';
import { FirebaseContext } from 'common';
// import { useTheme } from "@material-ui/core/styles";
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import {
//   Typography
// } from '@material-ui/core';
import ConfirmmationStatusImg from 'components/ConfirmmationStatusImg';
// import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from '@material-ui/icons/AddBox';
import GetAppIcon from '@material-ui/icons/GetApp';
import PermMediaIcon from '@material-ui/icons/PermMedia';



export default function TemplateOneSignal() {
  const { api } = useContext(FirebaseContext);
  const {
    editTemplateOneSignal,
    addTemplateOneSignal,
    deleteTemplateOneSignal,
    updateBookingImageTransfer
  } = api;
  const { t } = useTranslation();
  // const isRTL = i18n.dir();
  const columns = [
    { title: 'Nombre Plantilla', field: 'campaign', render: rowData => <span>{rowData.campaign}</span> },
    { title: 'Titulo', field: 'headings', render: rowData => <span>{rowData.headings}</span> },
    { title: 'Mensaje', field: 'contents', render: rowData => <span>{rowData.contents}</span> },
    // { title: 'Imagen', field: 'big_picture', render: rowData => <span>{rowData.big_picture}</span> },
    { title: 'Imágen', field: 'big_picture', render: rowData => rowData.big_picture ? <img alt='Profile' src={rowData.big_picture} style={{ width: 300 }} /> : null },
  ];

  const [data, setData] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState();
  const [openConfirmImg, setOpenConfirmImg] = useState(false);
  const templateonesignaldata = useSelector(state => state.templateonesignaldata);
  // console.log("templatedata" , templatedata)
  const dispatch = useDispatch();
  // const theme = useTheme()
  const settings = useSelector(state => state.settingsdata.settings);

  useEffect(() => {
    if (templateonesignaldata.simple) {
      setData(templateonesignaldata.simple);
    } else {
      setData([]);
    }
  }, [templateonesignaldata.simple]);

  const onConfirmCloseImg = (value) => {
    if (value) {
      // console.log("value",value)
      dispatch(updateBookingImageTransfer(
        selectedBooking,
        'big_picture',
        value)
      );
    }
    setOpenConfirmImg(false);
  }

  const theme = useTheme()


  return (
    templateonesignaldata.loading ? <CircularLoading /> :
      <div>
        <MaterialTable
          title={'Plantillas ONE SIGNAL'}
          columns={columns}
          data={data}
          icons={{
            Edit: () => <EditIcon style={{ color: "000000" }} />,
            Delete: () => <DeleteIcon style={{ color: "000000" }} />,
            Add: () => <AddBoxIcon style={{ color: "000000" }} />,
            Export: () => <GetAppIcon style={{ color: "000000" }} />

          }}
          options={{
            pageSize: 10,
            exportAllData: true,
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
            headerStyle: {
              backgroundColor: theme.palette.action.hover,
              color: theme.palette.common.black,
              fontWeight: 'bold',
              fontSize: 15,
              textAlign: 'center',
              flexDirection: 'row',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          localization={{
            body: {
              addTooltip: (t('add')),
              deleteTooltip: (t('delete')),
              editTooltip: (t('edit')),
              emptyDataSourceMessage: (
                (t('blank_message'))
              ),
              editRow: {
                deleteText: (t('delete_message')),
                cancelTooltip: (t('cancel')),
                saveTooltip: (t('save'))
              },
            },
            toolbar: {
              searchPlaceholder: (t('search')),
              exportTitle: (t('export')),
            },
            header: {
              actions: ('Acciones')
            },
            // pagination: {
            //   labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            //   labelRowsSelect: (t('rows')),
            //   firstTooltip: (t('first_page_tooltip')),
            //   previousTooltip: (t('previous_page_tooltip')),
            //   nextTooltip: (t('next_page_tooltip')),
            //   lastTooltip: (t('last_page_tooltip'))
            // },
          }}

          editable={settings.AllowCriticalEditsAdmin ? {
            onRowAdd: newData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  newData.value = tblData.length
                  // newData['city'] = 'TODOS';
                  tblData.push(newData);
                  dispatch(addTemplateOneSignal(newData));
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData[tblData.indexOf(oldData)] = newData;
                  // dispatch(editSector(tblData, "Update"));
                  dispatch(editTemplateOneSignal(oldData.id, newData));
                }, 600);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData.splice(tblData.indexOf(oldData), 1);
                  for (let i = 0; i < tblData.length; i++) {
                    tblData[i].value = i;
                  }
                  // dispatch(editSector(tblData, "Delete"));
                  dispatch(deleteTemplateOneSignal(oldData.id));
                }, 600);
              }),
          } : null}

          actions={[

            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <PermMediaIcon style={{ color: "000000" }} />
              </div>,
              tooltip: 'Subir imagen',
              onClick: (event, rowData) => {
                if (settings.AllowCriticalEditsAdmin) {
                  setSelectedBooking(rowData);
                  setOpenConfirmImg(true);
                }
              }
            }),
            // rowData => ({
            //   icon: () =>
            //     <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            //       <RemoveRedEyeIcon style={{ color: "e84fb7" }}/>
            //       {/* <Typography variant="subtitle2">{'Ubicación'}</Typography> */}
            //     </div>,
            //   onClick: (event, rowData) => {
            //     window.open(`https://maps.google.com/maps?q=${rowData.lat},${rowData.lng}&hl=es;z=14&amp;output=embed`, '_blank').focus()
            //   }
            // })

          ]}
        />
        <ConfirmmationStatusImg
          open={openConfirmImg}
          onClose={onConfirmCloseImg}
          value={''}
        />
      </div>
  );
}
