import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
// import { useTranslation } from "react-i18next";

export default function BannerPpal() {
  const { api } = useContext(FirebaseContext);
//   const { t } = useTranslation();
  const {
    editbannerPpal,
    addbannerPpal,
    deletebannerPpal
  } = api;
  const columns = [
    { title: 'Nombre Banner', field: 'title' ,render: rowData => <span>{rowData.title}</span>},
    { title: 'Ciudad Banner', field: 'description' ,render: rowData => <span>{rowData.description}</span>},
    { title: 'Imágen',  field: 'thumb', render: rowData => rowData.thumb?<img alt='Profile' src={rowData.thumb} style={{width: 300,borderRadius:'5%'}}/>:null},
    { title: 'Key', field: 'key' ,render: rowData => <span>{rowData.key}</span>},
    { title: 'Estado',  field: 'disponible', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 16, color: rowData.disponible === true ? 'green' : rowData.disponible === false ? 'red' : null }}>{rowData.disponible === true ? 'ACTIVO': 'INACTIVO' }</span> },
    { title: 'Activar/Desactivar',  field: 'disponible', type:'boolean', initialEditValue: false },
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
//   const cancelreasondata = useSelector(state => state.cancelreasondata);
//   const wpdata = useSelector(state => state.wpdata);

  const bannerppaldata = useSelector(state => state.bannerppaldata);

//   console.log("bannerppaldata" , bannerppaldata)

  const dispatch = useDispatch();

  useEffect(() => {
    if (bannerppaldata.complex) {
      setData(bannerppaldata.complex);
    }else{
      setData([]);
    }
  }, [bannerppaldata.complex]);

  return (
    bannerppaldata.loading ? <CircularLoading /> :
      <MaterialTable
        title={'NUMERO DE WHATSAPP'}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          pageSize: 10
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
            onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // const tblData = data;
                // newData.value = tblData.length
                // tblData.push(newData);
                dispatch(addbannerPpal(newData));
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // const tblData = data;
                // tblData[tblData.indexOf(oldData)] = newData;
                dispatch(editbannerPpal(oldData.id,newData));
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // const tblData = data;
                // tblData.splice(tblData.indexOf(oldData), 1);
                // for(let i=0;i<tblData.length;i++){
                //   tblData[i].value = i;
                // }
                dispatch(deletebannerPpal(oldData.id));
              }, 600);
            }),
        } : null}
      />
  );
}
