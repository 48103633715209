import React, { useState, useEffect, useRef } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";

export default function DeleteOrder(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    // const { t } = useTranslation();
    const [value, setValue] = useState(valueProp);
    const radioGroupRef = useRef(null);
    //   const options = useSelector(state => state.cancelreasondata.simple);
    //   console.log("options " , options)

      const confirmOrder = [
        { value: 'SI',  name: 'SI' },
        // { value: 'NO',  name: 'NO' },
      ];


    useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    // console.log("value ",value)

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            {/* <DialogTitle id="confirmation-dialog-title">{t('select_reason')}</DialogTitle> */}
            <DialogTitle id="confirmation-dialog-title">{'¿Desea eliminar el pedido?'}</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="ringtone"
                    name="ringtone"
                    value={value}
                    onChange={handleChange}
                >
                    {confirmOrder.map((confirmOrder) => (
                        <FormControlLabel value={confirmOrder.value} key={confirmOrder.value} control={<Radio />} label={confirmOrder.name} />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    {'CANCELAR'}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {'ELIMINAR'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DeleteOrder.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};