import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
// import { useTranslation } from "react-i18next";

export default function Supercategoria() {
  const { api } = useContext(FirebaseContext);
//   const { t } = useTranslation();
  const {
    editSupercategoria,
    addSupercategoria,
    deleteSupercategoria
  } = api;
  const columns = [
    { title: 'Orden', field: 'Orden' , type: 'numeric'  ,render: rowData => <span>{rowData.Orden}</span>},
    { title: 'Nombre Super categoria', field: 'title' ,render: rowData => <span>{rowData.title}</span>},
    { title: 'Imágen Principal',  field: 'thumb', render: rowData => rowData.thumb?<img alt='Profile' src={rowData.thumb} style={{width: 300,borderRadius:'5%'}}/>:null},
    { title: 'Ciudad Activa 1', field: 'ciudad' ,render: rowData => <span>{rowData.ciudad}</span>},
    { title: 'Ciudad Activa 2', field: 'ciudad1' ,render: rowData => <span>{rowData.ciudad1}</span>},
    { title: 'Ciudad Activa 3', field: 'ciudad2' ,render: rowData => <span>{rowData.ciudad2}</span>},
    { title: 'Imagen 1 Banner Supercategoria',  field: 'thumb1', render: rowData => rowData.thumb1?<img alt='Profile' src={rowData.thumb1} style={{width: 300,borderRadius:'5%'}}/>:null},
    { title: 'Ciudad para Imagen 1 Banner Supercategoria', field: 'thumb1ciudad1' ,render: rowData => <span>{rowData.thumb1ciudad1}</span>},
    { title: 'Imagen 2 Banner Supercategoria',  field: 'thumb2', render: rowData => rowData.thumb2?<img alt='Profile' src={rowData.thumb2} style={{width: 300,borderRadius:'5%'}}/>:null},
    { title: 'Ciudad para Imagen 2 Banner Supercategoria', field: 'thumb2ciudad2' ,render: rowData => <span>{rowData.thumb2ciudad2}</span>},
    { title: 'Imagen 3 Banner Supercategoria',  field: 'thumb3', render: rowData => rowData.thumb3?<img alt='Profile' src={rowData.thumb3} style={{width: 300,borderRadius:'5%'}}/>:null},
    { title: 'Ciudad para Imagen 3 Banner Supercategoria', field: 'thumb3ciudad3' ,render: rowData => <span>{rowData.thumb3ciudad3}</span>},
    // { title: 'Key', field: 'restaurantID' ,render: rowData => <span>{rowData.restaurantID}</span>},
    // { title: 'Estado',  field: 'disponible', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 16, color: rowData.disponible === true ? 'green' : rowData.disponible === false ? 'red' : null }}>{rowData.disponible === true ? 'ACTIVO': 'INACTIVO' }</span> },
    // { title: 'Activar/Desactivar',  field: 'disponible', type:'boolean', initialEditValue: false },
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
//   const cancelreasondata = useSelector(state => state.cancelreasondata);
//   const wpdata = useSelector(state => state.wpdata);

  const supercategoriadata = useSelector(state => state.supercategoriadata);

  // console.log("supercategoriadata" , supercategoriadata)

  const dispatch = useDispatch();

  useEffect(() => {
    if (supercategoriadata.complex) {
      setData(supercategoriadata.complex);
    }else{
      setData([]);
    }
  }, [supercategoriadata.complex]);

  return (
    supercategoriadata.loading ? <CircularLoading /> :
      <MaterialTable
        title={'SUPER CATEGORIAS'}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          pageSize: 10
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
            onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // const tblData = data;
                // newData.value = tblData.length
                // tblData.push(newData);
                dispatch(addSupercategoria(newData));
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // const tblData = data;
                // tblData[tblData.indexOf(oldData)] = newData;
                dispatch(editSupercategoria(oldData.id,newData));
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // const tblData = data;
                // tblData.splice(tblData.indexOf(oldData), 1);
                // for(let i=0;i<tblData.length;i++){
                //   tblData[i].value = i;
                // }
                dispatch(deleteSupercategoria(oldData.id));
              }, 600);
            }),
        } : null}
      />
  );
}
