import React, {
    useState,
    useEffect,
    //  useContext,
    useRef
}
    from 'react';

import 'moment/min/locales'
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
// import { useDownloadExcel } from 'react-export-table-to-excel';
// import { DownloadTableExcel } from 'react-export-table-to-excel';
// import { ReactHTMLTableToExcel } from "react-html-table-to-excel";
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import {
//   CSVLink
//   // , CSVDownload
// } from "react-csv";
import logo from "../assets/img/icono.png";
import "./styles.css";
import { useSelector } from "react-redux";
import moment from 'moment/min/moment-with-locales';


import {
    exportComponentAsJPEG,
    // exportComponentAsPDF,
    //  exportComponentAsPNG 
} from 'react-component-export-image';
import { useHistory } from "react-router-dom";



const Payment = () => {

    const componentRef = useRef();
    const location = useLocation();
    const history = useHistory();

    const fileType = {
        PNG: 'image/png',
        JPEG: 'image/jpeg',
        PDF: 'application/pdf'
    };


    const orderDetails = location.state;
    // console.log("orderDetails ", orderDetails);
    const id = location.state.storeId;
    const comision = location.state.percentage;

    const allorderslistdata = useSelector(state => state.allorderslistdata);
    // console.log("allorderslistdata", allorderslistdata)
    // const orderslistdata = useSelector(state => state.orderslistdata);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalCancelados, setTotalCancelados] = useState(0);
    const [totalRecibidos, setTotalRecibidos] = useState(0);
    const [dataOrdenada, setDataOrdenada] = useState([]);

    const today = new Date();
    const yyyy = today.getFullYear();

    const monthdayAfter = ['DICIEMBRE', 'ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE'][new Date().getMonth()]
    const monthday = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'][new Date().getMonth()]
    const mes = monthday + ' de ' + yyyy;
    const mesAfter = monthdayAfter + ' de ' + yyyy;

    // console.log("mesAfter", mesAfter)

    const DEFAULT_JPEG = {
        fileName: `${orderDetails.name}-${mes}.jpeg`,
        type: fileType.JPEG,
        html2CanvasOptions: {},
        // pdfOptions: {
        //     w: 5000,
        //     // h: 3500,
        //     unit: 'px',
        //     x: 5,
        //     y: 5,
        //     pdfFormat:[1190.56, 1683.78]
        //   }
    };

    useEffect(() => {
        // let date = new Date();
        // let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // let primerDiaMesActual = Math.floor(new Date(firstDay).getTime());
        if (allorderslistdata.bookings) {
            // setData(orderslistdata.bookings.filter(booking => booking.restId === id  && booking.createdAt > primerDiaMesActual));
            setData(allorderslistdata.bookings.filter(booking => booking.restId === id));
        } else {
            setData([]);
        }
    }, [allorderslistdata.bookings, id]);
    // console.log("data ", data);

    useEffect(() => {
        let pedidosRecibidos = []
        let pedidosCancelado = []
        if (data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].status !== "Cancelado") {
                    pedidosRecibidos.push(data[i])
                } else {
                    pedidosCancelado.push(data[i])
                }
            }
            // console.log("pedidosRecibidos ", pedidosRecibidos);
            let totalBookings = pedidosRecibidos.reduce((accum, item) => accum + item.subTotal, 0)
            setTotal(totalBookings);
            setTotalRecibidos(pedidosRecibidos.length)
            setTotalCancelados(pedidosCancelado)
        } else {
            setTotal(0);
        }
    }, [data]);
    // console.log("total ", totalCancelados);
    // console.log("total ", total);

    useEffect(() => {
        if (data) {
            let databooking = [];
            for (let j = 0; j < data.length; j++) {
                const booking = data[j];
                if (booking) {
                    data[j].fecha = moment(data[j].createdAt).format(" MMM DD , h:mm a");
                    data[j].comsión = (data[j].subTotal * comision) / 100;
                    databooking.push(booking)
                }
            }
            setDataOrdenada(databooking);
            // setDataOrdenada(databooking.filter(order => order.status !== "Cancelado"));
        }
    }, [data, comision]);
    // console.log("dataOrdenada", dataOrdenada)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const ComponentToPrint = React.forwardRef((props, ref) => (

        <div ref={ref} >

            <div style={{ paddingRight: 50, paddingLeft: 50 }}>
                <div className="invoice">

                    <div className="divstyle1">
                        <div className="imageContain">
                            <h4 style={{ fontSize: "18px", marginBottom: -13, color: 'black', fontWeight: 'bold' }}>
                                Llevo a Casa
                            </h4>
                            <h5 style={{ fontSize: "18px", marginTop: 14, marginBottom: -15, color: 'black' }}>Teléfono 3233501876 </h5>
                            <h5 style={{ fontSize: "18px", marginTop: 14, marginBottom: -10, color: 'black' }}>ventas.llevoacasa@gmail.com</h5>

                        </div>
                        <div className="divstyle33">
                            <h4 style={{ fontSize: "15px", marginBottom: -13, color: 'black', fontWeight: 'bold' }}>
                                {"Para mayor comodidad consignar en:"}
                            </h4>
                            <h4 style={{ fontSize: "15px", marginBottom: -15, color: 'black' }}> {"Bancolombia ahorros 72854103122"}</h4>
                            <h4 style={{ fontSize: "15px", marginBottom: -15, color: 'black' }}>  {"Nequi 3142870770"}</h4>
                            <h4 style={{ fontSize: "15px", marginBottom: -15, color: 'black' }}> {"Daviplata 3137195451"}</h4>
                        </div>
                        <div className="divstyle2">
                            <img src={logo} width="150" alt="" />
                        </div>
                    </div>

                    <div>
                        <br></br>
                    </div>

                    <div className="mt-1 divStyle4">
                        <table width="100%" className="table">
                            <thead>
                                <tr>
                                    <th colSpan={6}>
                                        <h6 style={{ fontSize: "13px", color: 'black', textAlign: "center" }}>
                                            Establecimiento
                                        </h6>
                                    </th>
                                    <th colSpan={6}>
                                        <h6 style={{ fontSize: "13px", color: 'black', textAlign: "center" }}>
                                            Comisión
                                        </h6>
                                    </th>
                                    <th colSpan={6}>
                                        <h6 style={{ fontSize: "13px", color: 'black', textAlign: "center" }}>
                                            Periodo
                                        </h6>
                                    </th>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <h6 style={{ fontSize: "12px", textAlign: "center" }}>
                                            <strong style={{ fontSize: "13px", color: 'black' }}>{orderDetails.name}</strong>
                                        </h6>
                                    </td>
                                    <td colSpan={6}>
                                        <h6 style={{ fontSize: "12px", textAlign: "center" }}>
                                            <strong style={{ fontSize: "13px", color: 'black' }}>{orderDetails.percentage}{"%"}</strong>
                                        </h6>
                                    </td>
                                    <td colSpan={6}>
                                        <h6 style={{ fontSize: "12px", textAlign: "center" }}>
                                            <strong style={{ fontSize: "13px", color: 'black' }}>{mesAfter}</strong>
                                        </h6>
                                    </td>
                                </tr>

                            </thead>
                        </table>
                    </div>

                    <div>
                        <br></br>
                    </div>

                    <div className="mt-1 divStyle4">
                        <table width="100%" className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <h6 style={{ fontSize: "12px", textAlign: "center", paddingLeft: "5px", paddingRight: "10px", color: 'black' }}>#</h6>
                                    </th>
                                    <th>
                                        <h6 style={{ fontSize: "12px", textAlign: "center", paddingLeft: "10px", paddingRight: "10px", color: 'black' }}> Fecha</h6>
                                    </th>
                                    <th>
                                        <h6 style={{ fontSize: "12px", textAlign: "center", paddingLeft: "10px", paddingRight: "10px", color: 'black' }}> Nº Orden</h6>
                                    </th>
                                    <th>
                                        <h6 style={{ fontSize: "12px", textAlign: "center", paddingLeft: "10px", paddingRight: "10px", color: 'black' }}>Subtotal</h6>
                                    </th>
                                    <th>
                                        <h6 style={{ fontSize: "12px", textAlign: "center", paddingLeft: "10px", paddingRight: "10px", color: 'black' }}>Comisión</h6>
                                    </th>
                                    <th>
                                        <h6 style={{ fontSize: "12px", textAlign: "center", paddingLeft: "10px", paddingRight: "10px", color: 'black' }}>Estado</h6>
                                    </th>
                                </tr>

                                {dataOrdenada.map((item, i) => {
                                    return [
                                        <tr key={i}>
                                            <td style={{ fontSize: "12px", textAlign: "center", paddingLeft: "10px", color: 'black' }}>{i + 1}</td>
                                            <td style={{ fontSize: "12px", textAlign: "center", paddingLeft: "10px", color: 'black' }}>{item.fecha}</td>
                                            <td style={{ fontSize: "12px", textAlign: "center", paddingLeft: "10px", color: 'black' }}>{item.orderId}</td>
                                            <td style={{ fontSize: "12px", textAlign: "center", paddingLeft: "10px", color: 'black' }}>{item.status !== "Cancelado" ? "$" + (item.subTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</td>
                                            <td style={{ fontSize: "12px", textAlign: "center", paddingLeft: "10px", color: 'black' }}>{item.status !== "Cancelado" ? "$" + (item.comsión).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</td>
                                            <td style={{ fontSize: "12px", fontWeight: 'bold', textAlign: "center", paddingLeft: "10px", color: item.status === "Cancelado" ? 'red' : 'green' }}>{item.status === "Cancelado" ? "CANCELADO" : "ENTREGADO"}</td>
                                        </tr>

                                    ];
                                })}
                            </thead>
                        </table>
                    </div>

                    <div>
                        <br></br>
                    </div>

                    <div className="mt-1 divStyle4">
                        <table width="100%" className="table">
                            <thead>
                                <tr>
                                    <th colSpan={3}>
                                        <h6 style={{ fontSize: "13px", color: 'black', textAlign: "center" }}>
                                            Total Pedidos
                                        </h6>
                                    </th>
                                    <th colSpan={3}>
                                        <h6 style={{ fontSize: "13px", color: 'black', textAlign: "center" }}>
                                            Recibidos
                                        </h6>
                                    </th>
                                    <th colSpan={2}>
                                        <h6 style={{ fontSize: "13px", color: 'black', textAlign: "center" }}>
                                            Cancelados
                                        </h6>
                                    </th>
                                    <th colSpan={2}>
                                        <h6 style={{ fontSize: "13px", color: 'black', textAlign: "center" }}>
                                            Subtotal
                                        </h6>
                                    </th>
                                    <th colSpan={2}>
                                        <h6 style={{ fontSize: "13px", color: 'black', textAlign: "center" }}>
                                            Comisión
                                        </h6>
                                    </th>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <h6 style={{ fontSize: "12px", textAlign: "center" }}>
                                            <strong style={{ fontSize: "13px", color: 'black' }}>{data.length}</strong>
                                        </h6>
                                    </td>
                                    <td colSpan={3}>
                                        <h6 style={{ fontSize: "12px", textAlign: "center" }}>
                                            <strong style={{ fontSize: "13px", color: 'black' }}>{totalRecibidos}</strong>
                                        </h6>
                                    </td>
                                    <td colSpan={2}>
                                        <h6 style={{ fontSize: "12px", textAlign: "center" }}>
                                            <strong style={{ fontSize: "13px", color: 'black' }}>{totalCancelados.length}</strong>
                                        </h6>
                                    </td>
                                    <td colSpan={2}>
                                        <h6 style={{ fontSize: "12px", textAlign: "center" }}>
                                            <strong style={{ fontSize: "13px", color: 'black' }}>{"$"}{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</strong>
                                        </h6>
                                    </td>
                                    <td colSpan={2} style={{ backgroundColor: '#FBC1C1' }}>
                                        <h6 style={{ fontSize: "12px", textAlign: "center" }}>
                                            <strong style={{ fontSize: "18px", color: 'black' }}>{(orderDetails.comision).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</strong>
                                        </h6>
                                    </td>

                                </tr>

                            </thead>
                        </table>
                    </div>


                </div>

            </div>

        </div>
    ));

    return (

        <React.Fragment>

            <div width="100%" style={{ display: "flex", justifyContent: "center" }}>

                <button onClick={() => history.goBack()} style={{ justifyContent: "right", width: "33%" }} >
                    <h6 style={{ fontSize: "16px", color: "#000000", textAlign: "center" }}>
                        <strong>Volver a lista</strong>
                    </h6>
                </button>
                <button onClick={() => exportComponentAsJPEG(componentRef, DEFAULT_JPEG)} style={{ justifyContent: "center", width: "33%" }}>
                    <h6 style={{ fontSize: "16px", color: "#000000", textAlign: "center" }}>
                        <strong> Descargar Documento</strong>
                    </h6>
                </button>
                <button onClick={() => handlePrint()} style={{ justifyContent: "left", width: "33%" }}>
                    <h6 style={{ fontSize: "16px", color: "#000000", textAlign: "center" }}>
                        <strong> Imprimir Documento</strong>
                    </h6>
                </button>
                {/* <button onClick={() => onDownload()} style={{ justifyContent: "left", width: "33%" }}>
            <h6 style={{ fontSize: "16px", color: "#000000", textAlign: "center" }}>
              <strong> Descargar Excel</strong>
            </h6>
          </button> */}
                {/* <CSVLink
            data={dataOrdenada}
            filename={"my-file.csv"}
            className="btn btn-primary"
            target="_blank"
          >
            Download me
          </CSVLink>; */}

            </div>

            <ComponentToPrint ref={componentRef} />



        </React.Fragment>
    );

}

export default Payment;