import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';


export default function Lluvia() {
  const { api } = useContext(FirebaseContext);
//   const { t } = useTranslation();
  const {
    editLluvia,
    // addLluvia,
    // deleteLluvia,
    // updateLluvia
  } = api;
  const columns = [
    { title: 'Porcentaje de lluvia', field: 'lluvia', type: 'numeric'  , render: rowData => <span>{rowData.lluvia === 0 ? "0%" : rowData.lluvia > 0 ? rowData.lluvia + "%": ""}</span> },
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);

  const lluviadata = useSelector(state => state.lluviadata);
//   console.log("lluviadata" , lluviadata)
  const dispatch = useDispatch();

  useEffect(() => {
    if (lluviadata.simple) {
      setData(lluviadata.simple);
    }else{
      setData([]);
    }
  }, [lluviadata.simple]);


  return (
    lluviadata.loading ? <CircularLoading /> :
      <MaterialTable
        title={'PORCENTAJE DE LLUVIA'}
        columns={columns}
        data={data}
        localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} of {count}'
            },
            // toolbar: {
            //   nRowsSelected: '{0} row(s) selected',
            //   searchPlaceholder: 'Buscar pedido'
            // },
            header: {
              actions: 'Editar Porcentaje'
            },
            body: {
              emptyDataSourceMessage: 'No records to display',
              filterRow: {
                filterTooltip: 'Filter'
              }
            }
          }}
        options={{
          exportButton: true,
          pageSize: 10
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
            // onRowAdd: newData =>
            // new Promise(resolve => {
            //   setTimeout(() => {
            //     resolve();
            //     // const tblData = data;
            //     // newData.value = tblData.length
            //     // tblData.push(newData);
            //     // console.log(newData);
            //     dispatch(addLluvia(newData));
            //   }, 600);
            // }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // const tblData = data;
                // tblData[tblData.indexOf(oldData)] = newData;
                dispatch(editLluvia(oldData.id,newData));
              }, 600);
            }),
        //   onRowDelete: oldData =>
        //     new Promise(resolve => {
        //       setTimeout(() => {
        //         resolve();
        //         // const tblData = data;
        //         // tblData.splice(tblData.indexOf(oldData), 1);
        //         // for(let i=0;i<tblData.length;i++){
        //         //   tblData[i].value = i;
        //         // }
        //         dispatch(deleteLluvia(oldData.id));
        //       }, 600);
        //     }),
        } : null}
      />
  );
}
