import React, {
    useState,
    useEffect,
    useContext,
    // useRef
}
    from 'react';
import MaterialTable from 'material-table';
import { useTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import "./styles.css";
import { useHistory } from "react-router-dom";
import {
    useSelector,
    useDispatch
} from "react-redux";
import moment from 'moment'
import { FirebaseContext } from 'common';


const UserDetail = () => {

    const { api } = useContext(FirebaseContext);
    const {
        fetchAddress,
    } = api;
    // const componentRef = useRef();
    const location = useLocation();

    const userDetails = location.state;
    // console.log("userDetails ", userDetails);
    const userId = location.state.userId;
    // console.log("userId ",userId);
    // const orderslistdata = useSelector(state => state.orderslistdata);
    const allordersuserslistdata = useSelector(state => state.allordersuserslistdata);
    // console.log("orderslistdata ",orderslistdata);
    const usersaddressdata = useSelector(state => state.usersaddressdata);
    // console.log("usersaddressdata ",usersaddressdata);
    const [dataOrder, setDataOrder] = useState([]);
    const [dataAddress, setDataAddress] = useState([]);
    const dispatch = useDispatch();



    useEffect(() => {
        // let date = new Date();
        // let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // let primerDiaMesActual = Math.floor(new Date(firstDay).getTime());
        if (allordersuserslistdata.bookings) {
            setDataOrder(allordersuserslistdata.bookings.filter(bookings => bookings.userId === userId));
        } else {
            setDataOrder([]);
        }
    }, [allordersuserslistdata.bookings, userId]);
    // console.log("dataOrder ",dataOrder);

    useEffect(() => {
        // let date = new Date();
        // let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // let primerDiaMesActual = Math.floor(new Date(firstDay).getTime());
        if (usersaddressdata.address) {
            setDataAddress(usersaddressdata.address);
        } else {
            setDataAddress([]);
        }
    }, [usersaddressdata.address]);
    // console.log("dataAddress ",dataAddress);

    useEffect(() => {
        dispatch(fetchAddress(userId))
    }, [userId, dispatch, fetchAddress]);


    const history = useHistory();

    // const [data, setData] = useState([]);
    const theme = useTheme()

    const columnsAddress = [
        { title: 'Nombre ', field: 'name', },
        { title: 'Barrio', field: 'area', },
        { title: 'Dirección', field: 'address' },
        { title: 'Teléfono', field: 'mobileNo' },
        { title: 'Ubicación', render: rowData => <span>{rowData.locationInfo ? rowData.locationInfo.lat + " " + rowData.locationInfo.lng + " " : ""}</span> },
        // { title: 'Ubicación', render: rowData => <a href={`https://maps.google.com/maps?q=${rowData.locationInfo.lat},${rowData.locationInfo.lng}&hl=es;z=14&amp;output=embed`}>Test Link</a>
    ];
    const columns = [
        { title: '#', field: 'orderId' },
        { title: 'Fecha ', render: rowData => <span>{rowData.createdAt ? moment(rowData.createdAt).format(" MMM DD , h:mm a") : ""}</span> },
        { title: 'Estado', field: 'status' },
        { title: 'Establecimiento', field: 'restaurantName', },
        { title: 'SubTotal', render: rowData => <span>{rowData.subTotal ? "$" + (rowData.subTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
        { title: 'Dirección', render: rowData => <span>{rowData.shippingAddress ? rowData.shippingAddress.address : ""}</span> },
        // { title: 'UbiDirecación', render: rowData => <span>{rowData.locationInfo ? rowData.locationInfo.lat + " " + rowData.locationInfo.lng + " " : ""}</span> },
        // { title: 'Ubicación', render: rowData => <a href={`https://maps.google.com/maps?q=${rowData.locationInfo.lat},${rowData.locationInfo.lng}&hl=es;z=14&amp;output=embed`}>Test Link</a>
    ];

    return (
        <div>

            <div>
                <div className="container invoice">
                    <button onClick={() => history.goBack()}>Atras</button>
                    <div className="divstyle1">
                        <div className="imgContain">
                            <img src={userDetails.image} width="150" alt="" />
                        </div>
                    </div>
                    <div className="divstyle3">
                        <div>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan={6}>
                                                <h6 style={{ fontSize: "14px", color: "#5e5c5c" }}>
                                                    Nombre: &nbsp;&nbsp; <strong style={{ fontSize: "14px", color: "#000000" }}>{userDetails.name}</strong>
                                                </h6>
                                                <h6 style={{ fontSize: "14px", color: "#5e5c5c" }}>
                                                    Teléfono: &nbsp;&nbsp;<strong style={{ fontSize: "14px", color: "#000000" }}>{userDetails.mobileNo}</strong>
                                                </h6>
                                                <h6 style={{ fontSize: "14px", color: "#5e5c5c" }}>
                                                    Correo: &nbsp;&nbsp;<strong style={{ fontSize: "14px", color: "#000000" }}>{userDetails.email}</strong>
                                                </h6>
                                                {/* <h6 style={{ fontSize: "14px", color: "#5e5c5c" }}>
                                                    Id: &nbsp;&nbsp;
                                                    {userDetails.userId}
                                                </h6> */}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {/* <td>{userDetails.userId}</td>
                                            <td>{userDetails.userId}</td>
                                            <td>{userDetails.userId}</td> */}
                                            <td>
                                                <strong style={{ fontSize: "14px", color: "#000000" }}>
                                                    ID: &nbsp;&nbsp;{userDetails.userId}
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MaterialTable
                title={'Direcciones'}
                columns={columnsAddress}
                data={dataAddress}
                options={{
                    search: false,
                    exportButton: true,
                    justifyContent: "center",
                    textAlign: 'center',
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: theme.palette.action.hover,
                        color: theme.palette.common.black,
                        fontWeight: 'bold',
                        // textAlign: 'center',
                        flexDirection: 'row',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }
                }}

                actions={[

                    rowData => ({
                        icon: () =>
                            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                <LocationOnIcon />
                                {/* <Typography variant="subtitle2">{'mapa'}</Typography> */}
                            </div>,
                        disabled: rowData.locationInfo && rowData.locationInfo.lat && rowData.locationInfo.lat > 0 ? false : true,
                        onClick: (event, rowData) => {
                            // console.log("rowData" ,rowData)
                            window.open(`https://maps.google.com/maps?q=${rowData.locationInfo.lat},${rowData.locationInfo.lng}&hl=es;z=14&amp;output=embed`, '_blank').focus()
                        }
                    }),
                ]}
            //   editable={{
            //     onRowAdd: newData =>
            //       new Promise(resolve => {
            //         setTimeout(() => {
            //           resolve();
            //           const tblData = data;
            //           tblData.push(newData);
            //           settings.AllowCriticalEditsAdmin?
            //             dispatch(sendNotification(newData))
            //             :
            //             alert(t('demo_mode'));
            //           dispatch(editNotifications(newData,"Add"));
            //         }, 600);
            //       }),

            //       onRowUpdate: (newData, oldData) =>
            //       new Promise(resolve => {
            //         setTimeout(() => {
            //           resolve();
            //           dispatch(editNotifications(newData,"Update"));
            //         }, 600);
            //       }),
            //     onRowDelete: oldData =>
            //       new Promise(resolve => {
            //         setTimeout(() => {
            //           resolve();
            //           dispatch(editNotifications(oldData,"Delete"));
            //         }, 600);
            //       }),
            //   }}
            />

            <MaterialTable
                title={'Pedidos'}
                columns={columns}
                data={dataOrder}
                options={{
                    search: false,
                    exportButton: true,
                    justifyContent: "center",
                    textAlign: 'center',
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: theme.palette.action.hover,
                        color: theme.palette.common.black,
                        fontWeight: 'bold',
                        // textAlign: 'center',
                        flexDirection: 'row',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }
                }}

            // actions={[

            //     rowData => ({
            //         icon: () =>
            //             <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            //                 <LocationOnIcon />
            //                 {/* <Typography variant="subtitle2">{'mapa'}</Typography> */}
            //             </div>,
            //         disabled: rowData.locationInfo && rowData.locationInfo.lat && rowData.locationInfo.lat > 0 ? false:true,
            //         onClick: (event, rowData) => {
            //             // console.log("rowData" ,rowData)
            //             window.open(`https://maps.google.com/maps?q=${rowData.locationInfo.lat},${rowData.locationInfo.lng}&hl=es;z=14&amp;output=embed`, '_blank').focus()
            //         }
            //     }),
            // ]}
            //   editable={{
            //     onRowAdd: newData =>
            //       new Promise(resolve => {
            //         setTimeout(() => {
            //           resolve();
            //           const tblData = data;
            //           tblData.push(newData);
            //           settings.AllowCriticalEditsAdmin?
            //             dispatch(sendNotification(newData))
            //             :
            //             alert(t('demo_mode'));
            //           dispatch(editNotifications(newData,"Add"));
            //         }, 600);
            //       }),

            //       onRowUpdate: (newData, oldData) =>
            //       new Promise(resolve => {
            //         setTimeout(() => {
            //           resolve();
            //           dispatch(editNotifications(newData,"Update"));
            //         }, 600);
            //       }),
            //     onRowDelete: oldData =>
            //       new Promise(resolve => {
            //         setTimeout(() => {
            //           resolve();
            //           dispatch(editNotifications(oldData,"Delete"));
            //         }, 600);
            //       }),
            //   }}
            />
        </div>


    );
}

export default UserDetail;