import React, { useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
// import moment from 'moment/min/moment-with-locales';
import moment from 'moment/min/moment-with-locales';
import DateFnsUtils from '@date-io/date-fns';
import 'moment/locale/es';
import { useTheme } from "@material-ui/core/styles";
import SearchTimeIcon from '@material-ui/icons/Search';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import DashboardCard from '../components/DashboardCard';

// import Filter1Icon from '@material-ui/icons/Filter1';
// import Filter2Icon from '@material-ui/icons/Filter2';
// import Filter3Icon from '@material-ui/icons/Filter3';
// import Filter4Icon from '@material-ui/icons/Filter4';
// import Filter5Icon from '@material-ui/icons/Filter5';
// import Filter6Icon from '@material-ui/icons/Filter6';
// import Filter7Icon from '@material-ui/icons/Filter7';
// import Filter8Icon from '@material-ui/icons/Filter8';
// import Filter9Icon from '@material-ui/icons/Filter9';
// import Filter9PlusIcon from '@material-ui/icons/Filter9Plus';

// import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  Grid,
  // Paper,
  // Typography
} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

const MyNewTitle = ({ text, variant }) => (
  <Typography
    variant={variant}
    style={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",

    }}
  >
    {text}
  </Typography>
);
// import {
//   // Grid,
//   // Typography,
//   // Modal,
//   // Button,
//   // FormControlLabel,
//   // FormControl,
//   // FormLabel,
//   // Radio,
//   // RadioGroup,
// } from '@material-ui/core';

// import {
//   Avatar,
//   Button,
//   Chip,
//   Container,
//   FormControlLabel,
//   Switch
// } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 480,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: 'center'
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: 'center'
  },
  items: {
    margin: 0,
    width: '100%'
  },
  input: {
    fontSize: 18,
    color: "#000"
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373"
  },
  carphoto: {
    height: '18px',
    marginRight: '10px'
  },
  buttonStyle: {
    margin: 0,
    width: '100%',
    height: '100%'
  }
}));

export default function UsersAppDelivery() {
  const { t, i18n } = useTranslation();
  const { api } = useContext(FirebaseContext);
  const {
    // addUser,
    editUserAppDelivery,
    // deleteUserApp,
    // checkUserExists,
    sendSmsTemplate,
    // sendSms,
    // senAllOrders,
    senAllOrdersUsers
  } = api;
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState([]);
  const [dataBooking, setDataBooking] = useState([]);
  const isRTL = i18n.dir();
  // console.log("setData " , setData)
  // const [cars, setCars] = useState({});
  const usersdata = useSelector(state => state.usersdata);
  const usersappdata = useSelector(state => state.usersappdata);
  // const orderslistdata = useSelector(state => state.orderslistdata);
  // const allorderslistdata = useSelector(state => state.allorderslistdata);
  // console.log("usersappdata " , usersappdata)
  const templatedata = useSelector(state => state.templatedata);
  // console.log("templatedata" , templatedata)
  const allordersuserslistdata = useSelector(state => state.allordersuserslistdata);
  // console.log("allordersuserslistdata " , allordersuserslistdata)
  // const cartypes = useSelector(state => state.cartypes);
  // const auth = useSelector(state => state.auth);
  // const settings = useSelector(state => state.settingsdata.settings);
  // console.log("settings " , settings)

  const [selectedDateInicio, setSelectedDateInicio] = useState();
  const [selectedDateInicioValue, setSelectedDateInicioValue] = useState(null);
  const [selectedDateInicioMoment, setSelectedDateInicioMoment] = useState(null);
  const [selectedDateFin, setSelectedDateFin] = useState();
  const [selectedDateFinValue, setSelectedDateFinValue] = useState(null);
  const [selectedDateFinMoment, setSelectedDateFinMoment] = useState(null);
  const [total, setTotal] = useState(0);
  // const [sms, setSms] = useState([]);
  const [templateActions, setTemplateActions] = useState([]);
  // const [dataFecha, setDataFecha] = useState([]);
  // const [dataMesConsulta, setDataMesConsulta] = useState(0);
  // const [servicesCount, setServicesCount] = useState(0);

  // const iconos = [
  //   { id: 1, description: "icon", name: "Filter1Icon", icon: Filter1Icon },
  //   { id: 2, description: "icon", name: "Filter2Icon", icon: Filter2Icon },
  //   { id: 3, description: "icon", name: "Filter3Icon", icon: Filter3Icon },
  //   { id: 4, description: "icon", name: "Filter4Icon", icon: Filter4Icon },
  //   { id: 5, description: "icon", name: "Filter5Icon", icon: Filter5Icon },
  //   { id: 6, description: "icon", name: "Filter6Icon", icon: Filter6Icon },
  //   { id: 7, description: "icon", name: "Filter7Icon", icon: Filter7Icon },
  //   { id: 8, description: "icon", name: "Filter8Icon", icon: Filter8Icon },
  //   { id: 9, description: "icon", name: "Filter9Icon", icon: Filter9Icon },
  //   { id: 10, description: "icon", name: "Filter9PlusIcon", icon: Filter9PlusIcon },
  // ]

  const array = Array.from(Array(100).keys());
  // console.log("array", array)

  const dispatch = useDispatch();
  const tableRef = useRef()
  const theme = useTheme()
  const history = useHistory();
  const classes = useStyles();
  const rootRef = useRef(null);

  useEffect(() => {
    if (usersappdata.address) {
      // setData(usersdata.users.filter(user => user.role ==='Manager' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin')|| auth.info.profile.usertype === 'admin')));
      // setData(usersappdata.users.filter(user => user.role ==='Manager' && user.city ==='CARTAGO' ));
      // setData(usersappdata.users.filter(user => user.role === 'User' ));
      setData(usersappdata.address);
    } else {
      setData([]);
    }
  }, [usersappdata.address]);
  // console.log("data ", data)

  useEffect(() => {
    // let date = new Date();
    // let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // let primerDiaMesActual = Math.floor(new Date(firstDay).getTime());
    // console.log("primerDiaMesActual", primerDiaMesActual)
    if (allordersuserslistdata.bookings) {
      setDataBooking(allordersuserslistdata.bookings);
    } else {
      setDataBooking([]);
    }
  }, [allordersuserslistdata.bookings]);
  // console.log("dataBooking", dataBooking)

  useEffect(() => {
    if (templatedata.simple) {
      setTemplate(templatedata.simple);
    } else {
      setTemplate([]);
    }
  }, [templatedata.simple]);
  // console.log("template", template)

  useEffect(() => {
    const usersBooking = [];
    const renderobj = {};
    const renderFechas = {};
    moment.locale('es');
    if (dataBooking && data) {
      for (let j = 0; j < dataBooking.length; j++) {
        const userIdBooking = dataBooking[j].userId;
        usersBooking.push({
          uniqueKey: userIdBooking,
          bookings: dataBooking[j],
          fechas: (moment(dataBooking[j].createdAt).format("MMMM")).toUpperCase()
        })
      }

      for (let i = 0; i < data.length; i++) {
        const userId = data[i].userId;
        let values = [];
        let fechas = [];

        for (let h = 0; h < usersBooking.length; h++) {
          const uniqueKey = usersBooking[h].uniqueKey;
          if (userId === uniqueKey) {
            values.push(usersBooking[h].bookings);
            if (!fechas.includes(usersBooking[h].fechas)) {
              fechas.push(usersBooking[h].fechas);
            }
            // fechas.push(usersBooking[h].fechas);
            renderobj[uniqueKey] = {};
            renderobj[uniqueKey] = values;
            renderFechas[uniqueKey] = {};
            renderFechas[uniqueKey] = fechas;
            data[i].booking = renderobj[uniqueKey];
            data[i].fechas = (renderFechas[uniqueKey]).toString();
            // data.map((item, h) => ({...item, booking: usersBooking[h]}))
          }
        }
        if (!data[i].fechas) {
          data[i].fechas = "SINPEDIDOS"
        }
      }
      // console.log("usersBooking", usersBooking)
    }
  }, [dataBooking, data]);

  useEffect(() => {
    if (selectedDateFin && selectedDateInicio) {
      let fecha = [
        {
          "selectedDateFin": selectedDateFin,
          "selectedDateInicio": selectedDateInicio
        }
      ]
      // setDataFecha(fecha)
      dispatch(senAllOrdersUsers(fecha))
    }
  }, [selectedDateFin, selectedDateInicio, dispatch, senAllOrdersUsers])
  // console.log("dataFecha", dataFecha)

  const handleDateChangeInicio = (dateInicio) => {
    const start = moment(dateInicio).startOf('day');
    const unixTimestamp = Math.floor(start._d.getTime() / 1000) * 1000;
    setSelectedDateInicio(unixTimestamp);
    setSelectedDateInicioValue(dateInicio);
    setSelectedDateInicioMoment(moment(unixTimestamp).format(" MMM DD "))

    // setDataMesConsulta(0);
    setSelectedDateFin(null);
    setSelectedDateFinValue(null);
    setSelectedDateFinMoment(null)
  };
  // console.log("selectedDateInicio", selectedDateInicio)

  const handleDateChangeFin = (dateFin) => {
    const end = moment(dateFin).endOf('day');
    const unixTimestampEnd = Math.floor(end._d.getTime() / 1000) * 1000;
    setSelectedDateFin(unixTimestampEnd);
    setSelectedDateFinValue(dateFin);
    setSelectedDateFinMoment(moment(unixTimestampEnd).format(" MMM DD "))
  };
  // console.log("selectedDateFin", selectedDateFin)

  const handleCheckboxClick = (rowData) => {
    // console.log("rowData ", rowData)
    if (rowData.length > 0) {
      let actionsTemplate = [];
      for (let i = 0; i < template.length; i++) {
        // template[i].icon = iconos[i].icon;
        template[i].number = array[i];
        let templateId = template[i];
        actionsTemplate.push({
          'icon': () =>
            <div>
              <span style={{
                fontSize: "20px",
                fontFamily: "sans-serif",
                color: "#C60930",
                fontWeight: "bold",
              }} >
                {templateId.number + 1}
              </span>
              {/* <templateId.icon style={{ color: "000000" }} /> */}
            </div>,
          'tooltip': templateId.campaign,
          'onClick': (event, rowData) => {
            let arrSms = [];
            for (let i = 0; i < rowData.length; i++) {
              if (rowData[i].mobileNo !== " ") {
                if (rowData[i].name) {
                  let firstWord = rowData[i].name.split(" ")[0]
                  arrSms.push({
                    'message': `LLEVO A CASA APP: Hola ${(firstWord).toUpperCase()}. ${templateId.sms}`,
                    'recipient': `+57${rowData[i].mobileNo}`,
                  });
                } else {
                  arrSms.push({
                    'message': `LLEVO A CASA APP: ${templateId.sms}`,
                    'recipient': `+57${rowData[i].mobileNo}`,
                  });
                }
              }
            }
            // console.log("arrSms ", arrSms)
            // console.log("rowData ", rowData)
            // console.log("event ", event)
            dispatch(sendSmsTemplate(arrSms));
            clearSelection();
            alert("Mensaje enviado correctamente.");
          },
          'position': "toolbarOnSelect"
        });
      }
      setTemplateActions(actionsTemplate)
      setTotal(rowData.length);
    } else {
      setTotal(0);
      setTemplateActions([])
    }
  };
  // console.log("template", template)
  // console.log("templateActions", templateActions)
  // console.log("sms ", sms)
  // console.log("total ", total)

  // const handleSmsAllTemplate = (event) => {
  //   console.log("event ", event)
  //   // let arrSms = [];
  //   // if (sms && template) {
  //   //   for (let i = 0; i < sms.length; i++) {
  //   //     for (let j = 0; j < template.length; j++) {
  //   //       arrSms.push({
  //   //         'message': `${template[j].sms}`,
  //   //         'recipient': `+57${sms[i].mobileNo}`,
  //   //       });
  //   //     }
  //   //   }
  //   // }
  //   clearSelection();
  //   // // dispatch(sendSmsTemplate(arrSms));
  //   // console.log("arrSms ", arrSms)
  // };

  // const handleSmsTemplate1 = () => {
  //   let arr1 = [];
  //   if (sms && template) {
  //     for (let i = 0; i < sms.length; i++) {
  //       arr1.push({
  //         'message': `${template[0].sms}`,
  //         'recipient': `+57${sms[i].mobileNo}`,
  //       });
  //     }
  //   }
  //   clearSelection();
  //   dispatch(sendSmsTemplate(arr1));
  //   // console.log("arr1 ", arr1)
  // };
  // const handleSmsTemplate2 = () => {
  //   let arr2 = [];
  //   if (sms && template) {
  //     for (let i = 0; i < sms.length; i++) {
  //       let firstWord = sms[i].name.split(" ")[0]
  //       arr2.push({
  //         'message': `LLEVO A CASA: Hola ${(firstWord).toUpperCase()}. ${template[1].sms}`,
  //         'recipient': `+57${sms[i].mobileNo}`,
  //       });
  //     }
  //   }
  //   clearSelection();
  //   dispatch(sendSmsTemplate(arr2));
  //   // console.log("arr2 ", arr2)
  // };

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false)
    }
  }


  const columns = [
    // { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null},
    { title: 'Registro', field: 'createAtUser', editable: 'never', render: rowData => rowData.createAtUser ? moment(rowData.createAtUser).format('lll') : null },
    { title: 'Nombre', field: 'name' },
    { title: 'Correo', field: 'email', editable: 'never' },
    { title: 'Teléfono', field: 'mobileNo' },
    { title: 'PlayerId', field: 'playerId' },
    // { title: 'Meses', render: rowData => <span>{rowData.fechas ? rowData.fechas : ""}</span> },
    { title: 'Meses', field: 'fechas', editable: 'never' },
    { title: 'Pedidos', render: rowData => <span>{rowData.booking ? rowData.booking.length : ""}</span> },
    // { title: 'UserId', render: rowData => <span>{rowData.fechas ? rowData.fechas : ""}</span> },
    // { title: 'Imágen', field: 'image', render: rowData => rowData.image && rowData.image !== "http://www.llevoa.casa/assets/images/person-outline-filled3.png" ? <img alt='Profile' src={rowData.image} style={{ width: 50, borderRadius: '50%' }} /> : null, editable: 'never' },
  ];

  // useEffect(() => {
  //   if (template) {
  //     let actionsTemplate = [];
  //     for (let i = 0; i < template.length; i++) {
  //       template[i].icon = iconos[i].icon;
  //       let templateId = template[i];
  //       actionsTemplate.push({
  //         'icon': () =>
  //           <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
  //             <templateId.icon style={{ color: "000000" }} />
  //           </div>,
  //         'tooltip': templateId.campaign,
  //         'onClick': (event, rowData) => {
  //           let arrSms = [];
  //           for (let i = 0; i < rowData.length; i++) {
  //             if (rowData[i].name) {
  //               let firstWord = rowData[i].name.split(" ")[0]
  //               arrSms.push({
  //                 'message': `LLEVO A CASA: Hola ${(firstWord).toUpperCase()}. ${templateId.sms}`,
  //                 'recipient': `+57${rowData[i].mobileNo}`,
  //               });
  //             } else {
  //               arrSms.push({
  //                 'message': `LLEVO A CASA: ${templateId.sms}`,
  //                 'recipient': `+57${rowData[i].mobileNo}`,
  //               });
  //             }
  //           }
  //           console.log("arrSms ", arrSms)
  //           console.log("rowData ", rowData)
  //           console.log("event ", event)
  //           clearSelection();
  //         },
  //         'position': "toolbarOnSelect"
  //       });
  //     }
  //     setTemplateActions(actionsTemplate)
  //   }
  // }, [template]);
  // console.log("templateActions", templateActions)

  const actions = [
    {
      icon: () =>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <SearchTimeIcon style={{ color: "000000" }} />
        </div>,
      tooltip: 'Ver usuario',
      onClick: (event, rowData) => {
        history.push({
          pathname: '/userDetail',
          state: rowData
        })
      },
      position: "row"
    },
    // {
    //   icon: () =>
    //     <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
    //       <Filter1Icon style={{ color: "000000" }} />
    //     </div>,
    //   tooltip: 'Plantilla sin encabezado',
    //   onClick: (event, rowData) => {
    //     // handleSmsTemplate1();
    //   },
    //   position: "toolbarOnSelect"
    // },
    // {
    //   icon: () =>
    //     <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
    //       <Filter2Icon style={{ color: "000000" }} />
    //     </div>,
    //   tooltip: 'Plantilla con encabezado',
    //   onClick: (event, rowData) => {
    //     // handleSmsTemplate2();
    //   },
    //   position: "toolbarOnSelect",
    // }
  ];
  // console.log("actions ", actions)

  return (
    usersdata.loading ? <CircularLoading /> :
      <div className={classes.container} ref={rootRef}>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={2} >

            <Grid item xs={12} sm={12} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
              <DashboardCard title={`${selectedDateInicioMoment ? "Fecha consulta: " + selectedDateInicioMoment : "Consulta por días"} ${selectedDateInicioMoment ? " - " : ""} ${selectedDateFinMoment ? selectedDateFinMoment : ""} `} image={require("../assets/img/money3.jpg").default}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>

                    <Grid item xs={12} sm={6} md={6} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <KeyboardDatePicker style={{ marginTop: 8, width: 300 }}
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="INICIO"
                        value={selectedDateInicioValue}
                        onChange={handleDateChangeInicio}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', paddingLeft: 20 }}>
                      <KeyboardDatePicker style={{ marginTop: 8, width: 300 }}
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="FIN"
                        disabled={selectedDateInicio ? false : true}
                        value={selectedDateFinValue}
                        onChange={handleDateChangeFin}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>

                  </Grid>
                </MuiPickersUtilsProvider>
              </DashboardCard>
            </Grid>

          </Grid>
        </Grid>

        <div style={{ paddingTop: 20 }}>
          <MaterialTable
            // title={'Usuarios App Cartago'}
            tableRef={tableRef}
            title={<MyNewTitle variant="h6" text={total > 0 ? "seleccionados " + total : "seleccionados 0"} />}
            columns={columns}
            onSelectionChange={(event) => {
              handleCheckboxClick(event);
              // let totalBookings = event.reduce((accum, item) => accum + item.trip_cost, 0)
              // setTotal(totalBookings);
              // console.log("event ", event)
              // console.log("total ",total)
            }}
            data={data}
            actions={templateActions.length > 0 ? templateActions : actions}
            options={{
              // toolbar: false,
              exportAllData: true,
              filtering: true,
              selection: true,
              showTextRowsSelected: false,
              // exportButton: settings.AllowCriticalEditsAdmin,
              exportButton: {
                csv: true,
                pdf: true
              },
              // exportCsv: (data, columns) => console.log(data, columns, '<== CSV'),
              // exportPdf: (data, columns) => console.log(data, columns, '<== PDF'),
              sorting: true,
              pageSize: 20,
              textAlign: 'center',
              showTitle: true,
              search: true,
              searchFieldAlignment: "right",
              headerStyle: {
                backgroundColor: theme.palette.action.hover,
                color: theme.palette.common.black,
                fontWeight: 'bold',
                // textAlign: 'center',
                flexDirection: 'row',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
            }}
            localization={{
              body: {
                addTooltip: (t('add')),
                deleteTooltip: (t('delete')),
                editTooltip: ('Editar Servicio'),
                emptyDataSourceMessage: (
                  (t('blank_message'))
                ),
                editRow: {
                  deleteText: (t('delete_message')),
                  cancelTooltip: (t('cancel')),
                  saveTooltip: (t('save'))
                },
              },
              toolbar: {
                searchPlaceholder: ('Buscar usuario'),
                exportTitle: ('Exportar'),
              },
              header: {
                actions: ('Acciones')
              },
              pagination: {
                labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
                labelRowsSelect: (t('rows')),
                firstTooltip: (t('first_page_tooltip')),
                previousTooltip: (t('previous_page_tooltip')),
                nextTooltip: (t('next_page_tooltip')),
                lastTooltip: (t('last_page_tooltip'))
              },
            }}

            // actions={[
            //   rowData => ({
            //     icon: () =>
            //       <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            //         <Filter1Icon style={{ color: "000000" }} />
            //       </div>,
            //     tooltip: 'Plantilla sin encabezado',
            //     position: "toolbarOnSelect",
            //     onClick: (event, rowData) => {
            //       handleSmsTemplate1();
            //     }
            //   }),
            //   rowData => ({
            //     icon: () =>
            //       <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            //         <Filter2Icon style={{ color: "000000" }} />
            //       </div>,
            //     tooltip: 'Plantilla con encabezado',
            //     position: "toolbarOnSelect",
            //     onClick: (event, rowData) => {
            //       handleSmsTemplate2();
            //     }
            //   }),
            //   rowData => ({
            //     icon: () =>
            //       <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            //         <SearchTimeIcon style={{ color: "000000" }} />
            //       </div>,
            //     tooltip: 'Ver usuario',
            //     position: "toolbarOnSelect",
            //     onClick: (event, rowData) => {
            //       history.push({
            //         pathname: '/userDetail',
            //         state: rowData
            //       })
            //     }
            //   })
            // ]}

            editable={{
              // onRowAdd: newData =>
              //   new Promise((resolve, reject) => {
              //     setTimeout(() => {
              //       checkUserExists(newData).then((res) => {
              //         if (res.users && res.users.length > 0) {
              //           alert(t('user_exists'));
              //           reject();
              //         }
              //         else if (res.error) {
              //           alert(t('email_or_mobile_issue'));
              //           reject();
              //         }
              //         else {
              //           newData['createdByAdmin'] = true;
              //           newData['usertype'] = 'driver';
              //           newData['createdAt'] = new Date().toISOString();
              //           newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
              //           let role = auth.info.profile.usertype;
              //           if (role === 'fleetadmin') {
              //             newData['fleetadmin'] = auth.info.uid;
              //           }
              //           dispatch(addUser(newData));
              //           resolve();
              //         }
              //       });
              //     }, 600);
              //   }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    dispatch(editUserAppDelivery(oldData.id, newData));
                  }, 600);
                }),

              // onRowDelete: oldData =>
              //   settings.AllowCriticalEditsAdmin ?
              //     new Promise(resolve => {
              //       setTimeout(() => {
              //         resolve();
              //         dispatch(deleteUserApp(oldData.id));
              //       }, 600);
              //     })
              //     :
              //     new Promise(resolve => {
              //       setTimeout(() => {
              //         resolve();
              //         alert(t('demo_mode'));
              //       }, 600);
              //     }),

            }}
          />
        </div>
      </div>
  );
}
