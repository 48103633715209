import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
// import moment from 'moment/min/moment-with-locales';
import moment from 'moment'
// import 'moment/min/locales'
import { useTheme } from "@material-ui/core/styles";
import {
  // Avatar,
  // Button,
  // Chip,
  // Container,
  FormControlLabel,
  Switch,
  // TextField
} from "@material-ui/core";

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    updateApprovedDriver,
    updateActiveDriver,
    updateQueueDriver
  } = api;
  const [data, setData] = useState([]);
  const [cars, setCars] = useState({});
  const usersdata = useSelector(state => state.usersdata);
  const cartypes = useSelector(state => state.cartypes);
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();

  const theme = useTheme()

  useEffect(() => {
    if (usersdata.users) {
      setData(usersdata.users.filter(user => user.usertype === 'driver' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin') || auth.info.profile.usertype === 'admin' || auth.info.profile.usertype === 'adminPpal')));
    } else {
      setData([]);
    }
  }, [usersdata.users, auth.info.profile.usertype, auth.info.uid]);

  useEffect(() => {
    if (cartypes.cars) {
      let obj = {};
      cartypes.cars.map((car) => obj[car.name] = car.name)
      setCars(obj);
    }
  }, [cartypes.cars]);

  const changeApproved = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    // console.log("newRow" ,newRow);
    if (newRow) {
      dispatch(
        updateApprovedDriver(newRow
          //     {
          //   approved: newRow.approved,
          //   booking: newRow.id,
          //   // cancelledBy: role
          // }
        ));
    }
  };
  const changeActive = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    // console.log("newRow" ,newRow);
    if (newRow) {
      dispatch(
        updateActiveDriver(newRow
          //     {
          //   approved: newRow.approved,
          //   booking: newRow.id,
          //   // cancelledBy: role
          // }
        ));
    }
  };
  const changeQueue = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    // console.log("newRow" ,newRow);
    if (newRow) {
      dispatch(
        updateQueueDriver(newRow
          //     {
          //   approved: newRow.approved,
          //   booking: newRow.id,
          //   // cancelledBy: role
          // }
        ));
    }
  };

  const columns = [
    { title: t('createdAt'), field: 'createdAt', editable: 'never', defaultSort: 'desc', render: rowData => rowData.createdAt ? moment(rowData.createdAt).format('lll') : null },
    // { title: t('first_name'), field: 'firstName', initialEditValue: '' },
    // { title: t('last_name'), field: 'lastName', initialEditValue: '' },
    { title: 'Repartidor', field: 'firstName', render: rowData => <span>{rowData.firstName ? rowData.firstName + " " + rowData.lastName : ""}</span> },
    // { title: t('account_approve'),  field: 'approved', type:'boolean', initialEditValue: true },
    {
      title: "Aprobado",
      field: "approved",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.approved}
              onChange={e => changeApproved(data, e)}
              name="approved"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.approved === true ? 'green' : data.approved === false ? 'red' : null }}>{data.approved === true ? 'APROBADO' : 'INACTIVO'}</span>}
        />
      )
    },
    // { title: 'Estado activo',  field: 'driverActiveStatus', type:'boolean', initialEditValue: true},
    {
      title: "Ocupado/Disponible",
      field: "driverActiveStatus",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.driverActiveStatus}
              onChange={e => changeActive(data, e)}
              name="driverActiveStatus"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.driverActiveStatus === true ? 'green' : data.driverActiveStatus === false ? 'red' : null }}>{data.driverActiveStatus === true ? 'DISPONIBLE' : 'OCUPADO'}</span>}
        />
      )
    },
    // { title: t('queue'),  field: 'queue', type:'boolean', initialEditValue: false },
    { title: t('email'), field: 'email', editable: 'onAdd', render: rowData => settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo" },
    { title: 'Teléfono', field: 'mobile', editable: 'onAdd', render: rowData => settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo" },
    { title: 'Imagen perfil', field: 'profile_image', render: rowData => rowData.profile_image ? <img alt='Profile' src={rowData.profile_image} style={{ width: 50, borderRadius: '50%' }} /> : null, editable: 'never' },
    { title: 'Marca vehiculo', field: 'vehicleMake', initialEditValue: '' },
    { title: 'Modelo vehiculo', field: 'vehicleModel', initialEditValue: '' },
    { title: 'Placa', field: 'vehicleNumber', initialEditValue: '' },
    { title: 'Direccion', field: 'other_info', initialEditValue: '' },
    { title: t('car_type'), field: 'carType', lookup: cars },
    { title: t('lisence_image'), field: 'licenseImage', render: rowData => rowData.licenseImage ? <img alt='License' src={rowData.licenseImage} style={{ width: 100 }} /> : null, editable: 'never' },
    { title: t('wallet_balance'), field: 'walletBalance', editable: 'never', render: rowData => <span>{rowData.walletBalance ? "$" + rowData.walletBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: t('you_rated_text'), render: rowData => <span>{rowData.ratings ? rowData.ratings.userrating : "0"}</span>, initialEditValue: 0 },
    { title: t('signup_via_referral'), field: 'signupViaReferral', editable: 'never' },
    { title: 'ID ref registro', field: 'referralId', initialEditValue: '' },
    { title: t('bankName'), field: 'bankName', initialEditValue: '' },
    { title: t('bankCode'), field: 'bankCode', initialEditValue: '' },
    { title: t('bankAccount'), field: 'bankAccount', initialEditValue: '' },
    {
      title: "Ocupado x pedidos",
      field: "queue",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.queue}
              onChange={e => changeQueue(data, e)}
              name="queue"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.queue === true ? 'red' : data.queue === false ? 'green' : null }}>{data.queue === true ? 'OCUPADO' : 'DISPONIBLE '}</span>}
        />
      )
    },
    { title: 'Key', field: 'id', initialEditValue: '' },
  ];

  return (
    usersdata.loading ? <CircularLoading /> :
      <MaterialTable
        title={t('drivers')}
        columns={columns}
        data={data}
        options={{
          pageSize: 20,
          // toolbar: false,
          exportAllData: true,
          showTitle: true,
          search: true,
          searchFieldAlignment: "left",
          exportButton: settings.AllowCriticalEditsAdmin,
          sorting: true,
          headerStyle: {
            backgroundColor: "#EFE9E9",
            color: theme.palette.common.black,
            fontWeight: 'bold',
            textAlign: 'center',
            flexDirection: 'row',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'sticky',
            top: 0,
          },
          maxBodyHeight: '900px',
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                checkUserExists(newData).then((res) => {
                  if (res.users && res.users.length > 0) {
                    alert(t('user_exists'));
                    reject();
                  }
                  else if (res.error) {
                    alert(t('email_or_mobile_issue'));
                    reject();
                  }
                  else {
                    newData['createdByAdmin'] = true;
                    newData['usertype'] = 'driver';
                    newData['createdAt'] = new Date().toISOString();
                    newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
                    let role = auth.info.profile.usertype;
                    if (role === 'fleetadmin') {
                      newData['fleetadmin'] = auth.info.uid;
                    }
                    dispatch(addUser(newData));
                    resolve();
                  }
                });
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editUser(oldData.id, newData));
              }, 600);
            }),
          onRowDelete: oldData =>
            settings.AllowCriticalEditsAdmin ?
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  dispatch(deleteUser(oldData.id));
                }, 600);
              })
              :
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  alert(t('demo_mode'));
                }, 600);
              })
          ,
        }}
      />
  );
}
