import React, { useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
// import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
// import ConfirmationStatus from 'components/ConfirmmationStatus';
import ConfirmmationStatusDomi from 'components/ConfirmmationStatusDomi';
import {
  Grid,
  Typography,
  Modal,
  Button,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { FirebaseContext } from 'common';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
import UsersCombo from '../components/UsersCombo';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
// import moment from 'moment/min/moment-with-locales';
// import moment from 'moment/min/moment-with-locales';
import moment from 'moment'
import 'moment/min/locales'
// import CancelIcon from '@material-ui/icons/Cancel';
// import CachedIcon from '@material-ui/icons/Cached';
// import PaymentIcon from '@material-ui/icons/Payment';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOrder from 'components/DeleteOrder';

import { useTheme } from "@material-ui/core/styles";

import useSound from "use-sound";
import beep from "../assets/sounds/beep.mp3";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const icons = {
  'paypal': require('../assets/img/payment-icons/paypal-logo.png').default,
  'braintree': require('../assets/img/payment-icons/braintree-logo.png').default,
  'stripe': require('../assets/img/payment-icons/stripe-logo.png').default,
  'paytm': require('../assets/img/payment-icons/paytm-logo.png').default,
  'payulatam': require('../assets/img/payment-icons/payulatam-logo.png').default,
  'flutterwave': require('../assets/img/payment-icons/flutterwave-logo.png').default,
  'paystack': require('../assets/img/payment-icons/paystack-logo.png').default,
  'securepay': require('../assets/img/payment-icons/securepay-logo.png').default,
  'payfast': require('../assets/img/payment-icons/payfast-logo.png').default,
  'liqpay': require('../assets/img/payment-icons/liqpay-logo.png').default,
  'culqi': require('../assets/img/payment-icons/culqi-logo.png').default,
  'mercadopago': require('../assets/img/payment-icons/mercadopago-logo.png').default
}

const BookingHistory = (props) => {
  const { api, appcat } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    // cancelBooking,
    updateBooking,
    RequestPushMsg,
    cancelBookingAdmin,
    deleteBooking
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const userdata = useSelector(state => state.usersdata);
  const settings = useSelector(state => state.settingsdata.settings);
  const [role, setRole] = useState(null);
  const [paymentModalStatus, setPaymentModalStatus] = useState(false);
  const providers = useSelector(state => state.paymentmethods.providers);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedProviderIndex, setSelectedProviderIndex] = useState(0);
  const [data, setData] = useState([]);
  const [dataOrdenada, setDataOrdenada] = useState([]);
  // console.log("data", data)
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState();
  // console.log("selectedBooking", selectedBooking)
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  // console.log("bookinglistdata", bookinglistdata)
  const [users, setUsers] = useState(null);
  const [userCombo, setUserCombo] = useState(null);
  const rootRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const [bookinngsDelete, setBookinngsDelete] = useState([]);
  const [openConfirmDeleteOrder, setOpenConfirmDeleteOrder] = useState(false);
  const classes = useStyles();
  const columns = [
    // { title: "Establecimiento", field: 'nameEstablishment', cellStyle: { paddingLeft: 20 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 15, color: 'black' }}>{rowData.nameEstablishment}</span> },
    {
      title: "Establecimiento", field: 'nameEstablishment', cellStyle: { paddingLeft: 20 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 15, color: '#000000' }}>
        {rowData.restaurantName ? rowData.restaurantName :
          rowData.booking_type_ride === false && rowData.booking_type_admin === true && !rowData.addressPickup ? "ADMIN ESTABLECIMIENTO" :
            rowData.booking_type_admin === false ? rowData.pickupAddress :
              rowData.booking_type_admin === true && rowData.addressPickup ? "ADMIN INICIO" :
                rowData.booking_type_rider === true && !rowData.addressPickup ? "ADMIN PERFIL" :
                  "ADMIN ESTABLECIMIENTO"
        }</span>
    },
    { title: "Pedido", field: 'orderId', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 15, color: 'black' }}>{rowData.orderId}</span> },
    { title: t('booking_status'), field: 'status', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 16, color: rowData.status === "NEW" ? '#EF9701' : rowData.status === "ACCEPTED" ? '#0099C5' : rowData.status === "STARTED" ? '#ffd500c7' : rowData.status === "REACHED" ? '#79AA00' : rowData.status === "PAID" ? 'green' : rowData.status === "COMPLETE" ? 'green' : rowData.status === "CANCELLED" ? 'red' : null }}>{t(rowData.status)}</span> },
    { title: 'Fecha', field: 'fecha', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => rowData.fecha ? rowData.fecha : null },
    { title: t('pickup_address'), field: 'pickupAddress', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 15, color: 'black' }}>{rowData.pickupAddress}</span> },
    { title: t('drop_address'), field: 'dropAddress', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 15, color: 'black' }}>{rowData.dropAddress}</span> },
    { title: t('trip_cost'), field: 'trip_cost', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span>{rowData.trip_cost ? "$" + rowData.trip_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    // { title: 'Tiempo recogida', field: 'timeDelivery'  , render: rowData => <span>{rowData.timeDelivery ? rowData.timeDelivery + " Min" : ""}</span> },
    { title: 'Valor del pedido', field: 'valueDelivery', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Hora recogida', field: 'timeDelivery', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => rowData.timeDelivery ? moment(rowData.bookingDateUnix).add(rowData.timeDelivery, 'minutes').format('h:mm A ') : null },
    { title: t('assign_driver'), field: 'driver_name', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Tiempo recibido', field: 'accepted_time', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Tiempo recogido', field: 'trip_start_time', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Tiempo entregado', field: 'trip_end_time', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Tiempo cancelado', field: 'canceled_time', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Nombre del cliente', field: 'clientName', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Teléfono del cliente', field: 'clientPhone', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Dirección recogida', field: 'addressPickup', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Dirección entrega', field: 'addressDropp', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: t('distance'), field: 'distance', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span>{rowData.distance ? rowData.distance + " km" : ""}</span> },

    { title: t('trip_cost_driver_share'), field: 'driver_share', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span>{rowData.driver_share ? "$" + rowData.driver_share.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: t('convenience_fee'), field: 'convenience_fees', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span>{rowData.convenience_fees ? "$" + rowData.convenience_fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },

    { title: t('discount_ammount'), field: 'discount', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span>{rowData.discount ? "$" + rowData.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    // { title: t('deliveryPerson'), field: 'deliveryPerson', hidden: appcat === 'taxi'? true: false },
    { title: t('deliveryPersonPhone'), field: 'deliveryPersonPhone', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, hidden: appcat === 'taxi' ? true : false },
    { title: t('pickUpInstructions'), field: 'pickUpInstructions', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, hidden: appcat === 'taxi' ? true : false },
    // { title: t('deliveryInstructions'), field: 'deliveryInstructions', hidden: appcat === 'taxi'? true: false },
    { title: t('parcel_type'), render: rowData => <span>{rowData.parcelTypeSelected ? rowData.parcelTypeSelected.description + " (" + rowData.parcelTypeSelected.amount + ")" : ""}</span>, hidden: appcat === 'taxi' ? true : false },
    { title: t('parcel_option'), render: rowData => <span>{rowData.optionSelected ? rowData.optionSelected.description + " (" + rowData.optionSelected.amount + ")" : ""}</span>, hidden: appcat === 'taxi' ? true : false },

    // { title: t('take_pickup_image'),  field: 'pickup_image',render: rowData => rowData.pickup_image?<img alt='Pick Up' src={rowData.pickup_image} style={{width: 150}}/>:null, editable:'never', hidden: appcat === 'taxi'? true: false},
    // { title: t('take_deliver_image'),  field: 'deliver_image',render: rowData => rowData.deliver_image?<img alt='Deliver' src={rowData.deliver_image} style={{width: 150}}/>:null, editable:'never', hidden: appcat === 'taxi'? true: false},
    { title: t('cancellation_reason'), field: 'reason' },
    { title: t('otp'), field: 'otp' },


    { title: t('Customer_paid'), field: 'customer_paid', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span>{rowData.customer_paid ? "$" + rowData.customer_paid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: t('payment_mode'), field: 'payment_mode', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: t('payment_gateway'), field: 'gateway', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: t('cash_payment_amount'), field: 'cashPaymentAmount', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span>{rowData.cashPaymentAmount ? "$" + rowData.cashPaymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: t('card_payment_amount'), field: 'cardPaymentAmount', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span>{rowData.cardPaymentAmount ? "$" + rowData.cardPaymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: t('wallet_payment_amount'), field: 'usedWalletMoney', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span>{rowData.usedWalletMoney ? "$" + rowData.usedWalletMoney.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: 'key', field: 'id', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Fecha', field: 'fechaDelete', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => rowData.fechaDelete ? rowData.fechaDelete : null },
  ];

  const tableRef = useRef()
  const [play] = useSound(beep, { interrupt: true });

  useEffect(() => {
    if (bookinglistdata.bookings) {
      for (let i = 0; i < bookinglistdata.bookings.length; i++) {
        let order = bookinglistdata.bookings[i];
        // console.log("order ",order)
        if (order.status === 'NEW') {
          // console.log("Ok Audio")
          play();
        }
      }
    }
  }, [bookinglistdata.bookings, play]);

  useEffect(() => {
    if (bookinglistdata.bookings) {
      setData(bookinglistdata.bookings);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (data) {
      let databooking = [];
      for (let j = 0; j < data.length; j++) {
        const booking = data[j];
        if (booking) {
          data[j].fecha = moment(data[j].tripdate).format(" MMM DD , h:mm a");
          data[j].fechaDelete = "MES"+(moment(data[j].tripdate).format("MMMM").toUpperCase());
          databooking.push(booking)
        }
      }
      setDataOrdenada(databooking);
    }
  }, [data]);
  // console.log("dataOrdenada", dataOrdenada)

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);

  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === 'driver' && user.driverActiveStatus && user.approved && !user.queue) {
          arr.push({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'mobile': user.mobile,
            'email': user.email,
            'uid': user.id,
            'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
            'pushToken': user.pushToken
          });
        }
      }
      setUsers(arr);
    }
  }, [userdata.users, settings.AllowCriticalEditsAdmin]);

  const onDeleteOrder = (value) => {
    // console.log("onDeleteOrder value " ,value)
    if (value) {
      dispatch(deleteBooking({
        booking: selectedBooking,
      }));
    }
    setOpenConfirmDeleteOrder(false);
  }

  const assignDriver = () => {
    let booking = data[rowIndex];
    if (booking['driver_name']) {
      booking['driver_name'] = `${userCombo.firstName}${" "}${userCombo.lastName}`;
    } else {
      booking['driver_name'] = {};
      booking['driver_name'] = `${userCombo.firstName}${" "}${userCombo.lastName}`;
    }
    if (booking['requestedDrivers']) {
      booking['requestedDrivers'][userCombo.uid] = true;
    } else {
      booking['requestedDrivers'] = {};
      booking['requestedDrivers'][userCombo.uid] = true;
    }
    dispatch(updateBooking(booking));
    RequestPushMsg(userCombo.pushToken, t('notification_title'), t('new_booking_notification'));
    setUserCombo(null);
    handleClose();
    alert("Repartidor asignado correctamente.");
  }

  const onConfirmClose = (value) => {
    if (value) {
      //  console.log("value " ,value)
      dispatch(cancelBookingAdmin({
        status: value,
        reason: 'cambio de estado por el Admin',
        booking: selectedBooking,
        cancelledBy: role
      }));
    }
    setOpenConfirm(false);
  }

  const handleChange = (e) => {
    if (e.target.name === 'selectedProviderIndex') {
      setSelectedProviderIndex(parseInt(e.target.value));
      setSelectedProvider(providers[parseInt(e.target.value)]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handlePaymentModalClose = (e) => {
    setTimeout(() => {
      setPaymentModalStatus(false);
    }, 1500)
  }

  useEffect(() => {
    if (providers) {
      setSelectedProvider(providers[0]);
    }
  }, [providers]);

  const theme = useTheme()

  // const processPayment = (rowData) =>{
  //   const curBooking = rowData;
  //   const paymentPacket = { 
  //     appcat: appcat,
  //     payment_mode: 'card',
  //     customer_paid: (parseFloat(curBooking.trip_cost) - parseFloat(curBooking.discount)).toFixed(2),
  //     cardPaymentAmount:  curBooking.payableAmount?curBooking.payableAmount:curBooking.trip_cost,
  //     discount: curBooking.discount? curBooking.discount:0,
  //     usedWalletMoney: curBooking.usedWalletMoney?curBooking.usedWalletMoney:0,
  //     cashPaymentAmount: 0,
  //     promo_applied: curBooking.promo_applied?curBooking.promo_applied:false,
  //     promo_details: curBooking.promo_details?curBooking.promo_details:null,
  //     payableAmount: curBooking.payableAmount?curBooking.payableAmount:curBooking.trip_cost
  //   };
  //   curBooking.paymentPacket = paymentPacket;
  //   dispatch(updateBooking(curBooking));
  //   setSelectedBooking(curBooking);
  // }

  const handleCheckboxClick = (rowData) => {
    if (rowData) {
      // console.log("rowData ", rowData);
      setBookinngsDelete(rowData);
    } else {
      setBookinngsDelete([]);
    }
  };
  // console.log("bookinngsDelete ", bookinngsDelete)


  const handleDeleteBookings = () => {
    if (bookinngsDelete && bookinngsDelete.length > 0) {
      for (let i = 0; i < bookinngsDelete.length; i++) {
        dispatch(deleteBooking(bookinngsDelete[i].id));
        clearSelection();
      }
    }
    clearSelection();
  };

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false)
    }
  }

  const actionsDelete = [
    {
      icon: () =>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <DeleteIcon style={{ color: "000000" }} />
        </div>,
      tooltip: 'Borrar servicios',
      onClick: (event, rowData) => {
        handleDeleteBookings();
      },
      position: "toolbarOnSelect"
    }
  ];

  const actions = [
    {
      icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <DeleteIcon />
      </div>,
      disabled: role === 'adminPpal' ? false : true,
      onClick: (event, rowData) => {
        if (settings.AllowCriticalEditsAdmin) {
          if (role === 'adminPpal') {
            setSelectedBooking(rowData);
            setOpenConfirmDeleteOrder(true);
          } else {
            setTimeout(() => {
              console.log("rowData", rowData)
              dispatch(deleteBooking({
                booking: rowData
              }));
            }, 1500);
          }
        } else {
          alert(t('demo_mode'));
        }
      },
      position: "row"
    },
    {
      icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        {/* <CachedIcon /> */}
        <Typography variant="subtitle2">{'Estado'}</Typography>
      </div>,
      // disabled: rowData.status==='NEW' || rowData.status==='ACCEPTED' || rowData.status==='PAYMENT_PENDING'? false:true,
      // disabled: rowData.status !== 'COMPLETE' ? false : true,
      onClick: (event, rowData) => {
        if (settings.AllowCriticalEditsAdmin && (role === 'rider' || role === 'admin' || role === 'adminPpal')) {
          // if(rowData.status==='NEW' || rowData.status==='ACCEPTED'){
          if (rowData.status !== 'COMPLETE') {
            setSelectedBooking(rowData);
            setOpenConfirm(true);
          } else {
            setTimeout(() => {
              dispatch(cancelBookingAdmin({
                reason: t('cancelled_incomplete_booking'),
                booking: rowData
              }));
            }, 1500);
          }
        } else {
          alert(t('demo_mode'));
        }
      },
      position: "row"
    },
    {
      icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        {/* <PersonAddIcon /> */}
        <Typography variant="subtitle2">{'Asignar'}</Typography>
      </div>,
      // disabled: (rowData.status !== 'COMPLETE' && (role === 'admin' || role === 'adminPpal') && settings.autoDispatch === false) ? false : true,
      onClick: (event, rowData) => {
        setOpen(true)
        setRowIndex(rowData.tableData.id);
      },
      position: "row"
    }
  ];

  return (
    bookinglistdata.loading ? <CircularLoading /> :
      <div>
        <MaterialTable
          title={'Domicilios Generales'}
          columns={columns}
          tableRef={tableRef}
          onSelectionChange={(event) => {
            handleCheckboxClick(event);
            // let totalBookings = event.reduce((accum, item) => accum + item.trip_cost, 0)
            // setTotal(totalBookings);
            // console.log("event ", event)
            // console.log("total ",total)
          }}
          // actions={actions}
          actions={bookinngsDelete.length > 0 ? actionsDelete : actions}
          data={dataOrdenada}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} of {count}'
            },
            toolbar: {
              nRowsSelected: '{0} row(s) selected',
              searchPlaceholder: 'Buscar Domicilio'
            },
            header: {
              actions: 'Acciones'
            },
            body: {
              emptyDataSourceMessage: 'No records to display',
              filterRow: {
                filterTooltip: 'Filter'
              }
            }
          }}
          options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
            pageSize: 20,
            search: true,
            selection: true,
            showTextRowsSelected: false,
            exportAllData: true,
            searchFieldAlignment: "left",
            justifyContent: "center",
            headerStyle: {
              backgroundColor: theme.palette.action.hover,
              color: theme.palette.common.black,
              fontWeight: 'bold',
              textAlign: 'center',
              flexDirection: 'row',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}

        />
        {selectedBooking && selectedBooking.status === 'PENDING' && role === 'rider' ?
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={paymentModalStatus}
            onClose={handlePaymentModalClose}
            className={classes.modal}
            container={() => rootRef.current}
          >
            <Grid container spacing={2} className={classes.paper}>
              {providers && selectedProvider && selectedBooking ?
                <form action={selectedProvider.link} method="POST">
                  <input type='hidden' name='order_id' value={selectedBooking.id} />
                  <input type='hidden' name='amount' value={selectedBooking.paymentPacket.payableAmount} />
                  <input type='hidden' name='currency' value={settings.code} />
                  <input type='hidden' name='product_name' value={t('bookingPayment')} />
                  <input type='hidden' name='first_name' value={auth.info.profile.firstName} />
                  <input type='hidden' name='last_name' value={auth.info.profile.lastName} />
                  <input type='hidden' name='quantity' value={1} />
                  <input type='hidden' name='cust_id' value={selectedBooking.customer} />
                  <input type='hidden' name='mobile_no' value={selectedBooking.customer_contact} />
                  <input type='hidden' name='email' value={selectedBooking.customer_email} />
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{t('payment')}</FormLabel>
                      <RadioGroup name="selectedProviderIndex" value={selectedProviderIndex} onChange={handleChange}>
                        {providers.map((provider, index) =>
                          <FormControlLabel key={provider.name} value={index} control={<Radio />} label={<img style={{ height: 24, margin: 7 }} src={icons[provider.name]} alt={provider.name} />} />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button onClick={handlePaymentModalClose} variant="contained" color="primary">
                      {t('cancel')}
                    </Button>
                    <Button variant="contained" color="primary" type="submit" style={{ marginLeft: 10 }} onClick={handlePaymentModalClose}>
                      {t('paynow_button')}
                    </Button>
                  </Grid>
                </form>
                : null}
            </Grid>
          </Modal>
          : null}
        {/* <ConfirmationDialogRaw
      open={openConfirm}
      onClose={onConfirmClose}
      value={''}
    /> */}
        <DeleteOrder
          open={openConfirmDeleteOrder}
          onClose={onDeleteOrder}
          value={''}
        />
        <ConfirmmationStatusDomi
          open={openConfirm}
          onClose={onConfirmClose}
          value={''}
        />
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          onClose={handleClose}
          open={open}
          className={classes.modal}
          container={() => rootRef.current}
        >
          <div className={classes.paper}>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <Typography component="h1" variant="h5" className={classes.title}>
                  Seleccione repartidor
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {users ?
                  <UsersCombo
                    className={classes.items}
                    placeholder={t('select_user')}
                    users={users}
                    value={userCombo}
                    onChange={(event, newValue) => {
                      setUserCombo(newValue);
                    }}
                  />
                  : null}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button onClick={handleClose} variant="contained" color="primary">
                  Cancelar
                </Button>
                <Button onClick={assignDriver} variant="contained" color="primary" style={{ marginLeft: 10 }}>
                  Asignar
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </div>

  );
}

export default BookingHistory;
