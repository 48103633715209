import React, { useState, useEffect,
    //  useContext
     } from 'react';
import MaterialTable from 'material-table';
import { useSelector,
    //  useDispatch
     } from "react-redux";
import CircularLoading from "../components/CircularLoading";
// import { FirebaseContext } from 'common';
// import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import moment from 'moment'
import 'moment/min/locales'

export default function SmsUsers() {
    // const { api } = useContext(FirebaseContext);
    // const { t } = useTranslation();
    // const {
    //     sendSms,
    //     editSms,
    // } = api;
    // const settings = useSelector(state => state.settingsdata.settings);
    const columns = [
        { title: 'Fecha', field: 'distance', render: rowData => <span>{rowData.date ? moment(rowData.date).format(" MMM DD , h:mm a")  : ""}</span> },
        {
            title: 'Mensaje', field: 'message',
        },
        {
            title: 'Cantidad', field: 'amount'
        },
    ];

    const [data, setData] = useState([]);
    // const notificationdata = useSelector(state => state.notificationdata);
    const smsusersdata = useSelector(state => state.smsusersdata);
    // console.log("smsusersdata ", smsusersdata)
    // const counterdata = useSelector(state => state.counterdata);
    // console.log("counterdata ", counterdata)
    // const dispatch = useDispatch();
    const theme = useTheme()

    useEffect(() => {
        if (smsusersdata.notifications) {
            setData(smsusersdata.notifications.sort((a, b) => b.date - a.date));
        } else {
            setData([]);
        }
    }, [smsusersdata.notifications]);
    // console.log("data ",data)
    return (
        smsusersdata.loading ? <CircularLoading /> :
            <MaterialTable
                title={'Mensajes de texto Usuarios'}
                columns={columns}
                data={data}
                options={{
                    exportButton: true,
                    justifyContent: "center",
                    textAlign: 'center',
                    headerStyle: {
                        backgroundColor: theme.palette.action.hover,
                        color: theme.palette.common.black,
                        fontWeight: 'bold',
                        // textAlign: 'center',
                        flexDirection: 'row',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }
                }}
                // editable={{
                //     onRowAdd: newData =>
                //         new Promise(resolve => {
                //             setTimeout(() => {
                //                 resolve();
                //                 const tblData = data;
                //                 tblData.push(newData);
                //                 settings.AllowCriticalEditsAdmin ?
                //                     dispatch(sendSms(newData))
                //                     :
                //                     alert(t('demo_mode'));
                //                 dispatch(editSms(newData, "Add"));
                //             }, 600);
                //         }),

                //     onRowUpdate: (newData, oldData) =>
                //         new Promise(resolve => {
                //             setTimeout(() => {
                //                 resolve();
                //                 dispatch(editSms(newData, "Update"));
                //             }, 600);
                //         }),
                //     onRowDelete: oldData =>
                //         new Promise(resolve => {
                //             setTimeout(() => {
                //                 resolve();
                //                 dispatch(editSms(oldData, "Delete"));
                //             }, 600);
                //         }),
                // }}
            />
    );
}
