import React, {
  useState,
  useEffect,
  useContext,
  //   useRef
} from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem,
  Badge,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
// import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CarIcon from '@material-ui/icons/DirectionsCar';
import ListIcon from '@material-ui/icons/ListAlt';
import ExitIcon from '@material-ui/icons/ExitToApp';
import OfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import TocIcon from '@material-ui/icons/Toc';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
// import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { FirebaseContext } from 'common';
import { colors } from '../components/Theme/WebTheme';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from "react-i18next";

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import BusinessIcon from '@material-ui/icons/Business';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ImageIcon from '@material-ui/icons/Image';
import FilterIcon from '@material-ui/icons/Filter';
import CategoryIcon from '@material-ui/icons/Category';
import CloudIcon from '@material-ui/icons/Cloud';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import TextsmsIcon from '@material-ui/icons/Textsms';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';

import useSound from "use-sound";
import beep from "../assets/sounds/beep.mp3";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';



function AppMenu() {
  const { api } = useContext(FirebaseContext);
  const {
    signOut,
    updateSettings,
    // updateStateAdmin,
    updateState,
    updateStateLlevamos,
    updateStateNoLlevamos
  } = api;
  const { t } = useTranslation();
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    rootCard: {
      height: 80,
      marginBottom: 5
    },
    rootCard1: {
      height: 140,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    anchorTopRightOk: {
      top: 10,
      left: 150,
      backgroundColor: "green",
      color: "white"
    },
    anchorTopRightDomiciliosNo: {
      top: 10,
      left: 150,
      backgroundColor: "red",
      color: "white"
    },
  }));

  const classes = useStyles();

  const [play] = useSound(beep, { interrupt: true });

  const [menuActive, setMenuActive] = useState([false, false, false, false]);

  const handleClick = (index) => {
    let temp = menuActive;
    temp[index] = !menuActive[index];
    setMenuActive(temp);
  };
  const list = {
    paddingLeft: 0,
  }
  const arrow = {
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0
  };

  const settings = useSelector(state => state.settingsdata.settings);
  // console.log("settings ", settings)
  const settingsdata = useSelector(state => state.settingsdata);

  const usersappdata = useSelector(state => state.usersappdata);
  // console.log("usersappdata ", usersappdata)

  const [uid, setUid] = useState(null);

  const [riderApp, setRiderApp] = useState([]);

  const lluviadata = useSelector(state => state.lluviadata);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (lluviadata.simple) {
      setData(lluviadata.simple[0]);
    } else {
      setData([]);
    }
  }, [lluviadata.simple]);

  const [dataBooking, setDataBooking] = useState([]);
  // const [dataBookingActive, setDataBookingActive] = useState([]);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  useEffect(() => {
    if (bookinglistdata.bookings) {
      setDataBooking((bookinglistdata.bookings).filter(booking => booking.status === 'NEW'));
      // setDataBookingActive((bookinglistdata.bookings).filter(booking => booking.status === 'STARTED' || booking.status === 'ACCEPTED' || booking.status === 'ARRIVED'));
    } else {
      setDataBooking([]);
    }
  }, [bookinglistdata.bookings]);
  // console.log("dataBookingActive", dataBookingActive)
  // console.log("dataBooking" , dataBooking)
  // console.log("dataBooking.length", dataBooking.length)

  const [dataOrder, setDataOrder] = useState([]);
  // const [dataOrderActive, setDataOrderActive] = useState([]);
  const orderslistdata = useSelector(state => state.orderslistdata);
  useEffect(() => {
    if (orderslistdata.bookings) {
      setDataOrder((orderslistdata.bookings).filter(booking => (booking.status === 'Pendiente' || booking.status === 'Confirmado')));
      // setDataOrderActive((orderslistdata.bookings).filter(booking => (booking.status === 'Recibido' || booking.status === 'Recogido') && ((booking.driver && booking.driver !== "SIN REPARTIDOR"))));
    } else {
      setDataOrder([]);
    }
  }, [orderslistdata.bookings]);
  // console.log("dataOrderActive", dataOrderActive)
  // console.log("dataOrder", dataOrder)
  // console.log("dataOrder.length", dataOrder.length)

  const [state, setState] = useState();
  const [dataLlevamos, setDataLlevamos] = useState([]);
  const [dataLlevamosActivos, setDataLlevamosActivos] = useState([]);
  // const [dataLlevamosInactivos, setDataLlevamosInactivos] = useState([]);

  const [dataNoLlevamos, setDataNoLlevamos] = useState([]);
  const [dataNoLlevamosActivos, setDataNoLlevamosActivos] = useState([]);

  const [dataTodos, setDataTodos] = useState([]);
  const [dataTodosActivos, setDataTodosActivos] = useState([]);
  // const [dataTodosInactivos, setDataTodosInactivos] = useState([]);

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  //   console.log("state ",state);
  //   dispatch(updateSettings(state));
  // };

  const [dataTodosEstado, setDataTodosEstado] = useState(false);
  const [dataLlevamosEstado, setDataLlevamosEstado] = useState(false);
  const [dataNoLlevamosEstado, setDataNoLlevamosEstado] = useState(false);


  useEffect(() => {
    if (usersappdata.users) {
      setDataLlevamos(usersappdata.users.filter(user => user.city === 'CARTAGO' && user.llevamos === true));
    } else {
      setDataLlevamos([]);
    }
  }, [usersappdata.users]);
  // console.log("dataLlevamos", dataLlevamos)

  useEffect(() => {
    if (usersappdata.users) {
      setDataNoLlevamos(usersappdata.users.filter(user => user.city === 'CARTAGO' && user.nollevamos === true));
    } else {
      setDataNoLlevamos([]);
    }
  }, [usersappdata.users]);
  // console.log("dataNoLlevamos", dataNoLlevamos)

  useEffect(() => {
    if (usersappdata.users) {
      setDataTodos(usersappdata.users.filter(user => user.city === 'CARTAGO'));
    } else {
      setDataTodos([]);
    }
  }, [usersappdata.users]);
  // console.log("dataTodos", dataTodos)

  useEffect(() => {
    if (dataTodosActivos.length > 0) {
      setDataTodosEstado(true)
    } else {
      setDataTodosEstado(false)
    }
  }, [dataTodosActivos]);
  // console.log("dataTodosEstado", dataTodosEstado)

  useEffect(() => {
    if (dataLlevamosActivos.length > 0) {
      setDataLlevamosEstado(true)
    } else {
      setDataLlevamosEstado(false)
    }
  }, [dataLlevamosActivos]);
  // console.log("dataLlevamosEstado", dataLlevamosEstado)

  useEffect(() => {
    if (dataNoLlevamosActivos.length > 0) {
      setDataNoLlevamosEstado(true)
    } else {
      setDataNoLlevamosEstado(false)
    }
  }, [dataNoLlevamosActivos]);
  // console.log("dataLlevamosEstado", dataLlevamosEstado)

  useEffect(() => {
    setDataLlevamosActivos(dataLlevamos.filter(user => user.estado === true));
    // setDataLlevamosInactivos(dataLlevamos.filter(user => user.estado === false));
  }, [dataLlevamos]);
  // console.log("dataLlevamosActivos", dataLlevamosActivos)
  // console.log("dataLlevamosInactivos", dataLlevamosInactivos)

  useEffect(() => {
    setDataNoLlevamosActivos(dataNoLlevamos.filter(user => user.estado === true));
    // setDataLlevamosInactivos(dataLlevamos.filter(user => user.estado === false));
  }, [dataNoLlevamos]);
  // console.log("dataLlevamosActivos", dataLlevamosActivos)
  // console.log("dataLlevamosInactivos", dataLlevamosInactivos)

  useEffect(() => {
    setDataTodosActivos(dataTodos.filter(user => user.estado === true));
    // setDataTodosInactivos(dataTodos.filter(user => user.estado === false));
  }, [dataTodos]);
  // console.log("dataTodosActivos", dataTodosActivos)
  // console.log("dataTodosInactivos", dataTodosInactivos)


  const handleChange = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    // console.log("changeData " ,changeData)
    if (changeData) {
      dispatch(updateSettings(changeData));
    }
  };

  const handleChangeLlevamos = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    // console.log("changeData ", changeData)
    if (changeData && dataLlevamosActivos.length > 0) {
      dispatch(updateStateLlevamos(dataLlevamosActivos));
    }
  };

  const handleChangeNoLlevamos = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    // console.log("changeData ", changeData)
    if (changeData && dataNoLlevamosActivos.length > 0) {
      dispatch(updateStateNoLlevamos(dataNoLlevamosActivos));
    }
  };

  const handleChangeTodos = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    // console.log("changeData ", changeData)
    if (changeData && dataTodosActivos.length > 0) {
      dispatch(updateStateLlevamos(dataTodosActivos));
    }
  };

  const handleOpen = (oldRow, e) => {
    const openData = { [e.target.name]: e.target.checked };
    // console.log("openData " ,openData)
    if (openData) {
      // dispatch(updateStateAdmin(openData));
      dispatch(updateState({
        estado: openData.estado,
        booking: uid,
      }));
    }
  };

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setUid(auth.info.uid);
    }
  }, [auth.info]);
  // console.log("uid ", uid)

  useEffect(() => {
    if (usersappdata && usersappdata.users) {
      setRiderApp(usersappdata.users.filter(users => users.storeId === uid)[0]);
    }
  }, [usersappdata, usersappdata.users, uid]);
  // console.log("riderApp ", riderApp);

  useEffect(() => {
    if (settingsdata && settingsdata.settings) {
      setState(settingsdata.settings);
    }
  }, [settingsdata, settingsdata.settings]);
  // console.log("state ", state);

  useEffect(() => {
    if ((dataOrder.length > 0 || dataBooking.length > 0) && settings.ActivarSonido) {
      play();
    }
  }, [dataOrder, dataOrder.length, dataBooking, dataBooking.length, play, settings.ActivarSonido]);

  let isAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'admin';
  let isAdminPpal = auth.info && auth.info.profile && auth.info.profile.usertype === 'adminPpal';
  let isRider = auth.info && auth.info.profile && auth.info.profile.usertype === 'rider';
  // console.log("isAdmin",isAdmin)
  let isFleetAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'fleetadmin';
  return (

    <div>
      <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: colors.HeaderPersonal }}>
        <img style={{ marginTop: '5px', marginBottom: '8px', width: '135px', height: '108px' }} src={require("../assets/img/logo.png").default} alt="Logo" />
      </div>

      {state && (isAdmin || isAdminPpal) ?
        <Card className={classes.rootCard}>
          <CardContent>
            <Typography style={{ fontSize: 12 }} color="textSecondary" >
              DOMICILIOS
            </Typography>
            <div style={{ justifyContent: 'cerightnter', paddingLeft: 20 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.ActivarServicio}
                    onChange={e => handleChange(data, e)}
                    name="ActivarServicio"
                    color="primary"
                  />
                }
                label={<span style={{ fontWeight: 'bold', fontSize: 12, color: state.ActivarServicio === true ? 'green' : state.ActivarServicio === false ? 'red' : null }}>{state.ActivarServicio === true ? ' ABIERTO' : ' CERRADO'}</span>}
              />
            </div>
          </CardContent>
        </Card>
        : null}

      {state && (isAdmin || isAdminPpal) ?
        <Card className={classes.rootCard}>
          <CardContent>

            <div style={{ justifyContent: 'right', paddingLeft: 20 }}>
              <Typography style={{ fontSize: 11 }} color="textSecondary" >
                TODOS
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    disabled={dataTodosActivos.length > 0 ? false : true}
                    checked={dataTodosEstado}
                    onChange={e => handleChangeTodos(data, e)}
                    name="ActivarServicio"
                    color="primary"
                  />
                }
                label={<span style={{ fontWeight: 'bold', fontSize: 12, color: dataTodosEstado === true ? 'green' : dataTodosEstado === false ? 'red' : null }}>{dataTodosEstado === true ? ` ABIERTOS ${dataTodosActivos.length}` : 'CERRADOS'}</span>}
              />
            </div>

          </CardContent>
        </Card>
        : null}

      {state && (isAdmin || isAdminPpal) ?
        <Card className={classes.rootCard1}>
          <CardContent>

            <div style={{ justifyContent: 'right', paddingLeft: 20 }}>
              <Typography style={{ fontSize: 11 }} color="textSecondary" >
                LLEVAMOS TODOS
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    disabled={dataLlevamosActivos.length > 0 ? false : true}
                    checked={dataLlevamosEstado}
                    onChange={e => handleChangeLlevamos(data, e)}
                    name="ActivarServicioLLevamos"
                    color="primary"
                  />
                }
                label={<span style={{ fontWeight: 'bold', fontSize: 12, color: dataLlevamosEstado === true ? 'green' : dataLlevamosEstado === false ? 'red' : null }}>{dataLlevamosEstado === true ? ` ABIERTOS ${dataLlevamosActivos.length}` : 'CERRADOS'}</span>}
              />
            </div>

            <div style={{ justifyContent: 'right', paddingLeft: 20 }}>
              <Typography style={{ fontSize: 11 }} color="textSecondary" >
              LLEVAMOS SIN DOMI
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    disabled={dataNoLlevamosActivos.length > 0 ? false : true}
                    checked={dataNoLlevamosEstado}
                    onChange={e => handleChangeNoLlevamos(data, e)}
                    name="ActivarServicio"
                    color="primary"
                  />
                }
                label={<span style={{ fontWeight: 'bold', fontSize: 12, color: dataNoLlevamosEstado === true ? 'green' : dataNoLlevamosEstado === false ? 'red' : null }}>{dataNoLlevamosEstado === true ? ` ABIERTOS ${dataNoLlevamosActivos.length}` : 'CERRADOS'}</span>}
              />
            </div>

          </CardContent>
        </Card>
        : null}

      {riderApp && isRider ?
        <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: 20 }}>
          <FormControlLabel
            control={
              <Switch
                checked={riderApp.estado}
                onChange={e => handleOpen(data, e)}
                name="estado"
                color="primary"
              />
            }
            label={<span style={{ fontWeight: 'bold', fontSize: 12, color: riderApp.estado === true ? 'green' : riderApp.estado === false ? 'red' : null }}>{riderApp.estado === true ? 'SERVICIO ABIERTO' : 'SERVICIO CERRADO'}</span>}
          />
        </div>
        : null}

      {/* <div style={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: colors.HeaderPersonal, paddingLeft: 15, fontWeight: 'bold', fontSize: 16, marginTop: 5 }}>
        <strong style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', }}>PEDIDOS</strong>
        <strong style={{ display: 'flex', paddingRight: 15, color: dataOrder.length > 0 ? 'green' : dataOrder.length === 0 ? 'red' : null }}>{dataOrder.length}</strong>
      </div> */}
      {/* <div style={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: colors.HeaderPersonal, paddingLeft: 15, fontWeight: 'bold', fontSize: 16 }}>
        <strong style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', }}>DOMICILIOS</strong>
        <strong style={{ display: 'flex', paddingRight: 15, color: dataBooking.length > 0 ? 'green' : dataBooking.length === 0 ? 'red' : null }}>{dataBooking.length}</strong>
      </div> */}
      {/* <div style={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: colors.HeaderPersonal, paddingLeft: 15, fontWeight: 'bold', fontSize: 14, marginTop: 5 }}>
        <strong style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', }}>SERVICIO</strong>
        <strong style={{ display: 'flex', paddingRight: 15, color: settings.ActivarServicio === true ? 'green' : settings.ActivarServicio === false ? 'red' : null }}>{settings && settings.ActivarServicio === true ? 'ABIERTO' : 'CERRADO'}</strong>
      </div> */}
      {isAdmin || isAdminPpal ?
        <div style={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: colors.HeaderPersonal, paddingLeft: 15, fontWeight: 'bold', fontSize: 14, marginTop: 5 }}>
          <strong style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', }}>LIMITE</strong>
          <strong style={{ display: 'flex', paddingRight: 15, color: 'green' }}>{settings && settings.quantity ? settings.quantity : null}</strong>
        </div>
        : null}
      {isAdmin || isAdminPpal ?
        <div style={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: colors.HeaderPersonal, paddingLeft: 15, fontWeight: 'bold', fontSize: 14, marginTop: 5 }}>
          <strong style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', }}>SONIDO</strong>
          <strong style={{ display: 'flex', paddingRight: 15, color: settings.ActivarSonido === true ? 'green' : settings.ActivarSonido === false ? 'red' : null }}>{settings && settings.ActivarSonido === true ? 'ACTIVO' : 'INACTIVO'}</strong>
        </div>
        : null}
      {isAdmin || isAdminPpal ?
        <div style={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: colors.HeaderPersonal, paddingLeft: 15, fontWeight: 'bold', fontSize: 14, marginTop: 5 }}>
          <strong style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', }}>LLUVIA</strong>
          <strong style={{ display: 'flex', paddingRight: 15, color: data && data.lluvia > 0 ? 'green' : data && data.lluvia === 0 ? 'red' : null }}>{data && data.lluvia > 0 ? 'ACTIVO' : ' INACTIVO'}{"  "}{data && data.lluvia > 0 ? data && data.lluvia + "%" : ''}</strong>
        </div>
        : null}
      <Divider />
      <MenuList>

        <MenuItem component={Link} to="/orders">
          <Badge
            classes={{ anchorOriginTopRightRectangle: dataOrder.length > 0 ? classes.anchorTopRightOk : classes.anchorTopRightDomiciliosNo }}
            badgeContent={`${dataOrder.length}`} color="secondary">
            <ListItemIcon style={list}>
              <ListIcon />
            </ListItemIcon>
          </Badge>
          <Typography variant="inherit">{"Pedidos"}</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/bookings">
          <Badge
            classes={{ anchorOriginTopRightRectangle: dataBooking.length > 0 ? classes.anchorTopRightOk : classes.anchorTopRightDomiciliosNo }}
            badgeContent={`${dataBooking.length}`}>
            <ListItemIcon style={list}>
              <ListIcon />
            </ListItemIcon>
          </Badge>
          <Typography variant="inherit">{"Domicilios"}</Typography>
        </MenuItem>

        {isRider ?
          <MenuItem component={Link} to="/">
            <ListItemIcon>
              <TocIcon />
            </ListItemIcon>
            <Typography variant="inherit">{'Nuevo domicilio'}</Typography>
          </MenuItem>
          : null}

        {isAdmin || isAdminPpal ?
          <MenuItem component={Link} to="/">
            <ListItemIcon>
              <TocIcon />
            </ListItemIcon>
            <Typography variant="inherit">{'Inicio'}</Typography>
          </MenuItem>
          : null}

        {isAdmin || isAdminPpal ?
          <MenuItem component={Link} to="/addbookings">
            <ListItemIcon>
              <TocIcon />
            </ListItemIcon>
            <Typography variant="inherit">{'Admin'}</Typography>
          </MenuItem>
          : null}

        {isFleetAdmin ?
          <MenuItem component={Link} to="/drivers">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('drivers')}</Typography>
          </MenuItem>
          : null}

        {isAdmin || isAdminPpal ?
          <MenuItem button onClick={() => handleClick(1)} component={Link} to="/usersApp">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Typography>{t('users_title')}</Typography>
            {menuActive[1] ?
              <ListItemIcon style={arrow}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrow}>
                <KeyboardArrowLeftIcon />
              </ListItemIcon>
            }
          </MenuItem>
          : null}

        <Collapse in={menuActive[1]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/usersApp">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Establecimientos App Cartago'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/riders">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Establecimientos Domi Cartago'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isFleetAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/usersAppDelivery">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Usuarios App Cartago'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isFleetAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/drivers">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Repartidores'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/fleetadmins">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('fleetadmins')}</Typography>
              </ListItem>
              : null}

          </List>
        </Collapse>



        {isAdmin || isAdminPpal ?
          <MenuItem button onClick={() => handleClick(2)} component={Link} to="/sector">
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <Typography>{'Config Domi'}</Typography>
            {menuActive[2] ?
              <ListItemIcon style={arrow}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrow}>
                <KeyboardArrowLeftIcon />
              </ListItemIcon>
            }
          </MenuItem>
          : null}

        <Collapse in={menuActive[2]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/sector">
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Sector pedidos'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/cartypes">
                <ListItemIcon>
                  <CarIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('car_type')}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/cancelreasons">
                <ListItemIcon>
                  <CarIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Razón a cancelar'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/ordervalue">
                <ListItemIcon>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Valor de pedidos'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/time">
                <ListItemIcon>
                  <AccessAlarmIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Tiempo recogida'}</Typography>
              </ListItem>
              : null}

          </List>
        </Collapse>

        {isAdmin || isAdminPpal ?
          <MenuItem button onClick={() => handleClick(3)} component={Link} to="/wp">
            <ListItemIcon>
              <SettingsApplicationsIcon />
            </ListItemIcon>
            <Typography>{'Config App'}</Typography>
            {menuActive[3] ?
              <ListItemIcon style={arrow}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrow}>
                <KeyboardArrowLeftIcon />
              </ListItemIcon>
            }
          </MenuItem>
          : null}

        <Collapse in={menuActive[3]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/lluvia">
                <ListItemIcon>
                  <CloudIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Lluvia'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/wp">
                <ListItemIcon>
                  <WhatsAppIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Whatsapp'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/bannerppal">
                <ListItemIcon>
                  <ImageIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Banner Ppal'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/supercategorias">
                <ListItemIcon>
                  <CategoryIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Super categorias'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/filtros">
                <ListItemIcon>
                  <FilterIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Filtros'}</Typography>
              </ListItem>
              : null}

          </List>
        </Collapse>


        {isFleetAdmin ?
          <MenuItem component={Link} to="/driverearning">
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{'earning_reports'}</Typography>
          </MenuItem>
          : null}

        {isAdmin || isAdminPpal ?
          <MenuItem button onClick={() => handleClick(4)} component={Link} to="/addtowallet">
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <Typography>{'Billeteras'}</Typography>
            {menuActive[4] ?
              <ListItemIcon style={arrow}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrow}>
                <KeyboardArrowLeftIcon />
              </ListItemIcon>
            }
          </MenuItem>
          : null}

        <Collapse in={menuActive[4]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            {/* {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/earningreports">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Ganancias x Domicilios'}</Typography>
              </ListItem>
              : null} */}

            {/* {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/earningreportsorder">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Ganancias x Pedidos'}</Typography>
              </ListItem>
              : null} */}

            {/* {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/driverearning">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Ganancias x Domicilios'}</Typography>
              </ListItem>
              : null} */}

            {/* {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/driverearningorder">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Ganancias x Pedidos'}</Typography>
              </ListItem>
              : null} */}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/addtowallet">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Agregar a Billetera'}</Typography>
              </ListItem>
              : null}

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/withdraws">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Retirar a Billetera'}</Typography>
              </ListItem>
              : null}

          </List>
        </Collapse>

        {isAdmin || isAdminPpal ?
          <MenuItem button onClick={() => handleClick(5)} component={Link} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <Typography>{'Mapas'}</Typography>
            {menuActive[4] ?
              <ListItemIcon style={arrow}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrow}>
                <KeyboardArrowLeftIcon />
              </ListItemIcon>
            }
          </MenuItem>
          : null}

        <Collapse in={menuActive[5]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            {isAdmin || isFleetAdmin || isAdminPpal ?
              <MenuItem component={Link} to="/dashboard">
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Mapa Domicilios'}</Typography>
              </MenuItem>
              : null}

            {isAdmin || isFleetAdmin || isAdminPpal ?
              <MenuItem component={Link} to="/DashboardSector">
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Mapa Barrios'}</Typography>
              </MenuItem>
              : null}

          </List>
        </Collapse>

        {isAdminPpal ?
          <MenuItem button onClick={() => handleClick(6)} component={Link} to="/bookingsPayment">
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <Typography>{'Estadisticas'}</Typography>
            {menuActive[6] ?
              <ListItemIcon style={arrow}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrow}>
                <KeyboardArrowLeftIcon />
              </ListItemIcon>
            }
          </MenuItem>
          : null}

        <Collapse in={menuActive[6]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            {isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/bookingsPayment">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Lista de cobros'}</Typography>
              </ListItem>
              : null}

            {isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/earningreports">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Total x Domicilios'}</Typography>
              </ListItem>
              : null}

            {isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/earningreportsorder">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Total x Pedidos'}</Typography>
              </ListItem>
              : null}

            {isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/driverearning">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Ganancias x Domicilios'}</Typography>
              </ListItem>
              : null}

            {isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/driverearningorder">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Ganancias x Pedidos'}</Typography>
              </ListItem>
              : null}
            {/* 
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/addtowallet">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Agregar a Billetera'}</Typography>
              </ListItem>
              : null} */}

            {/* {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/withdraws">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Retirar a Billetera'}</Typography>
              </ListItem>
              : null} */}

          </List>
        </Collapse>

        {isAdminPpal || isAdmin ?
          <MenuItem button onClick={() => handleClick(7)} component={Link} to="/smsusers">
            <ListItemIcon>
              <TextsmsIcon />
            </ListItemIcon>
            <Typography>{'Mensajes'}</Typography>
            {menuActive[7] ?
              <ListItemIcon style={arrow}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrow}>
                <KeyboardArrowLeftIcon />
              </ListItemIcon>
            }
          </MenuItem>
          : null}

        <Collapse in={menuActive[7]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            {isAdminPpal || isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/smsusers">
                <ListItemIcon>
                  <TextsmsIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Enviados Usuarios'}</Typography>
              </ListItem>
              : null}

            {isAdminPpal || isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/sms">
                <ListItemIcon>
                  <TextsmsIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Enviados Establecimientos'}</Typography>
              </ListItem>
              : null}

            {isAdminPpal || isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/template">
                <ListItemIcon>
                  <SmsFailedIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Plantillas Usuarios'}</Typography>
              </ListItem>
              : null}

            {isAdminPpal || isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/counter">
                <ListItemIcon>
                  <SmsFailedIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Saldo Sms'}</Typography>
              </ListItem>
              : null}

          </List>
        </Collapse>

        {isAdminPpal || isAdmin ?
          <MenuItem button onClick={() => handleClick(8)} component={Link} to="/UsersAppOneSignal">
            <ListItemIcon>
              <NotifyIcon />
            </ListItemIcon>
            <Typography>{'OneSignal'}</Typography>
            {menuActive[8] ?
              <ListItemIcon style={arrow}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrow}>
                <KeyboardArrowLeftIcon />
              </ListItemIcon>
            }
          </MenuItem>
          : null}

        <Collapse in={menuActive[8]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            {isAdmin || isAdminPpal ?
              <ListItem button className={classes.nested} component={Link} to="/UsersAppOneSignal">
                <ListItemIcon>
                  <NotifyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Usuarios OneSignal'}</Typography>
              </ListItem>
              : null}

            {isAdminPpal || isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/onesignalusers">
                <ListItemIcon>
                  <NotifyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Enviados OneSignal'}</Typography>
              </ListItem>
              : null}



            {isAdminPpal || isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/templateonesignal">
                <ListItemIcon>
                  <NotifyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{'Plantillas OneSignal'}</Typography>
              </ListItem>
              : null}


          </List>
        </Collapse>

        {isAdmin || isAdminPpal ?
          <MenuItem component={Link} to="/promos">
            <ListItemIcon>
              <OfferIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('promo')}</Typography>
          </MenuItem>
          : null}
        {isAdmin || isAdminPpal ?
          <MenuItem component={Link} to="/bonos">
            <ListItemIcon>
              <OfferIcon />
            </ListItemIcon>
            <Typography variant="inherit">{'Bonos'}</Typography>
          </MenuItem>
          : null}
        {isAdmin || isAdminPpal ?
          <MenuItem component={Link} to="/notifications">
            <ListItemIcon>
              <NotifyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{'Notificaciones'}</Typography>
          </MenuItem>
          : null}

        {/* {isAdmin || isAdminPpal ?
          <MenuItem component={Link} to="/sms">
            <ListItemIcon>
              <NotifyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{'Mensajes'}</Typography>
          </MenuItem>
          : null} */}

        {isAdmin || isAdminPpal ?
          <MenuItem component={Link} to="/settings">
            <ListItemIcon>
              <LocalAtmIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('settings_title')}</Typography>
          </MenuItem>
          : null}

        {/* <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('profile')}</Typography>
        </MenuItem> */}

        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('logout')}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;