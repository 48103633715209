import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
// import { ContactsOutlined } from '@material-ui/icons';
export default function DriverEarning() {
  const { t } = useTranslation();
    const columns =  [
        { title: t('year'),field: 'year'},
        { title: t('months'), field: 'monthsName' },
        { title: t('driver_name'), field: 'driverName'},
        { title: t('vehicle_reg_no'), field: 'driverVehicleNo' },
        { title: 'Cantidad', field: 'count' , render: rowData => (rowData.count.length) },
        { title: t('earning_amount'), field: 'driverShare' , render: rowData => "$" + (rowData.driverShare).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') },
        
    ];

  const [data, setData] = useState([]);
  const driverearningdata = useSelector(state => state.driverearningdata);
  // console.log("driverearningdata ",driverearningorderdata)

  useEffect(()=>{
        if(driverearningdata.driverearnings){
            setData(driverearningdata.driverearnings);
        }
  },[driverearningdata.driverearnings]);

  return (
    driverearningdata.loading? <CircularLoading/>:
    <MaterialTable
    title={'Ganancias x Domicilios'}
      columns={columns}
      data={data}
      
      options={{
        exportButton: true,
        grouping: true,
      }}
      
    />
  );
}
