import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import {
    useSelector,
} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import Typography from '@material-ui/core/Typography';
// import DashboardCard from '../components/DashboardCard';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

import {
    Grid,
    // Paper,
    // Typography
} from '@material-ui/core';



export default function BookingHistoryPayment() {

    const [data, setData] = useState(0);
    // const [dataTotalPedidos, setDataTotalPedidos] = useState(0);


    const counterdata = useSelector(state => state.counterdata);
    // console.log("counterdata ", counterdata)

    const loaded = useRef(false);
    // const classes = useStyles();

    useEffect(() => {
        if (counterdata.notifications) {
            setData(counterdata.notifications);
        } else {
            setData([]);
        }
        loaded.current = true;
    }, [counterdata.notifications]);
    // console.log("data", data)




    return (
        !loaded.current ? <CircularLoading /> :
            <div>

                {/* <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center', justify: 'center' }}>
                    <Grid xs={12} style={{ textAlign: 'center', justify: 'center' }}>
                        <DashboardCard title={`SALDO MENSAJES:`} image={require("../assets/img/money1.jpg").default}>
                            <Typography variant="h6">
                                {`${+ ' ' + data.counter}`}
                            </Typography>
                        </DashboardCard>
                    </Grid>
                </Grid> */}

                <Grid
                    container
                    spacing={24}
                    justify="center"
                    style={{ minHeight: '100vh', maxWidth: '100%' }}
                >
                    <Grid item xs={12} align="center">
                        <Card  variant="outlined">
                            <CardContent  >
                                    <Typography variant="h5">
                                        {`SALDO SMS `}
                                    </Typography>
                                    <Typography variant="h3">
                                        {` ${+ ' ' + data.counter}`}
                                    </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


            </div>
    );
}
