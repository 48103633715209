import React, {
  //  useState,
  // useEffect,
  //  useContext,
  useRef
}
  from 'react';

import moment from 'moment'
import 'moment/min/locales'
import { useLocation } from "react-router-dom";
// import { Col, Divider, Row, Table } from 'antd';
// import 'antd/dist/antd.css';
import logo from "../assets/img/logo.png";
import "./styles.css";

import {
  exportComponentAsJPEG,
  // exportComponentAsPDF,
  //  exportComponentAsPNG 
} from 'react-component-export-image';
import { useHistory } from "react-router-dom";



const Invoice = () => {

  const componentRef = useRef();
  const location = useLocation();
  const history = useHistory();

  const fileType = {
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    PDF: 'application/pdf'
  };


  const orderDetails = location.state;
  // console.log("orderDetails ", orderDetails);

  // const userDetails = location.state.userDetails;

  const address = location.state.shippingAddress;


  const DEFAULT_JPEG = {
    fileName: `${orderDetails.restaurantName}${" #" + orderDetails.orderId}.jpg`,
    type: fileType.JPEG
    // html2CanvasOptions: {}
  };

  const handleClick = (e) => {
    let num = e;
    // e.preventDefault();
    console.log(num);
    console.log('The link was clicked.');
    window.open(`https://wa.me/+57${address.mobileNo}?`, '_blank');
  }

  const ComponentToPrint = React.forwardRef((props, ref) => (


    <div ref={ref} style={{ maxWidth: "1000px" }} >

      {/* <div style={{ display: "flex" , justifyContent: "right" }}>
        <button onClick={() => history.goBack()}  >
          <h6>
            <strong style={{ fontSize: "12px", color: "#000000" }}>Atras</strong>
          </h6>
        </button>
      </div> */}
      {/* <div> */}
      {/* <ReactToPdf targetRef={ref} filename="div-blue.pdf">
        {({ toPdf }) => <button onClick={toPdf}>Generate pdf</button>}
      </ReactToPdf> */}
      {/* <Invoice ref={ref} /> */}

      <div>
        <div className="container invoice">
          {/* <button onClick={() => history.goBack()}>Atras</button> */}
          {/* <br /> */}
          {/* <div className="divstyle1">
            <div className="imgContain">
              <img src={logo} width="100" alt="" />
            </div>
            <div className="divstyle2">
            <img src={logo} width="100" alt="" />
              <h4>LLEVO A CASA APP</h4>
              <h5>ventas.llevoacasa@gmail.com</h5>
              <h5>www.llevoacasa.com</h5>
              <h5>+57 320 2210492</h5>
            </div>
          </div> */}

          <div className="divstyle3">
            <div>
              <h1 style={{ fontSize: "22px" }}> &nbsp;&nbsp;&nbsp; Pedido #
                <strong>{orderDetails.orderId}</strong>
              </h1>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ paddingLeft: "25px" }} colSpan={6}>
                        {/* <strong style={{ color: "#000000" }}>Cliente</strong> */}
                        <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                          Nombre: &nbsp; <strong style={{ fontSize: "13px", color: "#000000" }}>{address.name}</strong>
                        </h6>
                        <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                          Dirección: &nbsp;<strong style={{ fontSize: "13px", color: "#000000" }}>{address.address}</strong>
                        </h6>
                        <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                          Barrio: &nbsp;<strong style={{ fontSize: "13px", color: "#000000" }}>{address.area}</strong>
                        </h6>
                        <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                          Ciudad: &nbsp;<strong style={{ fontSize: "13px", color: "#000000" }}>{address.city}</strong>
                        </h6>
                        <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                          Teléfono: &nbsp;<strong style={{ fontSize: "13px", color: "#000000" }}>
                            {/* <a href="https://api.whatsapp.com/send?phone=1111111111"> {address.mobileNo}</a> */}
                            {/* <a href='https://api.whatsapp.com/send?phone=" + address.mobileNo + "'></a> */}
                            <a href="/#" onClick={handleClick}>
                              {address.mobileNo}
                            </a>
                            {/* {address.mobileNo} */}
                          </strong>
                        </h6>
                      </th>
                      <th style={{ paddingLeft: "100px" }} colSpan={6}>
                        {/* <strong>Establecimiento</strong> */}
                        <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                          Establecimiento: &nbsp;<strong style={{ fontSize: "13px", color: "#000000" }}>{orderDetails.restaurantName}</strong>
                        </h6>
                        <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                          Estado: &nbsp;<strong style={{ fontSize: "12px", color: "#000000" }}>{orderDetails.status}</strong>
                        </h6>
                        <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                          Fecha: &nbsp;<strong style={{ fontSize: "13px", color: "#000000" }}>{moment(orderDetails.createdAt).format(" MMM DD, h:mm a")}</strong>
                        </h6>

                        {orderDetails.paymentTypeTodo === 'DATAFONO' ?
                          <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                            Método de pago: &nbsp;<strong style={{ fontSize: "13px", color: "#ca0505" }}>{orderDetails.paymentTypeTodo}</strong>
                          </h6>
                          : null}

                        {orderDetails.paymentType === 'DATAFONO' ?
                          <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                            Método de pago: &nbsp;<strong style={{ fontSize: "13px", color: "#ca0505" }}>{orderDetails.paymentType}</strong>
                          </h6>
                          : null}

                        {orderDetails.paymentTypeTodo === 'TRANSFERENCIA' ?
                          <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                            Método de pago: &nbsp;<strong style={{ fontSize: "13px", color: "#ca0505" }}>{orderDetails.paymentTypeTodo}</strong>
                          </h6>
                          : null}

                        {orderDetails.paymentTypeTrans === 'TRANSFERENCIA' ?
                          <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                            Método de pago: &nbsp;<strong style={{ fontSize: "13px", color: "#ca0505" }}>{orderDetails.paymentTypeTrans}</strong>
                          </h6>
                          : null}

                        {orderDetails.paymentType === 'EFECTIVO' && orderDetails.paymentTypeTrans === 'EFECTIVO' && orderDetails.paymentTypeTodo === 'EFECTIVO' ?
                          <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                            Método de pago: &nbsp;<strong style={{ fontSize: "13px", color: "#ca0505" }}>{orderDetails.paymentType}</strong>
                          </h6>
                          : null}

                        {orderDetails.caracteristica ?
                          <h6 style={{ fontSize: "13px", color: "#5e5c5c" }}>
                            Instrucciones: &nbsp;<strong style={{ fontSize: "13px", color: "#ca0505" }}>{orderDetails.caracteristica}</strong>
                          </h6>
                          : null}

                      </th>
                      <th style={{ paddingLeft: "80px" }} colSpan={6}>
                        <div className="imgContain">
                          <img src={logo} width="150" alt="" />
                        </div>
                      </th>

                    </tr>

                    <tr>
                      <td colSpan={6}>
                        <h6 style={{ fontSize: "12px", color: "#ffffff" }}>
                          Address:- J-601, Supertech Ecociti, Sector-137,
                          Noida-201301
                        </h6>
                      </td>
                    </tr>

                  </thead>
                </table>

              </div>
            </div>
            <div></div>
          </div>

          <div className="mt-1 divStyle4">
            <table className="table">
              <thead>

                <tr>
                  <th>
                    <h6 style={{ fontSize: "12px", textAlign: "center", padding: "25px" }}>#</h6>
                  </th>
                  <th>
                    <h6 style={{ fontSize: "12px", textAlign: "center", padding: "25px" }}> Nombre del Producto</h6>
                  </th>
                  <th>
                    <h6 style={{ fontSize: "12px", textAlign: "center", padding: "25px" }}> Valor Unitario</h6>
                  </th>
                  <th>
                    <h6 style={{ fontSize: "12px", textAlign: "center", padding: "25px" }}>  Cantidad</h6>
                  </th>
                  <th>
                    <h6 style={{ fontSize: "12px", textAlign: "center", padding: "25px" }}>  Tamaño/Referencia</h6>
                  </th>
                  <th>
                    <h6 style={{ fontSize: "12px", textAlign: "center", padding: "25px" }}>   Adición/Preferencia</h6>
                  </th>
                  {/* <th>
                  <h6 style={{ fontSize: "12px" }}>Discount (40)%</h6>
                </th> */}
                  <th>
                    <h6 style={{ fontSize: "12px", textAlign: "center" }}>  Total </h6>
                  </th>
                </tr>

                {/* <tr> */}
                {orderDetails.cart.map((item, i) => {
                  return [
                    <tr key={i}>
                      <td style={{ fontSize: "16px", textAlign: "center", padding: "18px" }}>{i + 1}</td>
                      <td style={{ fontSize: "16px", textAlign: "center", padding: "18px" }}>{item.item.title}</td>
                      <td style={{ fontSize: "16px", textAlign: "center", padding: "18px" }}>{"$"}{(item.item.price.specialPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                      <td style={{ fontSize: "16px", textAlign: "center", padding: "18px" }}>{item.item.itemQunatity}</td>
                      <td style={{ fontSize: "16px", textAlign: "center", padding: "18px" }}>{item.item.price.pname}</td>

                      <td>
                        {item.item.extraOptions.map((extra, i) => {
                          return [
                            <div key={i} >
                              <div style={{ textAlign: "center" }}>
                                <span style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                  {extra.name} {"$"}{(extra.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                </span>
                              </div>
                            </div>
                          ];
                        })}

                        {item.item.extraOptions1.length > 0 ?
                          <br />
                          : null}

                        {item.item.extraOptions1.map((extra, i) => {
                          return [
                            <div key={i} >
                              <div style={{ textAlign: "center" }}>
                                <span style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                  {extra.name1} {"$"}{(extra.value1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                </span>
                              </div>
                            </div>
                          ];
                        })}

                        {item.item.extraOptions2.length > 0 ?
                          <br />
                          : null}

                        {item.item.extraOptions2.map((extra, i) => {
                          return [
                            <div key={i} >
                              <div style={{ textAlign: "center" }}>
                                <span style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                  {extra.name2} {"$"}{(extra.value2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                </span>
                              </div>
                            </div>
                          ];
                        })}

                        {item.item.extraOptions3.length > 0 ?
                          <br />
                          : null}

                        {item.item.extraOptions3.map((extra, i) => {
                          return [
                            <div key={i} >
                              <div style={{ textAlign: "center" }}>
                                <span style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                  {extra.name3} {"$"}{(extra.value3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                </span>
                              </div>
                            </div>
                          ];
                        })}


                        {item.item.extraOptions4.length > 0 ?
                          <br />
                          : null}

                        {item.item.extraOptions4.map((extra, i) => {
                          return [
                            <div key={i} >
                              <div style={{ textAlign: "center" }}>
                                <span style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                  {extra.name4} {"$"}{(extra.value4).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                </span>
                              </div>
                            </div>
                          ];
                        })}

                      </td>

                      <td style={{ fontSize: "16px", textAlign: "center", padding: "18px" }}>{"$"}{(item.itemTotalPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                    </tr>

                  ];
                })}

                <tr>
                  <td colSpan="5"></td>
                  <td className="text-right">
                    <strong style={{ fontSize: "16px", textAlign: "center" }}>Subtotal: </strong>
                  </td>
                  <td style={{ fontSize: "16px", textAlign: "center" }}>{"$"}{(orderDetails.subTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                </tr>

                {orderDetails.isFreeDelivery ?
                  <tr>
                    <td colSpan="5"></td>
                    <td className="text-right">
                      <strong style={{ fontSize: "16px", textAlign: "center" }}>Envío: </strong>
                    </td>
                    <td style={{ fontSize: "16px", textAlign: "center" }}>GRATIS</td>
                  </tr>
                  : null}

                {orderDetails.isVariableDelivery && !orderDetails.nuevoValor ?
                  <tr>
                    <td colSpan="5"></td>
                    <td className="text-right">
                      <strong style={{ fontSize: "16px", textAlign: "center" }}>Envío: </strong>
                    </td>
                    <td style={{ fontSize: "16px", textAlign: "center" }}>VARIABLE</td>
                  </tr>
                  : null}

                {orderDetails.isTaxiDelivery ?
                  <tr>
                    <td colSpan="5"></td>
                    <td className="text-right">
                      <strong style={{ fontSize: "16px", textAlign: "center" }} >Envío: </strong>
                    </td>
                    <td style={{ fontSize: "16px", textAlign: "center" }}>En TAXI</td>
                  </tr>
                  : null}

                {!orderDetails.isVariableDelivery &&
                  !orderDetails.isFreeDelivery &&
                  !orderDetails.isTaxiDelivery && !orderDetails.nuevoValor ?
                  <tr>
                    <td colSpan="5"></td>
                    <td className="text-right">
                      <strong style={{ fontSize: "16px", textAlign: "center" }}>Envío: </strong>
                    </td>
                    <td style={{ fontSize: "16px", textAlign: "center" }}>{"$"}{(orderDetails.deliveryCharge).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                  </tr>
                  : null}

                {!orderDetails.isVariableDelivery &&
                  !orderDetails.isFreeDelivery &&
                  !orderDetails.isTaxiDelivery && orderDetails.nuevoValor ?
                  <tr>
                    <td colSpan="5"></td>
                    <td className="text-right">
                      <strong style={{ fontSize: "16px", textAlign: "center" }}>Envío: </strong>
                    </td>
                    <td style={{ fontSize: "16px", textAlign: "center" }}>{"$"}{(orderDetails.nuevoValor).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                  </tr>
                  : null}

                {orderDetails.isVariableDelivery &&
                  !orderDetails.isFreeDelivery &&
                  !orderDetails.isTaxiDelivery && orderDetails.nuevoValor ?
                  <tr>
                    <td colSpan="5"></td>
                    <td className="text-right">
                      <strong style={{ fontSize: "16px", textAlign: "center" }}>Envío: </strong>
                    </td>
                    <td style={{ fontSize: "16px", textAlign: "center" }}>{"$"}{(orderDetails.nuevoValor).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                  </tr>
                  : null}

                {orderDetails.paymentPropina && orderDetails.paymentPropina > 0 ?
                  <tr>
                    <td colSpan="5"></td>
                    <td className="text-right">
                      <strong style={{ fontSize: "16px", textAlign: "center" }} >Propina: </strong>
                    </td>
                    <td style={{ fontSize: "16px", textAlign: "center" }}>{"$"}{(orderDetails.paymentPropina).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                  </tr>
                  : null}

                {orderDetails.valorCupon && orderDetails.valorCupon > 0 ?
                  <tr>
                    <td colSpan="5"></td>
                    <td className="text-right">
                      <strong style={{ fontSize: "16px", textAlign: "center" , color: "#ca0505" }}>Cupón: </strong>
                    </td>
                    <td style={{ fontSize: "16px", textAlign: "center" , color: "#ca0505" }}>{"- $"}{(orderDetails.valorCupon).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                  </tr>
                  : null}

                {orderDetails.paymentPropina && orderDetails.paymentPropina > 0 ?
                  <tr>
                    <td colSpan="5"></td>
                    <td className="text-right">
                      <strong style={{ fontSize: "16px", textAlign: "center" }}>Total: </strong>
                    </td>
                    <td style={{ fontSize: "16px", textAlign: "center" }}>{"$"}{((orderDetails.grandTotal + orderDetails.paymentPropina)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                  </tr>
                  : null}

                {!orderDetails.paymentPropina || orderDetails.paymentPropina === 0 ?
                  <tr>
                    <td colSpan="5"></td>
                    <td className="text-right">
                      <strong style={{ fontSize: "16px", textAlign: "center" }}>Total: </strong>
                    </td>
                    <td style={{ fontSize: "16px", textAlign: "center" }}>{"$"}{((orderDetails.grandTotal)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                  </tr>
                  : null}

              </thead>
            </table>
          </div>

        </div>

      </div>

    </div>
  ));

  return (

    <React.Fragment>


      <div style={{ display: "flex", justifyContent: "center", maxWidth: "1000px" }}>

        <button onClick={() => history.goBack()} style={{ justifyContent: "right", width: "50%" }} >
          <h6 style={{ fontSize: "16px", color: "#000000", textAlign: "center" }}>
            <strong>Volver a pedidos</strong>
          </h6>
        </button>
        {/* <br/> */}
        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
        <button onClick={() => exportComponentAsJPEG(componentRef, DEFAULT_JPEG)} style={{ justifyContent: "left", width: "50%" }}>
          <h6 style={{ fontSize: "16px", color: "#000000", textAlign: "center" }}>
            <strong> Descargar Pedido</strong>
          </h6>
        </button>

      </div>

      <ComponentToPrint ref={componentRef} />



    </React.Fragment>
  );

}

export default Invoice;