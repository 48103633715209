import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
// import { 
//   features,
//   language
// } from 'config';
import { FirebaseContext } from 'common';
import { useTheme } from "@material-ui/core/styles";
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import {
//   Typography
// } from '@material-ui/core';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';



export default function Time() {
  const { api } = useContext(FirebaseContext);
  const {
    editSector
  } = api;
  const columns = [
    { title: 'Nombre', field: 'label' ,render: rowData => <span>{rowData.label}</span>},
    { title: 'Descripción', field: 'description' ,render: rowData => <span>{rowData.description}</span>},
    // { title: 'language.coordinates', field: 'coordinates' ,render: rowData => <span>{rowData.coordinates}</span>},
    { title: 'Latitud', field: 'lat' , type:'numeric' ,render: rowData => <span>{rowData.lat}</span>},
    { title: 'Longitud', field: 'lng' , type:'numeric' ,render: rowData => <span>{rowData.lng}</span>},
    // { title: language.order_values_orders, field: 'order' , type:'numeric' ,render: rowData => <span>{rowData.order}</span>},
  ];
  
  const [data, setData] = useState([]);
  const sectordata = useSelector(state => state.sectordata);
  // console.log("valueorderdata" , valueorderdata)
  const dispatch = useDispatch();
  const theme = useTheme()
  const settings = useSelector(state => state.settingsdata.settings);

  useEffect(() => {
    if (sectordata.simple) {
      setData(sectordata.simple);
    }else{
      setData([]);
    }
  }, [sectordata.simple]);

  return (
    sectordata.loading ? <CircularLoading /> :
      <MaterialTable
        title={'Sector o Barrio'}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          pageSize: 20,
          search: true,
          searchFieldAlignment: "left",
          justifyContent: "center",
          textAlign: 'center',
          headerStyle: {
            backgroundColor: theme.palette.action.hover,
            color: theme.palette.common.black,
            fontWeight: 'bold',
            textAlign: 'center',
            flexDirection: 'row',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        }}
      
        editable={settings.AllowCriticalEditsAdmin ? {
            onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                newData.value = tblData.length
                tblData.push(newData);
                dispatch(editSector(tblData, "Add"));
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData[tblData.indexOf(oldData)] = newData;
                dispatch(editSector(tblData, "Update"));
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData.splice(tblData.indexOf(oldData), 1);
                for(let i=0;i<tblData.length;i++){
                  tblData[i].value = i;
                }
                dispatch(editSector(tblData, "Delete"));
              }, 600);
            }),
        } : null}

        actions={[

          rowData => ({
            icon: () =>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <RemoveRedEyeIcon />
                {/* <Typography variant="subtitle2">{'Ubicación'}</Typography> */}
              </div>,
            onClick: (event, rowData) => {
              window.open(`https://maps.google.com/maps?q=${rowData.lat},${rowData.lng}&hl=es;z=14&amp;output=embed`, '_blank').focus()
            }
          })

        ]}
      />
  );
}
