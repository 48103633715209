import React, { useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
// import moment from 'moment/min/moment-with-locales';
import moment from 'moment';
import 'moment/min/locales'
import { useTheme } from "@material-ui/core/styles";
// import CustomDatePicker from "./CustomDatePicker";
// import {
//   MuiPickersUtilsProvider,
//   // KeyboardDatePicker,
//   KeyboardDateTimePicker,
//   // DateTimePicker,
//   // DatePicker
// } from "@material-ui/pickers";
import "date-fns";
// import DateFnsUtils from "@date-io/date-fns";
// import MomentUtils from "@date-io/moment";
import { Typography } from "@material-ui/core";
import {
  TextField,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
// import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 780,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function Bonos() {
  const { api, appcat } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    deletePromosApp,
    deletePromosAppBusiness,
    editPromosApp,
    editPromosAppBusiness,
    addPromosApp
  } = api;
  //   const settings = useSelector(state => state.settingsdata.settings);

  const columns = [
    { title: 'Registro', field: 'createdAtUnix', cellStyle: { paddingLeft: 100 }, headerStyle: { paddingLeft: 100 }, editable: 'never', render: rowData => rowData.createdAtUnix ? moment(rowData.createdAtUnix).format('MMMM Do YYYY, h:mm:ss a') : null },
    { title: 'Nombre bono', field: 'bono_name' },
    // { title: 'Usuario', field: 'user', editable: 'onAdd', render: rowData => rowData.user ? rowData.user : "" },
    { title: 'Correo Usuario', field: 'email_user', cellStyle: { paddingLeft: 20 }, headerStyle: { paddingLeft: 20 }, editable: 'onAdd', render: rowData => rowData.email_user ? rowData.email_user : "" },
    { title: 'Valor bono', field: 'discount_value', render: rowData => rowData.discount_value ? "$" + rowData.discount_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : "" },
    { title: 'Minimo pedido', field: 'min_order', render: rowData => rowData.min_order ? "$" + rowData.min_order.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : "" },
    { title: 'Establecimientos para uso',
     field: 'Establecimientos',
     editable: 'never',
      render: rowData => rowData.Establecimientos && rowData.Establecimientos.length > 0 ? 
      rowData.Establecimientos.map((element, i) => {
        return (
          <li key={i}>
         {element.restName}
        </li>
          // <span>{" " + element.restName}</span>
        )
      })
       : "SIN ESTABLECIMIENTOS" },
    {
      title: 'Vencimiento',
      field: 'promo_validity',
      type: "date",
      // dateSetting: { locale: "en-GB" },
      // render: rowData => moment.unix(rowData.promo_validity).format('MMMM DD, h:mm:ss a'),
      render: rowData => <span>{rowData.promo_validity ? moment(rowData.promo_validity).format(" MMM DD , h:mm a") : ""}</span>,
      // filterComponent: (props) => <CustomDatePicker {...props} />,
      editComponent: props => (
        <TextField
          id="datetime-local"
          // label={'Fecha y hora'}
          type="datetime-local"
          variant="filled"
          fullWidth
          InputProps={{
            style: { textAlignLast: 'center' }
          }}
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
        />
      ),
      // editComponent: ({ value, onChange }) => (
      //   <MuiPickersUtilsProvider utils={DateFnsUtils}>
      //     <KeyboardDateTimePicker
      //       // inputVariant="filled"
      //       helperText=""
      //       value={value}
      //       onChange={onChange}
      //     />
      //   </MuiPickersUtilsProvider>
      // ),
    },
    { title: 'Activo', field: 'active', type: 'boolean', initialEditValue: false },
    { title: 'Fecha Uso', field: 'useBono', editable: 'never', render: rowData => rowData.useBono ? moment(rowData.useBono).format('MMMM Do YYYY, h:mm:ss a') : null },
    { title: 'Establecimiento Uso', field: 'restaurantName', editable: 'never', render: rowData => rowData.restaurantName ? rowData.restaurantName : null },
  ];

  const subcolumns = [
    { title: 'Key Establecimiento', field: 'key_rest', render: rowData => <span>{rowData.key_rest}</span> },
    { title: 'Establecimiento', field: 'restName', editable: 'never', render: rowData => <span>{rowData.restName}</span> },
  ];
  const [data, setData] = useState([]);
  const [dataRest, setDataRest] = useState([]);

  const [modalType, setModalType] = useState();
  const [rowIndex, setRowIndex] = useState();
  const [open, setOpen] = useState(false);
  //   const promodata = useSelector(state => state.promodata);
  const promoappdata = useSelector(state => state.promoappdata);
  const settings = useSelector(state => state.settingsdata.settings);
  //   console.log("promoappdata ",promoappdata)
  const usersappdata = useSelector(state => state.usersappdata);
  // console.log("usersappdata ", usersappdata)
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const theme = useTheme()
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (usersappdata.users) {
      setDataRest(usersappdata.users.filter(user => user.city === 'CARTAGO'));
    } else {
      setDataRest([]);
    }
  }, [usersappdata.users])
  // console.log("dataRest ", dataRest)

  useEffect(() => {
    if (promoappdata.simple) {
      setData(promoappdata.simple);
    } else {
      setData([]);
    }
  }, [promoappdata.simple]);
  // console.log("data ", data)

  useEffect(() => {
    if (dataRest && data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].Establecimientos && data[i].Establecimientos.length > 0) {
          const establecimientos = data[i].Establecimientos;
          for (let h = 0; h < establecimientos.length; h++) {
            for (let j = 0; j < dataRest.length; j++) {
              if (dataRest[j].id === establecimientos[h].key_rest) {
                establecimientos[h].restName = dataRest[j].name
              }
            }
          }
        }
      }
    }
  }, [dataRest, data]);
  // console.log("dataRest ", data)

  return (
    promoappdata.loading ? <CircularLoading /> :
      <div ref={rootRef}>
        <MaterialTable
          title={'Bonos Usuarios'}
          columns={columns}
          data={data}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} of {count}'
            },
            toolbar: {
              nRowsSelected: '{0} row(s) selected',
              searchPlaceholder: 'Buscar bono'
            },
            header: {
              actions: 'Acciones'
            },
            body: {
              emptyDataSourceMessage: 'No records to display',
              filterRow: {
                filterTooltip: 'Filter'
              }
            }
          }}
          options={{
            pageSize: 10,
            exportButton: true,
            justifyContent: "center",
            textAlign: 'center',
            searchFieldAlignment: "left",
            filtering: false,
            search: true,
            headerStyle: {
              backgroundColor: theme.palette.action.hover,
              color: theme.palette.common.black,
              fontWeight: 'bold',
              // textAlign: 'center',
              flexDirection: 'row',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }
          }}
          editable={{
            onRowAdd: newData =>
              new Promise(resolve => {
                setTimeout(() => {
                  newData['createdAt'] = new Date().toISOString();
                  newData['createdAtUnix'] = new Date().getTime();
                  dispatch(addPromosApp(newData));
                  resolve();
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  dispatch(editPromosApp(oldData.id, newData));
                }, 600);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  dispatch(deletePromosApp(oldData.id));
                }, 600);
              })
          }}
          actions={[
            rowData => (appcat === 'delivery' ? {
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <BusinessIcon />
                <Typography variant="subtitle2">{'Agregar Establecimientos'}</Typography>
              </div>,
              onClick: (event, rowData) => {
                setModalType('Establecimientos')
                setRowIndex(rowData.tableData.id);
                setOpen(true);
              }
            } : null),
          ]}
        />
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          onClose={handleClose}
          open={open}
          className={classes.modal}
          container={() => rootRef.current}
        >
          <div className={classes.paper}>
            <MaterialTable
              title={'Establecimientos'}
              columns={subcolumns}
              data={(data[rowIndex] && data[rowIndex][modalType]) ? data[rowIndex][modalType] : []}
              options={{
                exportButton: true,
              }}
              editable={{
                onRowAdd: newData =>
                  settings.AllowCriticalEditsAdmin ?
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        let tblData = data;
                        if (!tblData[rowIndex][modalType]) {
                          tblData[rowIndex][modalType] = [];
                        }
                        tblData[rowIndex][modalType].push(newData);
                        dispatch(editPromosAppBusiness(tblData[rowIndex].id, newData, tblData[rowIndex]));
                      }, 600);
                    })
                    :
                    new Promise(resolve => {
                      setTimeout(() => {
                        resolve();
                        alert(t('demo_mode'));
                      }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                  settings.AllowCriticalEditsAdmin ?
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        let tblData = data;
                        tblData[rowIndex][modalType][tblData[rowIndex][modalType].indexOf(oldData)] = newData;
                        dispatch(editPromosAppBusiness(tblData[rowIndex].id, newData, tblData[rowIndex]));
                      }, 600);
                    })
                    :
                    new Promise(resolve => {
                      setTimeout(() => {
                        resolve();
                        alert(t('demo_mode'));
                      }, 600);
                    }),
                onRowDelete: oldData =>
                  settings.AllowCriticalEditsAdmin ?
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        let tblData = data;
                        tblData[rowIndex][modalType].splice(tblData[rowIndex][modalType].indexOf(oldData), 1);
                        // dispatch(editCarType(tblData[rowIndex]), "Update");
                        // dispatch(deletePromosApp(oldData.id));
                        dispatch(deletePromosAppBusiness(tblData[rowIndex].id, oldData, tblData[rowIndex]));
                      }, 600);
                    })
                    :
                    new Promise(resolve => {
                      setTimeout(() => {
                        resolve();
                        alert(t('demo_mode'));
                      }, 600);
                    }),
              }}
            />
          </div>
        </Modal>
      </div>
  );
}
