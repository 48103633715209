import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
// import { 
//   features,
//   language
// } from 'config';
import { FirebaseContext } from 'common';
// import { useTheme } from "@material-ui/core/styles";
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import {
//   Typography
// } from '@material-ui/core';
// import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from '@material-ui/icons/AddBox';
import GetAppIcon from '@material-ui/icons/GetApp';




export default function TemplateSms() {
  const { api } = useContext(FirebaseContext);
  const {
    editTemplate,
    addTemplate,
    deleteTemplate
  } = api;
  const { t } = useTranslation();
  // const isRTL = i18n.dir();
  const columns = [
    { title: 'Nombre Plantilla', field: 'campaign', render: rowData => <span>{rowData.campaign}</span> },
    // { title: 'Descripción', field: 'description' ,render: rowData => <span>{rowData.description}</span>},
    { title: 'Mensaje', field: 'sms', render: rowData => <span>{rowData.sms}</span> },
    // { title: 'language.coordinates', field: 'coordinates' ,render: rowData => <span>{rowData.coordinates}</span>},
    // { title: 'Latitud', field: 'lat', type: 'numeric', render: rowData => <span>{rowData.lat}</span> },
    // { title: 'Longitud', field: 'lng', type: 'numeric', render: rowData => <span>{rowData.lng}</span> },
    // { title: language.order_values_orders, field: 'order' , type:'numeric' ,render: rowData => <span>{rowData.order}</span>},
  ];

  const [data, setData] = useState([]);
  const templatedata = useSelector(state => state.templatedata);
  // console.log("templatedata" , templatedata)
  const dispatch = useDispatch();
  // const theme = useTheme()
  const settings = useSelector(state => state.settingsdata.settings);

  useEffect(() => {
    if (templatedata.simple) {
      setData(templatedata.simple);
    } else {
      setData([]);
    }
  }, [templatedata.simple]);

  const theme = useTheme()


  return (
    templatedata.loading ? <CircularLoading /> :
      <MaterialTable
        title={'Plantillas SMS'}
        columns={columns}
        data={data}
        icons={{
          Edit: () => <EditIcon style={{ color: "000000" }} />,
          Delete: () => <DeleteIcon style={{ color: "000000" }} />,
          Add: () => <AddBoxIcon style={{ color: "000000" }} />,
          Export: () => <GetAppIcon style={{ color: "000000" }} />
          
        }}
        options={{
          pageSize: 10,
          exportAllData: true,
          exportButton: settings.AllowCriticalEditsAdmin,
          sorting: true,
          headerStyle: {
            backgroundColor: theme.palette.action.hover,
            color: theme.palette.common.black,
            fontWeight: 'bold',
            fontSize: 15,
            textAlign: 'center',
            flexDirection: 'row',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        localization={{
          body: {
            addTooltip: (t('add')),
            deleteTooltip: (t('delete')),
            editTooltip: (t('edit')),
            emptyDataSourceMessage: (
              (t('blank_message'))
            ),
            editRow: {
              deleteText: (t('delete_message')),
              cancelTooltip: (t('cancel')),
              saveTooltip: (t('save'))
            },
          },
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          header: {
            actions: ('Acciones')
          },
          // pagination: {
          //   labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
          //   labelRowsSelect: (t('rows')),
          //   firstTooltip: (t('first_page_tooltip')),
          //   previousTooltip: (t('previous_page_tooltip')),
          //   nextTooltip: (t('next_page_tooltip')),
          //   lastTooltip: (t('last_page_tooltip'))
          // },
        }}

        editable={settings.AllowCriticalEditsAdmin ? {
          onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                newData.value = tblData.length
                // newData['city'] = 'TODOS';
                tblData.push(newData);
                dispatch(addTemplate(newData));
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData[tblData.indexOf(oldData)] = newData;
                // dispatch(editSector(tblData, "Update"));
                dispatch(editTemplate(oldData.id, newData));
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData.splice(tblData.indexOf(oldData), 1);
                for (let i = 0; i < tblData.length; i++) {
                  tblData[i].value = i;
                }
                // dispatch(editSector(tblData, "Delete"));
                dispatch(deleteTemplate(oldData.id));
              }, 600);
            }),
        } : null}

        // actions={[

        //   rowData => ({
        //     icon: () =>
        //       <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        //         <RemoveRedEyeIcon style={{ color: "e84fb7" }}/>
        //         {/* <Typography variant="subtitle2">{'Ubicación'}</Typography> */}
        //       </div>,
        //     onClick: (event, rowData) => {
        //       window.open(`https://maps.google.com/maps?q=${rowData.lat},${rowData.lng}&hl=es;z=14&amp;output=embed`, '_blank').focus()
        //     }
        //   })

        // ]}
      />
  );
}
