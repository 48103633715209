import React,{ useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
// import moment from 'moment/min/moment-with-locales';
import 'moment/min/locales'
// import moment from 'moment';
import { useTheme } from "@material-ui/core/styles";
import {
  // Avatar,
  // Button,
  // Chip,
  // Container,
  FormControlLabel,
  Switch,
  // TextField
} from "@material-ui/core";


export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    addUser,
    editUser, 
    deleteUser,
    checkUserExists,
    updateApproved,
    updatePrimeRider
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  // const [dataOrder, setDataOrder] = useState([]);
  const usersdata = useSelector(state => state.usersdata);
  // console.log("usersdata",usersdata)
  const dispatch = useDispatch();

  const theme = useTheme()

  // useEffect(()=>{
  //   if(usersdata.users){
  //     let usersOrder = [];
  //     for (let i = 0; i < usersdata.users.length; i++) {
  //       const element = usersdata.users[i];
  //       let unix =  new Date(element.createdAt);
  //       let timestamp = unix.getTime();
  //       timestamp = element.createdAtUnix;
  //       usersOrder.push(element)
  //     }
  //     setDataOrder(usersOrder);
  //   }else{
  //     setDataOrder([]);
  //   }
  // },[usersdata.users]);
  // // console.log("dataOrder" ,dataOrder)

  const changeApproved = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    // console.log("newRow" ,newRow);
    if (newRow) {
      dispatch(
        updateApproved(newRow
      //     {
      //   approved: newRow.approved,
      //   booking: newRow.id,
      //   // cancelledBy: role
      // }
      ));
    }
  };
  const changePrime = (oldRow, e) => {
    const changeData = { [e.target.name]: e.target.checked };
    const newRow = { ...oldRow, ...changeData };
    // console.log(oldRow ,e);
    const index = data.findIndex(dtaRow => dtaRow.id === oldRow.id);
    const newData = data;
    newData[index] = newRow;
    // console.log("newRow" ,newRow);
    if (newRow) {
      dispatch(
        updatePrimeRider(newRow
      //     {
      //   approved: newRow.approved,
      //   booking: newRow.id,
      //   // cancelledBy: role
      // }
      ));
    }
  };

  useEffect(()=>{
    if(usersdata.users){
      setData(usersdata.users.filter(user => user.usertype ==='rider'));
    }else{
      setData([]);
    }
  },[usersdata.users]);

  const columns = [
    // { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null},
    { title: 'Imagen', field: 'profile_image', render: rowData => rowData.profile_image?<img alt='Profile' src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null},
    { title: "Descripción", field: 'nameEstablishment' },
    // { title: t('account_approve'),  field: 'approved', type:'boolean'},
    {
      title: "Aprobado",
      field: "approved",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.approved}
              onChange={e => changeApproved(data, e)}
              name="approved"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.approved === true ? 'green' : data.approved === false ? 'red' : null }}>{data.approved === true ? 'APROBADO' : 'INACTIVO'}</span>}
        />
      )
    },
    // { title: 'Prime',  field: 'prime', type:'boolean'},
    {
      title: "Prime",
      field: "prime",
      render: (data, id) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.prime}
              onChange={e => changePrime(data, e)}
              name="prime"
              color="primary"
            />
          }
          label={<span style={{ fontWeight: 'bold', fontSize: 16, color: data.prime === true ? 'green' : data.prime === false ? 'red' : null }}>{data.prime === true ? 'ACTIVO' : 'INACTIVO'}</span>}
        />
      )
    },
    { title: "Dirección", field: 'directionEstablishment'},
    { title: 'Teléfono', field: 'mobile',render: rowData => settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo"},
    { title: 'Nombre Admin', field: 'firstName'},
    { title: 'Apellido Admin', field: 'lastName'},
    { title: "Latitud", field: 'location.lat' , type:'numeric'},
    { title: "Longitud", field: 'location.lng' , type:'numeric'},
    { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo"},
    // { title: t('profile_image'),  field: 'profile_image', render: rowData => rowData.profile_image?<img alt='Profile' src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null, editable:'never'},
  
    { title: 'Saldo billetera',  field: 'walletBalance', editable:'never', render: rowData => <span>{rowData.walletBalance ? "$" + rowData.walletBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: 'ID ref registro', field: 'signupViaReferral', editable:'never' },
    { title: t('referralId'),  field: 'referralId', initialEditValue: '' },
    { title: 'Key', field: 'id', editable: 'never' },
    { title: 'PushToken', field: 'pushToken', editable: 'never', initialEditValue: '' },
  ];

  return (
    usersdata.loading? <CircularLoading/>:
    <MaterialTable
      title={'Establecimientos App Domicilios'}
      columns={columns}
      data={data}
      options={{
        // toolbar: false,
        exportButton: settings.AllowCriticalEditsAdmin,
        sorting: true,
        pageSize: 20,
        search: true,
        searchFieldAlignment: "left",
        headerStyle: {
          backgroundColor: "#EFE9E9",
          color: theme.palette.common.black,
          fontWeight: 'bold',
          textAlign: 'center',
          flexDirection: 'row',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          position: 'sticky',
          top: 0
        },
        maxBodyHeight: '900px',
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve,reject) => {
            setTimeout(() => {
              checkUserExists(newData).then((res) => {
                if (res.users && res.users.length > 0) {
                  alert(t('user_exists'));
                  reject();
                }
                else if(res.error){
                  alert(t('email_or_mobile_issue'));
                  reject();
                }
                else{
                  newData['createdByAdmin'] = true;
                  newData['usertype'] = 'rider';
                  newData['createdAt'] = new Date().toISOString();
                  newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
                  dispatch(addUser(newData));
                  resolve();
                }
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editUser(oldData.id,newData));
            }, 600);
          }),
        onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(deleteUser(oldData.id));
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          })
          , 
      }}
    />
  );
}
