import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
// import { 
//   features,
//   language
// } from 'config';
// import { useTranslation } from "react-i18next";
import { FirebaseContext } from 'common';
import { useTheme } from "@material-ui/core/styles";

export default function OrderValue() {
  const { api } = useContext(FirebaseContext);
  const {
    editValueOrders
  } = api;
  const columns = [
    { title:'Valor de pedido', field: 'label' ,render: rowData => <span>{rowData.label}</span>},
    // { title: language.order_values_orders, field: 'order' ,render: rowData => <span>{rowData.order}</span>},
  ];

//   const { t } = useTranslation();
  const [data, setData] = useState([]);
  const valueorderdata = useSelector(state => state.valueorderdata);
  // console.log("valueorderdata" , valueorderdata)
  const dispatch = useDispatch();
  const theme = useTheme()
  const settings = useSelector(state => state.settingsdata.settings);

  useEffect(() => {
    if (valueorderdata.complex) {
      setData(valueorderdata.complex);
    }else{
      setData([]);
    }
  }, [valueorderdata.complex]);

  return (
    valueorderdata.loading ? <CircularLoading /> :
      <MaterialTable
        title={'Valor de pedidos'}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          pageSize: 10,
          justifyContent: "center",
          textAlign: 'center',
          headerStyle: {
            backgroundColor: theme.palette.action.hover,
            color: theme.palette.common.black,
            fontWeight: 'bold',
            // textAlign: 'center',
            flexDirection: 'row',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
            onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                newData.value = tblData.length
                tblData.push(newData);
                dispatch(editValueOrders(tblData, "Add"));
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData[tblData.indexOf(oldData)] = newData;
                dispatch(editValueOrders(tblData, "Update"));
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData.splice(tblData.indexOf(oldData), 1);
                for(let i=0;i<tblData.length;i++){
                  tblData[i].value = i;
                }
                dispatch(editValueOrders(tblData, "Delete"));
              }, 600);
            }),
        } : null}
      />
  );
}
