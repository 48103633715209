import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
// import { 
//   features,
//   language
// } from 'config';
import { FirebaseContext } from 'common';
import { useTheme } from "@material-ui/core/styles";

export default function Time() {
  const { api } = useContext(FirebaseContext);
  const {
    editTime
  } = api;
  const columns = [
    { title: 'Tiempo de recogida',  field: 'label' ,render: rowData => <span style={{ textAlign: "center" }}>{rowData.label}</span>},
    { title: 'Minutos', field: 'minutes' , type:'numeric' ,render: rowData => <span>{rowData.minutes}</span>},
    // { title: language.order_values_orders, field: 'order' ,render: rowData => <span>{rowData.order}</span>},
  ];
  
  const [data, setData] = useState([]);
  const timedata = useSelector(state => state.timedata);
  // console.log("valueorderdata" , valueorderdata)
  const dispatch = useDispatch();
  const theme = useTheme()
  const settings = useSelector(state => state.settingsdata.settings);

  useEffect(() => {
    if (timedata.complex) {
      setData(timedata.complex);
    }else{
      setData([]);
    }
  }, [timedata.complex]);

  return (
    timedata.loading ? <CircularLoading /> :
      <MaterialTable
        title={'Tiempo de recogidas'}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          sorting: true,
          pageSize: 10,
          // justifyContent: "center",
          textAlign: 'center',
          headerStyle: {
            backgroundColor: theme.palette.action.hover,
            color: theme.palette.common.black,
            fontWeight: 'bold',
            // textAlign: 'center',
            flexDirection: 'row',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
            onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                newData.value = tblData.length
                tblData.push(newData);
                dispatch(editTime(tblData, "Add"));
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData[tblData.indexOf(oldData)] = newData;
                dispatch(editTime(tblData, "Update"));
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData.splice(tblData.indexOf(oldData), 1);
                for(let i=0;i<tblData.length;i++){
                  tblData[i].value = i;
                }
                dispatch(editTime(tblData, "Delete"));
              }, 600);
            }),
        } : null}
      />
  );
}
