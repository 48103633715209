export const colors = {
    LandingPage_Sec_info : '#F1F2F1',
    LandingPage_Background: '#E9E9E1',
    Landing_description: '#373737',
    Btn_Primary: '#46F34D',
    Btn_Secondary: '#239B28', 
    Header_background: '#FFFFFF',
    header_content: '#000000',
    AppManu_Header: '#444444',
    RespossiveDrawer_Header: '#444444',
    HeaderPersonal: '#E6E3E2',
}