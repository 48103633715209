import React, { useEffect, useContext } from 'react';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { FirebaseContext } from 'common';
import i18n from "i18next";
import { useTranslation } from "react-i18next";

function AuthLoading(props) {
    const { api } = useContext(FirebaseContext);
    const { t } = useTranslation();
    const {
        fetchFiltros,
        fetchSupercategoria,
        fetchbannerPpal,
        fetchWp,
        fetchWh,
        fetchLluvia,
        fetchUsersAddress,
        fetchWallet,
        fetchOrders,
        // fetchAllOrders,
        fetchTemplate,
        fetchTemplateOneSignal,
        fetchOneSignalUsers,
        fetchCounter,
        fetchSmsUsers,
        senAllOrders,
        senAllOrdersUsers,
        fetchUser,
        fetchUsersApp,
        fetchUsersDataApp,
        fetchCarTypes,
        fetchSettings,
        fetchBookings,
        fetchCancelReasons,
        fetchValueOrders,
        fetchTime,
        fetchSector,
        fetchPromos,
        fetchPromosApp,
        fetchDriverEarnings,
        fetchUsers,
        fetchAddress,
        fetchNotifications,
        fetchSms,
        fetchEarningsReport,
        fetchEarningsordersReport,
        fetchDriverEarningsOrders,
        signOut,
        fetchWithdraws,
        fetchLanguageFiles,
        fetchPaymentMethods
    } = api;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    // console.log(" auth.info.profile " , auth.info.profile)
    const languagedata = useSelector(state => state.languagedata);
    const settingsdata = useSelector(state => state.settingsdata);

    useEffect(() => {
        dispatch(fetchLanguageFiles());
        dispatch(fetchCarTypes());
        dispatch(fetchSettings());
    }, [dispatch, fetchLanguageFiles, fetchCarTypes, fetchSettings]);

    useEffect(() => {
        if (languagedata.json) {
            for (const [key, value] of Object.entries(languagedata.json)) {
                i18n.addResourceBundle(key, 'translations', value);
            }
            dispatch(fetchUser());
        }
    }, [languagedata, dispatch, fetchUser])

    useEffect(() => {
        if (settingsdata.settings) {
            document.title = settingsdata.settings.appName
        }
    }, [settingsdata.settings])

    useEffect(() => {
        if (auth.info) {
            if (auth.info.profile) {
                let role = auth.info.profile.usertype;
                if (role === 'rider') {
                    dispatch(fetchBookings(auth.info.uid, role));
                    dispatch(fetchPaymentMethods());
                    dispatch(fetchCancelReasons());
                    dispatch(fetchValueOrders());
                    dispatch(fetchTime());
                    dispatch(fetchSector());
                    dispatch(fetchUsers());
                    dispatch(fetchUsersApp());
                    dispatch(fetchOrders(auth.info.uid, role));
                    dispatch(fetchUsersAddress());
                }
                else if (role === 'driver') {
                    dispatch(fetchBookings(auth.info.uid, role));
                }
                else if (role === 'adminPpal') {
                    dispatch(fetchUsersAddress());
                    dispatch(fetchWallet());
                    dispatch(fetchOrders(auth.info.uid, role));
                    // dispatch(fetchAllOrders(auth.info.uid, role));
                    dispatch(fetchUsers());
                    dispatch(senAllOrders());
                    dispatch(fetchAddress());
                    dispatch(senAllOrdersUsers());
                    dispatch(fetchTemplate());
                    dispatch(fetchTemplateOneSignal());
                    dispatch(fetchOneSignalUsers());
                    dispatch(fetchCounter());
                    dispatch(fetchSmsUsers());
                    dispatch(fetchUsersApp());
                    dispatch(fetchUsersDataApp());
                    dispatch(fetchBookings(auth.info.uid, role));
                    dispatch(fetchPromos());
                    dispatch(fetchPromosApp());
                    dispatch(fetchDriverEarnings(auth.info.uid, role));
                    dispatch(fetchNotifications());
                    dispatch(fetchSms());
                    dispatch(fetchEarningsReport());
                    dispatch(fetchEarningsordersReport());
                    dispatch(fetchDriverEarningsOrders());
                    dispatch(fetchCancelReasons());
                    dispatch(fetchWp());
                    dispatch(fetchWh());
                    dispatch(fetchLluvia());
                    dispatch(fetchbannerPpal());
                    dispatch(fetchSupercategoria());
                    dispatch(fetchFiltros());
                    dispatch(fetchValueOrders());
                    dispatch(fetchTime());
                    dispatch(fetchSector());
                    dispatch(fetchWithdraws());
                    dispatch(fetchPaymentMethods());
                }
                else if (role === 'admin') {
                    dispatch(fetchUsersAddress());
                    dispatch(fetchWallet());
                    dispatch(fetchOrders(auth.info.uid, role));
                    // dispatch(fetchAllOrders(auth.info.uid, role));
                    dispatch(fetchUsers());
                    dispatch(senAllOrders());
                    dispatch(fetchAddress());
                    dispatch(senAllOrdersUsers());
                    dispatch(fetchTemplate());
                    dispatch(fetchTemplateOneSignal());
                    dispatch(fetchOneSignalUsers());
                    dispatch(fetchCounter());
                    dispatch(fetchSmsUsers());
                    dispatch(fetchUsersApp());
                    dispatch(fetchUsersDataApp());
                    dispatch(fetchBookings(auth.info.uid, role));
                    dispatch(fetchPromos());
                    dispatch(fetchPromosApp());
                    dispatch(fetchDriverEarnings(auth.info.uid, role));
                    dispatch(fetchNotifications());
                    dispatch(fetchSms());
                    dispatch(fetchEarningsReport());
                    dispatch(fetchEarningsordersReport());
                    dispatch(fetchDriverEarningsOrders());
                    dispatch(fetchCancelReasons());
                    dispatch(fetchWp());
                    dispatch(fetchWh());
                    dispatch(fetchLluvia());
                    dispatch(fetchbannerPpal());
                    dispatch(fetchSupercategoria());
                    dispatch(fetchFiltros());
                    dispatch(fetchValueOrders());
                    dispatch(fetchTime());
                    dispatch(fetchSector());
                    dispatch(fetchWithdraws());
                    dispatch(fetchPaymentMethods());
                }
                else if (role === 'fleetadmin') {
                    dispatch(fetchUsers());
                    dispatch(fetchBookings(auth.info.uid, role));
                    dispatch(fetchDriverEarnings(auth.info.uid, role));
                }
                else {
                    alert(t('not_valid_user_type'));
                    dispatch(signOut());
                }
            } else {
                alert(t('user_issue_contact_admin'));
                dispatch(signOut());
            }
        }
    }, [auth.info,
        dispatch,
        fetchBookings,
        fetchCancelReasons,
        fetchSupercategoria,
        fetchbannerPpal,
        fetchFiltros,
        fetchWp,
        fetchWh,
        fetchLluvia,
        fetchOrders,
        // fetchAllOrders,
        fetchTemplate,
        fetchTemplateOneSignal,
        fetchCounter,
        fetchSmsUsers,
        fetchOneSignalUsers,
        senAllOrders,
        senAllOrdersUsers,
        fetchWallet,
        fetchUsersAddress,
        fetchAddress,
        fetchValueOrders,
        fetchTime,
        fetchSector,
        fetchDriverEarnings,
        fetchEarningsReport,
        fetchEarningsordersReport,
        fetchDriverEarningsOrders,
        fetchNotifications,
        fetchSms,
        fetchPromos,
        fetchPromosApp,
        fetchUsers,
        fetchUsersApp,
        fetchUsersDataApp,
        fetchWithdraws,
        signOut,
        fetchPaymentMethods
        , t
    ]);

    return (
        auth.loading ? <CircularLoading /> : props.children
    )
}

export default AuthLoading;