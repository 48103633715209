import React, {
  useState,
  useEffect,
  //  useContext,
  //   useRef
} from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AppMenu from "../views/AppMenu";
import { colors } from '../components/Theme/WebTheme';
import { useSelector } from "react-redux";
// import Container from "@material-ui/core/Container";

// import moment from 'moment'
// import 'moment/min/locales'

// import clsx from 'clsx';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import Divider from '@material-ui/core/Divider';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import ListItemText from '@material-ui/core/ListItemText';


// import useSound from "use-sound";
// import beep from "../assets/sounds/beep.mp3";

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    // marginBottom: 50,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    marginTop: 60,
    padding: theme.spacing(1),
  },
}));

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   appBar: {
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//   },
//   appBarShift: {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: drawerWidth,
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   hide: {
//     display: 'none',
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
//   drawerHeader: {
//     display: 'flex',
//     alignItems: 'center',
//     backgroundColor: colors.HeaderPersonal,
//     // color: colors.AppManu_Header,
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//     justifyContent: 'flex-end',
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(1),
//     // marginTop: 50,
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: -drawerWidth,
//   },
//   contentShift: {
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: 0,
//   },
// }));


function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const orderslistdata = useSelector(state => state.orderslistdata);
  const userdata = useSelector(state => state.usersdata);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const settings = useSelector(state => state.settingsdata.settings);

  const [totalOrder, settotalOrder] = useState([]);
  // const [dataBooking, setDataBooking] = useState([]);
  const [dataBookingActive, setDataBookingActive] = useState([]);
  const [dataOrderActive, setDataOrderActive] = useState([]);
  // const [dataOrder, setDataOrder] = useState([]);


  // console.log("userdata", userdata)
  const [users, setUsers] = useState(null);

  const [User0, setUser0] = useState([]);
  const [User1, setUser1] = useState([]);
  const [User2, setUser2] = useState([]);
  const [User3, setUser3] = useState([]);
  const [User4, setUser4] = useState([]);
  const [User5, setUser5] = useState([]);

  // const [open, setOpen] = React.useState(false);


  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const auth = useSelector(state => state.auth);
  const [role, setRole] = useState(null);

  // const lluviadata = useSelector(state => state.lluviadata);
  // const [data, setData] = useState([]);
  // console.log("lluviadata" , lluviadata)



  useEffect(() => {
    if (bookinglistdata.bookings) {
      // setDataBooking((bookinglistdata.bookings).filter(booking => booking.status === 'NEW'));
      setDataBookingActive((bookinglistdata.bookings).filter(booking => booking.status === 'STARTED' || booking.status === 'ACCEPTED' || booking.status === 'ARRIVED'));
    } else {
      setDataBookingActive([]);
    }
  }, [bookinglistdata.bookings]);
  // console.log("dataBookingActive", dataBookingActive)
  // console.log("dataBooking" , dataBooking)
  // console.log("dataBooking.length" , dataBooking.length)


  useEffect(() => {
    if (orderslistdata.bookings) {
      // setDataOrder((orderslistdata.bookings).filter(booking => booking.status === 'Pendiente'));
      setDataOrderActive((orderslistdata.bookings).filter(booking => (booking.status === 'Recibido' || booking.status === 'Recogido')));
    } else {
      setDataOrderActive([]);
    }
  }, [orderslistdata.bookings]);
  // console.log("dataOrderActive", dataOrderActive)
  // console.log("dataOrder", dataOrder)
  // console.log("dataOrder.length" , dataOrder.length)


  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === 'driver' && user.driverActiveStatus && user.approved && !user.queue) {
          arr.push({
            'firstName': (user.firstName),
            'lastName': (user.lastName),
            'mobile': user.mobile,
            'email': user.email,
            'uid': user.id,
            'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
            'pushToken': user.pushToken
          });
        }
      }
      setUsers(arr);
    }
  }, [userdata.users, settings.AllowCriticalEditsAdmin]);
  // console.log("users ", users)

  useEffect(() => {
    if (dataOrderActive.length > 0 && dataBookingActive.length > 0) {
      settotalOrder([...dataOrderActive, ...dataBookingActive])
    }
    if (dataOrderActive.length > 0 && dataBookingActive.length === 0) {
      settotalOrder(dataOrderActive)
    }
    if (dataBookingActive.length > 0 && dataOrderActive.length === 0) {
      settotalOrder(dataBookingActive)
    }
  }, [dataOrderActive, dataBookingActive]);
  // console.log("totalOrder", totalOrder)



  useEffect(() => {
    let pedidosUser0 = []
    let pedidosUser1 = []
    let pedidosUser2 = []
    let pedidosUser3 = []
    let pedidosUser4 = []
    let pedidosUser5 = []

    if (totalOrder.length > 0) {
      for (let i = 0; i < totalOrder.length; i++) {
        if (totalOrder[i].driver) {

          if (users && users[0]) {
            if (totalOrder[i].driver === users[0].uid) {
              pedidosUser0.push(totalOrder[i])
            }
            if (pedidosUser0.length > 0) {
              setUser0(pedidosUser0)
            } else {
              setUser0([])
            }
          }

          if (users && users[1]) {
            if (totalOrder[i].driver === users[1].uid) {
              pedidosUser1.push(totalOrder[i])
            }
            if (pedidosUser1.length > 0) {
              setUser1(pedidosUser1)
            } else {
              setUser1([])
            }
          }

          if (users && users[2]) {
            if (totalOrder[i].driver === users[2].uid) {
              pedidosUser2.push(totalOrder[i])
            }
            if (pedidosUser2.length > 0) {
              setUser2(pedidosUser2)
            } else {
              setUser2([])
            }
          }
          if (users && users[3]) {
            if (totalOrder[i].driver === users[3].uid) {
              pedidosUser3.push(totalOrder[i])
            }
            if (pedidosUser3.length > 0) {
              setUser3(pedidosUser3)
            } else {
              setUser3([])
            }
          }

          if (users && users[4]) {
            if (totalOrder[i].driver === users[4].uid) {
              pedidosUser4.push(totalOrder[i])
            }
            if (pedidosUser4.length > 0) {
              setUser4(pedidosUser4)
            } else {
              setUser4([])
            }
          }

          if (users && users[5]) {
            if (totalOrder[i].driver === users[5].uid) {
              pedidosUser5.push(totalOrder[i])
            }
            if (pedidosUser5.length > 0) {
              setUser5(pedidosUser5)
            } else {
              setUser5([])
            }
          }

        }
      }
    }

    // console.log("pedidosUser0 ", pedidosUser0)
    // console.log("pedidosUser1 ", pedidosUser1)
    // console.log("pedidosUser2 ", pedidosUser2)
    // console.log("pedidosUser3 ", pedidosUser3)
    // console.log("pedidosUser4 ", pedidosUser4)

  }, [totalOrder, users])

  // console.log("User0", User0)
  // console.log("User1", User1)

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };


  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);


  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <AppBar position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      > */}
      <AppBar position="fixed" className={classes.appBar}>
        {/* <Toolbar style={{ backgroundColor: colors.HeaderPersonal }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={clsx(classes.menuButton, open && classes.hide)}
          > */}
        <Toolbar style={{ backgroundColor: colors.HeaderPersonal }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          {role === "admin" || role === "adminPpal" ?
            <List className={classes.root}>

              {users && users[0] ?
                <ListItem style={{ alignItems: 'flex-start' }} >
                  <ListItemText style={{ fontWeight: 'bold', fontSize: 10, color: "red", textAlign: "center" }}
                    primary={users[0].firstName + " " + users[0].lastName + "    " + (User0.length > 0 ? User0.length : "")}

                    secondary={
                      <React.Fragment>
                        <CssBaseline />
                          {/* <Container maxWidth="sm" fixed={true}> */}
                            {User0 && User0.length > 0 ?
                              User0.map((pedido, j) => (
                                <Typography key={j} style={{ fontSize: 11, color: "#00000", fontWeight: 'bold' }}
                                  variant="h1"
                                  component="h2"
                                  align="center"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  {pedido.restaurantName ? pedido.restaurantName : ""} {" - "}{pedido.shippingAddress && pedido.shippingAddress.area ? (pedido.shippingAddress.area).toUpperCase() : ""}{pedido.drop && pedido.drop.add ? (pedido.drop.add).toUpperCase() : ""}{" - "}{pedido.tiempoRecogida ? pedido.tiempoRecogida : ""}
                                  {/* {pedido.restaurantName ? pedido.restaurantName:""} {" - "}{pedido.shippingAddress ? (pedido.shippingAddress.area).toUpperCase() : (pedido.drop.add).toUpperCase()}{" - "}{moment(pedido.startTime).format('h:mm A ')} */}
                                </Typography>
                              ))
                              : null}
                          {/* </Container> */}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                : null}

              {users && users[1] ?
                <ListItem style={{ alignItems: 'flex-start' }} >
                  <ListItemText style={{ fontWeight: 'bold', fontSize: 10, color: "red", textAlign: "center" }}
                    primary={users[1].firstName + " " + users[1].lastName + "    " + (User1.length > 0 ? User1.length : "")}

                    secondary={
                      <React.Fragment>
                        <CssBaseline />
                        {/* <Container maxWidth="sm" fixed={true}> */}
                          {User1 && User1.length > 0 ?
                            User1.map((pedido, j) => (
                              <Typography key={j} style={{ fontSize: 11, color: "#00000", fontWeight: 'bold' }}
                                variant="h1"
                                component="h2"
                                align="center"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {pedido.restaurantName ? pedido.restaurantName : ""} {" - "}{pedido.shippingAddress && pedido.shippingAddress.area ? (pedido.shippingAddress.area).toUpperCase() : ""}{pedido.drop && pedido.drop.add ? (pedido.drop.add).toUpperCase() : ""}{" - "}{pedido.tiempoRecogida ? pedido.tiempoRecogida : ""}
                              </Typography>
                            ))
                            : null}
                        {/* </Container> */}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                : null}

              {users && users[2] ?
                <ListItem style={{ alignItems: 'flex-start' }} >
                  <ListItemText style={{ fontWeight: 'bold', fontSize: 10, color: "red", textAlign: "center" }}
                    primary={users[2].firstName + " " + users[2].lastName + "    " + (User2.length > 0 ? User2.length : "")}

                    secondary={
                      <React.Fragment>
                        <CssBaseline />
                        {/* <Container maxWidth="sm" fixed={true}> */}
                          {User2 && User2.length > 0 ?
                            User2.map((pedido, j) => (
                              <Typography key={j} style={{ fontSize: 11, color: "#00000", fontWeight: 'bold' }}
                                variant="h1"
                                component="h2"
                                align="center"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {pedido.restaurantName ? pedido.restaurantName : ""} {" - "}{pedido.shippingAddress && pedido.shippingAddress.area ? (pedido.shippingAddress.area).toUpperCase() : ""}{pedido.drop && pedido.drop.add ? (pedido.drop.add).toUpperCase() : ""}{" - "}{pedido.tiempoRecogida ? pedido.tiempoRecogida : ""}
                              </Typography>
                            ))
                            : null}
                        {/* </Container> */}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                : null}

              {users && users[3] ?
                <ListItem style={{ alignItems: 'flex-start' }} >
                  <ListItemText style={{ fontWeight: 'bold', fontSize: 10, color: "red", textAlign: "center" }}
                    primary={users[3].firstName + " " + users[3].lastName + "    " + (User3.length > 0 ? User3.length : "")}

                    secondary={
                      <React.Fragment>
                        <CssBaseline />
                        {/* <Container maxWidth="sm" fixed={true}> */}
                          {User3 && User3.length > 0 ?
                            User3.map((pedido, j) => (
                              <Typography key={j} style={{ fontSize: 11, color: "#00000", fontWeight: 'bold' }}
                                variant="h1"
                                component="h2"
                                align="center"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {pedido.restaurantName ? pedido.restaurantName : ""} {" - "}{pedido.shippingAddress && pedido.shippingAddress.area ? (pedido.shippingAddress.area).toUpperCase() : ""}{pedido.drop && pedido.drop.add ? (pedido.drop.add).toUpperCase() : ""}{" - "}{pedido.tiempoRecogida ? pedido.tiempoRecogida : ""}
                              </Typography>
                            ))
                            : null}
                        {/* </Container> */}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                : null}


              {users && users[4] ?
                <ListItem style={{ alignItems: 'flex-start' }} >
                  <ListItemText style={{ fontWeight: 'bold', fontSize: 10, color: "red", textAlign: "center" }}
                    primary={users[4].firstName + " " + users[4].lastName + "    " + (User4.length > 0 ? User4.length : "")}

                    secondary={
                      <React.Fragment>
                        <CssBaseline />
                        {/* <Container maxWidth="sm" fixed={true}> */}
                          {User4 && User4.length > 0 ?
                            User4.map((pedido, j) => (
                              <Typography key={j} style={{ fontSize: 11, color: "#00000", fontWeight: 'bold' }}
                                variant="h1"
                                component="h2"
                                align="center"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {pedido.restaurantName ? pedido.restaurantName : ""} {" - "}{pedido.shippingAddress && pedido.shippingAddress.area ? (pedido.shippingAddress.area).toUpperCase() : ""}{pedido.drop && pedido.drop.add ? (pedido.drop.add).toUpperCase() : ""}{" - "}{pedido.tiempoRecogida ? pedido.tiempoRecogida : ""}
                              </Typography>
                            ))
                            : null}
                        {/* </Container> */}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                : null}

              {users && users[5] ?
                <ListItem style={{ alignItems: 'flex-start' }} >
                  <ListItemText style={{ fontWeight: 'bold', fontSize: 10, color: "red", textAlign: "center" }}
                    primary={users[5].firstName + " " + users[5].lastName + "    " + (User5.length > 0 ? User5.length : "")}

                    secondary={
                      <React.Fragment>
                        <CssBaseline />
                        {/* <Container fixed={true}> */}
                          {User5 && User5.length > 0 ?
                            User5.map((pedido, j) => (
                              <Typography key={j} style={{ fontSize: 11, color: "#00000", fontWeight: 'bold' }}
                                variant="h1"
                                component="h2"
                                align="center"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {pedido.restaurantName ? pedido.restaurantName : ""} {" - "}{pedido.shippingAddress && pedido.shippingAddress.area ? (pedido.shippingAddress.area).toUpperCase() : ""}{pedido.drop && pedido.drop.add ? (pedido.drop.add).toUpperCase() : ""}{" - "}{pedido.tiempoRecogida ? pedido.tiempoRecogida : ""}
                              </Typography>
                            ))
                            : null}
                        {/* </Container> */}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                : null}

              <Divider variant="inset" component="li" />
            </List>
            : null}


          {role === 'rider' ?
            <Typography style={{ fontWeight: 'bold', fontSize: 18, color: "#000000" }} variant="h6" noWrap>
              {auth.info.profile && auth.info.profile.nameEstablishment ? auth.info.profile.nameEstablishment : ''}
            </Typography>
            : null}

        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <AppMenu />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <AppMenu />
          </Drawer>
        </Hidden>
      </nav>
      {/* <nav className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          className={classes.drawer}
          container={container}
          variant="temporary"
          anchor="left"
          open={open}
          // onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
        // ModalProps={{
        //   keepMounted: true, // Better open performance on mobile.
        // }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <AppMenu />
        </Drawer>
      </nav> */}
      {/* <main
        // className={classes.content}
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main> */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default ResponsiveDrawer;
