import React, { useState, useEffect, useRef } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";

export default function ConfirmamtionStatusImg(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    // const { t } = useTranslation();
    const [value, setValue] = useState(valueProp);
    // console.log("value", value)
    const radioGroupRef = useRef(null);
    //   const options = useSelector(state => state.cancelreasondata.simple);
    //   console.log("options " , options)


    const [file, setFile] = useState(valueProp);
    // console.log("file", file)
    const [error, setError] = useState(null);
    const [preview, setPreview] = useState()

    const format = ['image/png', 'image/jpeg', 'image/jpg'];

    const changeHandler = async (res) => {

        // const selected = URL.createObjectURL(res.target.files[0]);
        const selected = res.target.files[0];

        const objectUrl = URL.createObjectURL(selected)
        setPreview(objectUrl)

        if (selected && format.includes(selected.type)) {
            let reader = new FileReader();
            reader.onload = function (e) {
                setFile({ uploadedImage: e.target.result });
            };
            reader.readAsDataURL(res.target.files[0]);
            setFile(selected);
            setValue(selected);
            setError(null);
        } else {
            setFile(null);
            setError('choose only supported format (png/jpeg/jpg)');
        }
    }

    useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
        setFile(null);
        setValue(null);
        setPreview(null)
    };

    // const handleChange = (event) => {
    //     setValue(event.target.value);
    // };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            {/* <DialogTitle id="confirmation-dialog-title">{t('select_reason')}</DialogTitle> */}
            <DialogTitle id="confirmation-dialog-title">{'Cargar imágen'}</DialogTitle>
            <DialogContent dividers>

                <form>
                    <label>
                        <input className="label" file={value} type="file" onChange={changeHandler} placeholder="+" />
                        {/* <span>+</span> */}
                    </label>
                    <div className="output" value={value} ref={radioGroupRef}>
                        {/* {file && <div>{file.name}</div>} */}
                        {error && <div className="error" >{error}</div>}

                        <div>
                            <img src={preview} width="300px" alt="" />
                        </div>
                        {/* {file && <ProgressBar file={file} setFile={setFile} /> } */}
                        {/* {file && <div  file={file} setFile={setFile}></div>} */}
                    </div>
                </form>

                {/* <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {status.map((status) => (
            <FormControlLabel value={status.value} key={status.value} control={<Radio />} label={status.name} />
          ))}
        </RadioGroup> */}

            </DialogContent>
            <DialogActions>

                {file ?
                    <Button onClick={handleOk} color="primary">
                        {'Guardar imágen'}
                    </Button>
                    : null}
                <Button autoFocus onClick={handleCancel} color="primary">
                    {'Cancelar'}
                </Button>

            </DialogActions>
        </Dialog>
    );
}

ConfirmamtionStatusImg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};