import React, {
  useState,
  useEffect,
  useContext,
  useRef
} from 'react';
import MaterialTable
  // ,{ MTableToolbar }
  from 'material-table'
// import  MTableToolbar  from 'material-table';
// import Chip  from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmmationStatusDomi from 'components/ConfirmmationStatusDomi';
// import { Grid } from '@material-ui/core';
// import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import {
  Grid,
  Typography,
  Modal,
  Button,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  // Switch
} from '@material-ui/core';
import { FirebaseContext } from 'common';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
import UsersCombo from '../components/UsersCombo';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
// import moment from 'moment/min/moment-with-locales';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import CachedIcon from '@material-ui/icons/Cached';
// import SearchTimeIcon from '@material-ui/icons/Search';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useTheme } from "@material-ui/core/styles";
import moment from 'moment'
// import 'moment/min/locales'
import ConfirmationStatus from 'components/ConfirmmationStatus';
import ConfirmationStatusAdmin from 'components/ConfirmmationStatusAdmin';
import ConfirmationOrder from 'components/ConfirmationOrder';
import DeleteOrder from 'components/DeleteOrder';
import ConfirmationTime from 'components/ConfirmationTime';
// import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
// import Invoice from './Invoice';
// import useSound from "use-sound";
// import beep from "../assets/sounds/beep.mp3";
// import { element } from 'prop-types';
// import { FeedbackSharp } from '@material-ui/icons';
// import { element } from 'prop-types';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TimerIcon from '@material-ui/icons/Timer';
import RepeatIcon from '@material-ui/icons/Repeat';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const icons = {
  'paypal': require('../assets/img/payment-icons/paypal-logo.png').default,
  'braintree': require('../assets/img/payment-icons/braintree-logo.png').default,
  'stripe': require('../assets/img/payment-icons/stripe-logo.png').default,
  'paytm': require('../assets/img/payment-icons/paytm-logo.png').default,
  'payulatam': require('../assets/img/payment-icons/payulatam-logo.png').default,
  'flutterwave': require('../assets/img/payment-icons/flutterwave-logo.png').default,
  'paystack': require('../assets/img/payment-icons/paystack-logo.png').default,
  'securepay': require('../assets/img/payment-icons/securepay-logo.png').default,
  'payfast': require('../assets/img/payment-icons/payfast-logo.png').default,
  'liqpay': require('../assets/img/payment-icons/liqpay-logo.png').default,
  'culqi': require('../assets/img/payment-icons/culqi-logo.png').default,
  'mercadopago': require('../assets/img/payment-icons/mercadopago-logo.png').default
}

const OrdersHistory = (props) => {
  const {
    api,
    // appcat
  } = useContext(FirebaseContext);

  const { t } = useTranslation();
  const {
    cancelOrder,
    updateOrder,
    confirmOrder,
    confirmTime,
    confirmTimeAdmin,
    // updateState
    cancelBookingAdmin,
    deleteOrderAdmin,
    updateBooking,
    RequestPushMsg,
    RequestPushWp
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  // console.log("auth.info.uid",   auth.info.uid)
  const userdata = useSelector(state => state.usersdata);
  // console.log("userdata", userdata)
  const settings = useSelector(state => state.settingsdata.settings);
  const [role, setRole] = useState(null);
  const [uid, setUid] = useState(null);
  const [paymentModalStatus, setPaymentModalStatus] = useState(false);
  const providers = useSelector(state => state.paymentmethods.providers);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedProviderIndex, setSelectedProviderIndex] = useState(0);
  // const [data, setData] = useState([]);
  const [dataOrdenada, setDataOrdenada] = useState([]);
  // const [bookingOrdenada, setBookingOrdenada] = useState([]);
  // const [dataBooking, setDataBooking] = useState([]);
  const [dataOrder, setDataOrder] = useState([]);
  // console.log("data", data)
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmDomi, setOpenConfirmDomi] = useState(false);
  const [openConfirmDomiAdmin, setOpenConfirmDomiAdmin] = useState(false);
  const [openConfirmOrder, setOpenConfirmOrder] = useState(false);
  const [openConfirmDeleteOrder, setOpenConfirmDeleteOrder] = useState(false);
  const [openConfirmTime, setOpenConfirmTime] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState();
  // const bookinglistdata = useSelector(state => state.bookinglistdata);
  const orderslistdata = useSelector(state => state.orderslistdata);
  // console.log("orderslistdata.bookings" , orderslistdata.bookings)

  // const whdata = useSelector(state => state.whdata.notifications);
  // const [dataWh, setDataWh] = useState([]);
  // const [dataWhPrueba, setDataWhPrueba] = useState([]);
  // console.log("whdata", whdata)
  // console.log("whdata" , whdata.changes[0])

  const usersappdata = useSelector(state => state.usersappdata);
  const [dataUser, setDataUser] = useState([]);
  const [dataUserActive, setDataUserActive] = useState([]);

  const [users, setUsers] = useState(null);
  const [userCombo, setUserCombo] = useState(null);
  const rootRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const classes = useStyles();

  // useEffect(() => {
  //   if (whdata) {
  //     let messages = [];
  //     for (let i = 0; i < whdata.length; i++) {
  //       if (whdata[i].statuses) {
  //         messages.push(whdata[i].statuses[0])
  //       }
  //       setDataWh(messages);
  //     }
  //   } else {
  //     setDataWh([]);
  //   }
  // }, [whdata]);
  // console.log("dataWh", dataWh)

  // useEffect(() => {
  //   if (whdata) {
  //     let messagesPrueba = [];
  //     for (let i = 0; i < whdata.length; i++) {
  //       if (whdata[i].statuses && whdata[i].statuses[0].id === "wamid.HBgMNTczMTI4MzkwOTAyFQIAERgSRDNEMjI3OTEwQTJCODJGM0I5AA==") {
  //         messagesPrueba.push(whdata[i].statuses[0])
  //       }
  //       setDataWhPrueba(messagesPrueba);
  //     }
  //   } else {
  //     setDataWhPrueba([]);
  //   }
  // }, [whdata]);
  // console.log("dataWhPrueba", dataWhPrueba)

  const columns = [
    // { title: "#", render: rowData => rowData.tableData.id + 1 },
    {
      title: "Establecimiento", field: 'restaurantName', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 13, paddingLeft: 0 }}>
        {rowData.restaurantName ? rowData.restaurantName :
          rowData.booking_type_ride === false && rowData.booking_type_admin === true && !rowData.addressPickup ? "ADMIN ESTABLECIMIENTO" :
            rowData.booking_type_admin === false ? rowData.pickupAddress :
              rowData.booking_type_admin === true && rowData.addressPickup ? "ADMIN INICIO" :
                rowData.booking_type_rider === true && !rowData.addressPickup ? "ADMIN PERFIL" :
                  "ADMIN ESTABLECIMIENTO"
        }</span>
    },
    { title: "Pedido", field: 'orderId', width: null, cellStyle: { paddingLeft: 0, whiteSpace: 'nowrap' }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>{rowData.orderId}</span> },
    { title: "Estado", field: 'status', cellStyle: { paddingLeft: 0 },
     headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 13, color: rowData.status === "Pendiente" ? '#EF9701' : rowData.status === "Recibido" ? '#0099C5' : rowData.status === "Recogido" ? '#ffd500c7' : rowData.status === "Confirmado" ? '#f15f86' : rowData.status === "Entregado" ? 'green' : rowData.status === "Pagado" ? 'green' : rowData.status === "CANCELLED" ? 'red' : rowData.status === "Cancelado" ? 'red' : rowData.status === "NEW" ? '#EF9701' : rowData.status === "ACCEPTED" ? '#0099C5' : rowData.status === "STARTED" ? '#ffd500c7' : rowData.status === "REACHED" ? '#79AA00' : rowData.status === "ARRIVED" ? 'green' : rowData.status === "PAID" ? 'green' : rowData.status === "COMPLETE" ? '65079B' : null }}>{rowData.status === "Pendiente" ? 'PENDIENTE' : rowData.status === "NEW" ? 'PENDIENTE' : rowData.status === "ACCEPTED" ? 'RECIBIDO' : rowData.status === "STARTED" ? 'RECOGIDO' : rowData.status === "ARRIVED" ? 'ENTREGADO' : rowData.status === "REACHED" ? 'ENTREGADO' : rowData.status === "PAID" ? 'PAGADO' : rowData.status === "COMPLETE" ? 'COMPLETO' : rowData.status === "Recibido" ? 'RECIBIDO' : rowData.status === "Recogido" ? 'RECOGIDO' : rowData.status === "Confirmado" ? 'CONFIRMADO' : rowData.status === "Entregado" ? 'ENTREGADO' : rowData.status === "Pagado" ? 'PAGADO' : rowData.status === "CANCELLED" ? 'CANCELADO' : rowData.status === "Cancelado" ? 'CANCELADO' : null}</span> },
    // {
    //   title: "WhatsApp ",
    //   field: 'statusWhatsApp',
    //   width: null,
    //   cellStyle: { paddingLeft: 0, whiteSpace: 'nowrap' },
    //   headerStyle: { paddingLeft: 0 },
    //   render: rowData => (
    //     <div>
    //       {rowData.statusWhatsApp ? rowData.statusWhatsApp.map((type, index) => (
    //         <li style={{ fontWeight: 'bold', color: "green", fontSize: 13 }} key={index}>
    //           {type.status === "sent" ? "Enviado" : type.status === "read" ? "Leído" : type.status === "delivered" ? "Entregado" : ""}
    //           {/* -{type.timestamp?moment(type.timestamp * 1000).format("h:mm a") : ""} */}
    //         </li>
    //       )) : " "}
    //     </div>
    //   )
    // },
    { title: "WhatsApp", field: 'publicUrl', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 14, color: 'green' }}>{rowData.publicUrl ? "Enviado" : ""}</span> },
    // {
    //   title: "WhatsApp",
    //   field: 'status',
    //   cellStyle: { paddingLeft: 0, textAlign: 'center', fontSize: 16 },
    //   headerStyle: { paddingLeft: 0, textAlign: 'center' },
    //   render: rowData => <span style={{ fontWeight: 'bold', color: "green" }}>{rowData.status === "Confirmado" ? " √ √ √ √ " : null} </span>
    // },
    { title: "Fecha", field: 'fecha', width: 100, cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => rowData.fecha ? rowData.fecha : null },
    // // { title: "Ciudad", field: 'city', render: rowData => rowData.city ? rowData.city : "ACTUALIZAR" },
    // { title: "Tipo", field: 'cart', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 16, color: rowData.cart ? 'green' : !rowData.cart ? 'red' : null }}>{rowData.cart ? 'USUARIO' : !rowData.cart ? 'DOMICILIO' : "null"}</span> },
    { title: "Llevo", field: 'llevo', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 13, color: rowData.llevo === true || rowData.bookingDate ? 'green' : rowData.llevo === false || !rowData.bookingDate ? 'red' : null }}>{rowData.llevo === true || rowData.bookingDate ? 'SI' : rowData.llevo === false || !rowData.bookingDate ? 'NO' : null}</span> },
    { title: "Tiempo", field: 'tiempoRecogida', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>{rowData.tiempoRecogida ? rowData.tiempoRecogida : ""}</span> },
    // { title: "Recoger", field: 'tiempoRecogida', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 14, color: 'black' }}>{rowData.tiempoRecogida && rowData.cart ? rowData.tiempoRecogida : rowData.timeDelivery ? moment(rowData.bookingDateUnix).add(rowData.timeDelivery, 'minutes').format('h:mm A ') : !rowData.tiempoRecogida || !rowData.timeDelivery ? " " : null}</span> },
    // // { title: "Establecimiento", field: 'restaurantName' },
    // { title: "Salida", field: 'cart', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 14, color: 'black' }}>{!rowData.cart ? rowData.pickupAddress : " "}</span> },
    // { title: "LLegada", field: 'cart', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 14, color: 'black' }}>{!rowData.cart ? rowData.dropAddress : rowData.cart ? (rowData.shippingAddress.area).toUpperCase() : " "}</span> },
    { title: "Barrio", field: 'shippingAddress.area', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>{(rowData.shippingAddress && rowData.shippingAddress.area ? (rowData.shippingAddress.area).toUpperCase() : " ")}</span> },
    // { title: "Ubicación", field: 'ubicacion', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 13, color: rowData.ubicacion === "SI" ? 'green' : rowData.ubicacion === "NO" ? 'red' : null }}>{rowData.ubicacion}</span> },
    { title: 'Repartidor', field: 'DomicilioEncargado.nombreDomicilio', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    // { title: "Repartidor", field: 'DomicilioEncargado ', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 14, color: 'black' }}>{(rowData.DomicilioEncargado.nombreDomicilio).toUpperCase()}</span> },
    { title: "Subtotal", field: 'subTotal', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 14, color: 'black' }}>{rowData.subTotal ? "$" + (rowData.subTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : rowData.estimate ? "$" + (rowData.estimate).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: "Método", field: 'Metodo', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 14, color: rowData.Metodo === 'EFECTIVO' ? 'black' : 'red' }}>{rowData.Metodo}</span> },
    { title: "Envío", field: 'envio', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 14, color: 'black' }}>{rowData.envio ? "$" + (rowData.envio).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: 'Recibido', field: 'accepted_time', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Confirmado', field: 'confirmed_time', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Recogido', field: 'trip_start_time', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Entregado', field: 'trip_end_time', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Cancelado', field: 'cancelled_Time', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'Motivo', field: 'cancelled_reason', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: "Calificación", field: 'review', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontSize: 14 }}>{rowData.review ? "App: " + rowData.review.rating : ""}{rowData.review && rowData.review.ratingDelivery ? " Repartidor: " + rowData.review.ratingDelivery : ""}{rowData.review && rowData.review.ratingDish ? " Producto: " + rowData.review.ratingDish : ""}</span> },
    { title: "Comentario", field: 'review', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontSize: 14 }}>{rowData.review ? rowData.review.comment : ""}</span> },
    { title: "Cupón", field: 'valorCupon', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontWeight: 'bold', fontSize: 14, color: 'black' }}>{rowData.valorCupon ? "$" + (rowData.valorCupon).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
    { title: "Pdf", field: 'publicUrl', width: null, cellStyle: { paddingLeft: 0, whiteSpace: 'nowrap' }, headerStyle: { paddingLeft: 0 }, render: rowData => <span style={{ fontSize: 13, color: 'black' }}>{rowData.publicUrl}</span> },
    // { title: 'WhatsAppId', field: 'idWhatsApp', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'orderId', field: 'id', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'UserId', field: 'userId', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 } },
    { title: 'UserEmail', field: 'userDetails', cellStyle: { paddingLeft: 0 }, headerStyle: { paddingLeft: 0 }, render: rowData => <span>{rowData.userDetails ? rowData.userDetails.email : ""}</span> },
  ];

  const history = useHistory();

  // const [play] = useSound(beep, { interrupt: true });


  const onConfirmOrder = (value) => {
    // console.log("onConfirmOrder value " ,value)
    if (value) {
      dispatch(confirmOrder({
        superCategoria: value,
        booking: selectedBooking,
        // cancelledBy: role
      }));
    }
    setOpenConfirmOrder(false);
  }

  const onDeleteOrder = (value) => {
    // console.log("onDeleteOrder value " ,value)
    if (value) {
      dispatch(deleteOrderAdmin({
        booking: selectedBooking,
      }));
    }
    setOpenConfirmDeleteOrder(false);
  }

  useEffect(() => {
    if (usersappdata.users) {
      setDataUser(usersappdata.users.filter(user => user.city === 'CARTAGO'));
      setDataUserActive(usersappdata.users.filter(user => user.storeId === uid)[0]);
    } else {
      setDataUser([]);
    }
  }, [usersappdata.users, uid]);
  // console.log("dataUser", dataUser)
  // console.log("dataUserActive.llevamos", dataUserActive.llevamos)


  useEffect(() => {
    if (orderslistdata.bookings) {
      if (role === "admin" || role === 'adminPpal') {
        setDataOrder(orderslistdata.bookings);
      } if (role === "rider") {
        setDataOrder(orderslistdata.bookings.filter(bookings => bookings.restId === uid));
      }
    } else {
      setDataOrder([]);
    }
  }, [orderslistdata.bookings, uid, role]);
  // console.log("dataOrder", dataOrder)


  useEffect(() => {
    if (dataOrder) {
      let dataorder = [];
      // const renderStatus = {};
      let DomicilioEncargado = {
        nombreDomicilio: "Sin Repartidor"
      }

      for (let j = 0; j < dataOrder.length; j++) {
        const order = dataOrder[j];
        // const whStatus = [];

        // for (let k = 0; k < dataWh.length; k++) {
        //   const userIdBooking = dataWh[k].id;
        //   whStatus.push({
        //     uniqueKey: userIdBooking,
        //     mesaggesStatus: dataWh[k],
        //   })
        // }

        // whStatus.sort((a, b) => (b.timestamp > a.timestamp) ? 1 : ((a.timestamp > b.timestamp) ? -1 : 0))
        // console.log("whStatus", whStatus)
        // let values = [];

        // for (let h = 0; h < whStatus.length; h++) {
        //   const uniqueKey = whStatus[h].uniqueKey;
        //   if (order.idWhatsApp === uniqueKey) {
        //     values.push(whStatus[h].mesaggesStatus);
        //     renderStatus[uniqueKey] = {};
        //     renderStatus[uniqueKey] = values.sort((a, b) => (b.timestamp > a.timestamp) ? 1 : ((a.timestamp > b.timestamp) ? -1 : 0))
        //     dataOrder[j].statusWhatsApp = renderStatus[uniqueKey];
        //   }
        // }

        for (let i = 0; i < dataUser.length; i++) {
          const user = dataUser[i];
          if (user.storeId === order.restId || user.id === order.restId) {
            dataOrder[j].fecha = moment(dataOrder[j].createdAt).format(" MMM DD , h:mm a");
            dataOrder[j].llevo = dataUser[i].llevamos;
            if (!dataOrder[j].DomicilioEncargado) {
              dataOrder[j].DomicilioEncargado = DomicilioEncargado;
            }
            // if (dataOrder[j].shippingAddress.locationInfo && dataOrder[j].shippingAddress.locationInfo.lat > 0) {
            //   dataOrder[j].ubicacion = "SI";
            // } else {
            //   dataOrder[j].ubicacion = "NO";
            // }
            if (dataOrder[j].isFreeDelivery) {
              dataOrder[j].envio = "GRATIS";
            }
            if (dataOrder[j].isVariableDelivery && !dataOrder[j].nuevoValor) {
              dataOrder[j].envio = "VARIABLE";
            }
            if (!dataOrder[j].isVariableDelivery &&
              !dataOrder[j].isFreeDelivery &&
              !dataOrder[j].isTaxiDelivery && !dataOrder[j].nuevoValor) {
              dataOrder[j].envio = dataOrder[j].deliveryCharge;
            }
            if (!dataOrder[j].isVariableDelivery &&
              !dataOrder[j].isFreeDelivery &&
              !dataOrder[j].isTaxiDelivery && dataOrder[j].nuevoValor) {
              dataOrder[j].envio = dataOrder[j].nuevoValor;
            }
            if (dataOrder[j].isVariableDelivery &&
              !dataOrder[j].isFreeDelivery &&
              !dataOrder[j].isTaxiDelivery && dataOrder[j].nuevoValor) {
              dataOrder[j].envio = dataOrder[j].nuevoValor;
            }



            if (dataOrder[j].paymentTypeTodo === 'DATAFONO') {
              dataOrder[j].Metodo = dataOrder[j].paymentTypeTodo;
            }
            if (dataOrder[j].paymentType === 'DATAFONO') {
              dataOrder[j].Metodo = dataOrder[j].paymentType;
            }
            if (dataOrder[j].paymentTypeTodo === 'TRANSFERENCIA') {
              dataOrder[j].Metodo = dataOrder[j].paymentTypeTodo;
            }
            if (dataOrder[j].paymentTypeTrans === 'TRANSFERENCIA') {
              dataOrder[j].Metodo = dataOrder[j].paymentTypeTrans;
            }
            if (dataOrder[j].paymentType === 'EFECTIVO' &&
              dataOrder[j].paymentTypeTrans === 'EFECTIVO' &&
              dataOrder[j].paymentTypeTodo === 'EFECTIVO'
            ) {
              dataOrder[j].Metodo = dataOrder[j].paymentType;
            }
          }

        }
        dataorder.push(order)
      }
      setDataOrdenada(dataorder);
    }
  }, [dataOrder, dataUser]);

  // console.log("dataOrdenada", dataOrdenada)
  // console.log("dataOrdenada.length" , dataOrdenada.length)

  // useEffect(() => {
  //   if (bookingOrdenada.length >= 1 && dataOrdenada.length >= 1) {
  //     setData([...bookingOrdenada, ...dataOrdenada].sort((a, b) => b.createdAt > a.createdAt ? 1 : -1));
  //     // console.log("1" , 1)
  //   } if (bookingOrdenada.length === 0 && dataOrdenada.length >= 1) {
  //     setData((dataOrdenada).sort((a, b) => b.createdAt > a.createdAt ? 1 : -1));
  //     // console.log("2" , 2)
  //   } if (bookingOrdenada.length >= 1 && dataOrdenada.length === 0) {
  //     setData((bookingOrdenada).sort((a, b) => b.createdAt > a.createdAt ? 1 : -1));
  //     // console.log("3" , 3)
  //   }
  // }, [bookingOrdenada, dataOrdenada]);
  // // console.log("data" , data)
  // // console.log("data.length" , data.length)

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);
  // console.log("role ", role)
  // restId

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setUid(auth.info.uid);
    }
  }, [auth.info]);
  // console.log("uid ", uid)

  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === 'driver' && user.driverActiveStatus && user.approved && !user.queue) {
          arr.push({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'mobile': user.mobile,
            'email': user.email,
            'uid': user.id,
            'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
            'pushToken': user.pushToken
          });
        }
      }
      setUsers(arr);
    }
  }, [userdata.users, settings.AllowCriticalEditsAdmin]);
  // console.log("users ", users)

  // const assignDriverOrder = () => {
  //   let booking = data[rowIndex];


  // }

  const assignDriver = () => {
    const booking = dataOrdenada[rowIndex];

       // const recipient = `57${document.data().telefono}.`;
       const recipient = `573142870770`;
       const restaurant = "Borrico";
       const link = "https://storage.googleapis.com/llevoacasaprueba2.appspot.com/orders/cee0caSXZBweWRQBjy4O/cee0caSXZBweWRQBjy4O.pdf";
       const numberOrder = "25478";
       const filename = `Pedido No. ${numberOrder}`;
       const payload = `${numberOrder}-${"eventOrder.keyOrder"}`;

    // console.log("booking ", booking)
    if (!booking.cart) {
      if (booking['driver_name']) {
        booking['driver_name'] = `${userCombo.firstName}${" "}${userCombo.lastName}`;
      } else {
        booking['driver_name'] = {};
        booking['driver_name'] = `${userCombo.firstName}${" "}${userCombo.lastName}`;
      }
      if (booking['requestedDrivers']) {
        booking['requestedDrivers'][userCombo.uid] = true;
      } else {
        booking['requestedDrivers'] = {};
        booking['requestedDrivers'][userCombo.uid] = true;
      }
      dispatch(updateBooking(booking));
      // RequestPushMsg(userCombo.pushToken, t('notification_title'), t('new_booking_notification'));
      RequestPushMsg(userCombo.pushToken, `PEDIDO EN ${booking.pickup.add} `, `Lugar de entrega:${booking.drop.add} `);
      
      RequestPushWp("new_order_delivery", link, recipient, filename, restaurant, payload, numberOrder);

      setUserCombo(null);
      handleClose();
      alert("Repartidor asignado correctamente.");
    } else {
      if (booking['DomicilioEncargado']) {
        booking['DomicilioEncargado']["nombreDomicilio"] = `${userCombo.firstName}${" "}${userCombo.lastName}`;
      } else {
        booking['DomicilioEncargado'] = {};
        booking['DomicilioEncargado']["nombreDomicilio"] = `${userCombo.firstName}${" "}${userCombo.lastName}`;
      }
      if (booking['requestedDrivers']) {
        // booking['requestedDrivers'][userCombo.uid] = true;
        booking['requestedDrivers']["driverId"] = userCombo.uid;
      } else {
        booking['requestedDrivers'] = {};
        // booking['requestedDrivers'][userCombo.uid] = true;
        booking['requestedDrivers']["driverId"] = userCombo.uid;
      }
      dispatch(updateOrder(booking));
      // RequestPushMsg(userCombo.pushToken, t('notification_title'), t('new_booking_notification'));
      RequestPushMsg(userCombo.pushToken, `PEDIDO EN ${booking.restaurantName}`, `BARRIO ➡️ ${(booking.shippingAddress.area).toUpperCase()} `);

      RequestPushWp("new_order_delivery", link, recipient, filename, restaurant, payload, numberOrder);

      setUserCombo(null);
      handleClose();
      alert("Repartidor asignado correctamente.");
    }



  }
  const onConfirmCloseDomi = (value) => {
    if (value) {
      //  console.log("value " ,value)
      dispatch(cancelOrder({
        status: value,
        // reason: 'cambio de estado por el Admin',
        booking: selectedBooking,
        // cancelledBy: role
      }));
    }
    if (role === "admin" || role === 'adminPpal') {
      setOpenConfirmDomi(false);
    } if (role === "rider") {
      setOpenConfirmDomiAdmin(false);
    }
  }

  // const onConfirmCloseDomiAdmin = (value) => {
  //   if (value) {
  //     //  console.log("value " ,value)
  //     dispatch(cancelOrder({
  //       status: value,
  //       // reason: 'cambio de estado por el Admin',
  //       booking: selectedBooking,
  //       // cancelledBy: role
  //     }));
  //   }
  //   setOpenConfirmDomiAdmin(false);
  // }

  // const onConfirmClose = (value) => {
  //   // console.log("value " ,value)
  //   if (value) {
  //     dispatch(cancelOrder({
  //       status: value,
  //       booking: selectedBooking,
  //       // cancelledBy: role
  //     }));
  //   }
  //   setOpenConfirm(false);
  // }

  const onConfirmClose = (value) => {
    if (value) {
      //  console.log("value " ,value)
      dispatch(cancelBookingAdmin({
        status: value,
        reason: 'cambio de estado por el Admin',
        booking: selectedBooking,
        cancelledBy: role
      }));
    }
    setOpenConfirm(false);
  }

  const onConfirmTime = (value) => {
    // console.log("onConfirmTime value " ,value)
    let time = Date.now();
    if (value) {
      if (role === 'admin' || role === 'adminPpal') {
        dispatch(confirmTime({
          // tiempoRecogida:value,
          tiempoRecogida: moment(time).add(value, 'minutes').format('h:mm A '),
          booking: selectedBooking,
          // cancelledBy: role
        }));
      }
      if (role === 'rider') {
        dispatch(confirmTimeAdmin({
          // tiempoRecogida:value,
          tiempoRecogida: moment(time).add(value, 'minutes').format('h:mm A '),
          booking: selectedBooking,
          // cancelledBy: role
        }));
      }

    }
    setOpenConfirmTime(false);
  }

  const handleChange = (e) => {
    if (e.target.name === 'selectedProviderIndex') {
      setSelectedProviderIndex(parseInt(e.target.value));
      setSelectedProvider(providers[parseInt(e.target.value)]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handlePaymentModalClose = (e) => {
    setTimeout(() => {
      setPaymentModalStatus(false);
    }, 1500)
  }

  useEffect(() => {
    if (providers) {
      setSelectedProvider(providers[0]);
    }
  }, [providers]);

  const theme = useTheme()

  // const processPayment = (rowData) =>{
  //   const curBooking = rowData;
  //   const paymentPacket = { 
  //     appcat: appcat,
  //     payment_mode: 'card',
  //     customer_paid: (parseFloat(curBooking.trip_cost) - parseFloat(curBooking.discount)).toFixed(2),
  //     cardPaymentAmount:  curBooking.payableAmount?curBooking.payableAmount:curBooking.trip_cost,
  //     discount: curBooking.discount? curBooking.discount:0,
  //     usedWalletMoney: curBooking.usedWalletMoney?curBooking.usedWalletMoney:0,
  //     cashPaymentAmount: 0,
  //     promo_applied: curBooking.promo_applied?curBooking.promo_applied:false,
  //     promo_details: curBooking.promo_details?curBooking.promo_details:null,
  //     payableAmount: curBooking.payableAmount?curBooking.payableAmount:curBooking.trip_cost
  //   };
  //   curBooking.paymentPacket = paymentPacket;
  //   dispatch(updateBooking(curBooking));
  //   setSelectedBooking(curBooking);
  // }

  return (
    orderslistdata.loading ? <CircularLoading /> :
      <div>
        <MaterialTable
          title={'Pedidos Generales'}
          columns={columns}
          data={dataOrdenada}

          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} of {count}'
            },
            toolbar: {
              nRowsSelected: '{0} row(s) selected',
              searchPlaceholder: 'Buscar Pedido'
            },
            header: {
              actions: 'Acciones'
            },
            body: {
              emptyDataSourceMessage: 'No records to display',
              filterRow: {
                filterTooltip: 'Filter'
              }
            }
          }}
          options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
            exportAllData: true,
            // toolbar: false,
            // paging:true,
            pageSize: 20,
            // emptyRowsWhenPaging: false,
            // pageSizeOptions:[100,200,300,400,500],
            showTitle: true,
            search: true,
            searchFieldAlignment: "left",
            justifyContent: "center",
            rowStyle: {
              height: "20px",
              maxHeight: 20,
              padding: 0
            },
            headerStyle: {
              // backgroundColor: theme.palette.action.hover,
              backgroundColor: "#EFE9E9",
              color: theme.palette.common.black,
              fontWeight: 'bold',
              textAlign: 'center',
              flexDirection: 'row',
              position: 'sticky',
              top: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            maxBodyHeight: '900px',
          }}

          actions={[

            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <DeleteIcon />
              </div>,
              disabled: role === 'adminPpal' ? false : true,
              onClick: (event, rowData) => {
                if (settings.AllowCriticalEditsAdmin) {
                  if (role === 'adminPpal') {
                    setSelectedBooking(rowData);
                    setOpenConfirmDeleteOrder(true);
                  } else {
                    setTimeout(() => {
                      dispatch(deleteOrderAdmin({
                        booking: rowData
                      }));
                    }, 1500);
                  }
                } else {
                  alert(t('demo_mode'));
                }
              }
            }),

            rowData => ({
              icon: () =>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <LocationOnIcon />
                  {/* <Typography variant="subtitle2">{'Ubicación'}</Typography> */}
                </div>,
              disabled: (rowData.shippingAddress.locationInfo && rowData.shippingAddress.locationInfo.lat && rowData.shippingAddress.locationInfo.lat > 0) ? false : true,
              onClick: (event, rowData) => {
                window.open(`https://maps.google.com/maps?q=${rowData.shippingAddress.locationInfo.lat},${rowData.shippingAddress.locationInfo.lng}&hl=es;z=14&amp;output=embed`, '_blank').focus()
              }
            }),


            rowData => ({
              icon: () =>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <VisibilityIcon />
                  {/* <Typography variant="subtitle2">{'Ver'}</Typography> */}
                </div>,
              // disabled: rowData.status==='NEW' || rowData.status==='ACCEPTED' || rowData.status==='PAYMENT_PENDING'? false:true,
              // disabled: rowData.cart ? false : true,
              onClick: (event, rowData) => {
                // history.push("/Invoice");
                history.push({
                  pathname: '/Invoice',
                  // search: '?the=search',
                  state: rowData
                  // state: { some: 'state' }
                })
              }
            }),

            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <TimerIcon />
                {/* <Typography variant="subtitle2">{'Tiempo'}</Typography> */}
              </div>,
              // disabled: rowData.status==='NEW' || rowData.status==='ACCEPTED' || rowData.status==='PAYMENT_PENDING'? false:true,
              disabled: (role === 'rider' && dataUserActive.llevamos === true) || role === 'admin' || role === 'adminPpal' ? false : true,
              onClick: (event, rowData) => {
                if (settings.AllowCriticalEditsAdmin) {
                  if (role === 'rider' || role === 'admin' || role === 'adminPpal') {
                    // if (rowData.status !== 'Cancelado') {
                    // if(rowData.status==='NEW' || rowData.status==='ACCEPTED'){
                    setSelectedBooking(rowData);
                    setOpenConfirmTime(true);
                  } else {
                    setTimeout(() => {
                      dispatch(confirmTime({
                        reason: t('cancelled_incomplete_booking'),
                        booking: rowData
                      }));
                    }, 1500);
                  }
                } else {
                  alert(t('demo_mode'));
                }
              }
            }),

            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <RepeatIcon />
                {/* <Typography variant="subtitle2">{'Estado'}</Typography> */}
              </div>,
              // disabled: rowData.status==='NEW' || rowData.status==='ACCEPTED' || rowData.status==='PAYMENT_PENDING'? false:true,
              // disabled: role === 'admin' ? false  :  true,
              disabled: (role === 'rider' && dataUserActive.llevamos === false) || role === 'admin' || role === 'adminPpal' ? false : true,
              onClick: (event, rowData) => {
                if (settings.AllowCriticalEditsAdmin) {
                  if (role === 'admin' || role === 'rider' || role === 'adminPpal') {
                    // if(rowData.status==='NEW' || rowData.status==='ACCEPTED'){
                    // if (rowData.cart) {
                    setSelectedBooking(rowData);
                    if (role === 'admin' || role === 'adminPpal') {
                      setOpenConfirmDomi(true);
                    }
                    if (role === 'rider') {
                      setOpenConfirmDomiAdmin(true);
                    }

                  }

                  else {
                    setTimeout(() => {
                      // if (rowData.cart) {
                      dispatch(cancelOrder({
                        // reason: t('cancelled_incomplete_booking'),
                        booking: rowData
                      }));

                    }, 1500);
                  }
                } else {
                  alert(t('demo_mode'));
                }
              }
            }),
            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <PersonAddIcon />
                {/* <Typography variant="subtitle2">{'Asignar'}</Typography> */}
              </div>,
              disabled: (role === 'admin' || role === 'adminPpal') ? false : true,
              // disabled: rowData.status === 'Pendiente' ? true : false,
              onClick: (event, rowData) => {
                setOpen(true)
                setRowIndex(rowData.tableData.id);
              }
            }),
            // rowData => ({
            //   icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
            //       <PaymentIcon />
            //       <Typography variant="subtitle2">{t('paynow_button')}</Typography>
            //   </div>,
            //   disabled: (rowData.status==='PENDING' && role==='rider')? false:true,
            //   onClick: (event, rowData) => {
            //     processPayment(rowData);
            //     setPaymentModalStatus(true);
            //   }
            // })
          ]}
        />
        {selectedBooking && selectedBooking.status === 'PENDING' && role === 'rider' ?
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={paymentModalStatus}
            onClose={handlePaymentModalClose}
            className={classes.modal}
            container={() => rootRef.current}
          >
            <Grid container spacing={2} className={classes.paper}>
              {providers && selectedProvider && selectedBooking ?
                <form action={selectedProvider.link} method="POST">
                  <input type='hidden' name='order_id' value={selectedBooking.id} />
                  <input type='hidden' name='amount' value={selectedBooking.paymentPacket.payableAmount} />
                  <input type='hidden' name='currency' value={settings.code} />
                  <input type='hidden' name='product_name' value={t('bookingPayment')} />
                  <input type='hidden' name='first_name' value={auth.info.profile.firstName} />
                  <input type='hidden' name='last_name' value={auth.info.profile.lastName} />
                  <input type='hidden' name='quantity' value={1} />
                  <input type='hidden' name='cust_id' value={selectedBooking.customer} />
                  <input type='hidden' name='mobile_no' value={selectedBooking.customer_contact} />
                  <input type='hidden' name='email' value={selectedBooking.customer_email} />
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{t('payment')}</FormLabel>
                      <RadioGroup name="selectedProviderIndex" value={selectedProviderIndex} onChange={handleChange}>
                        {providers.map((provider, index) =>
                          <FormControlLabel key={provider.name} value={index} control={<Radio />} label={<img style={{ height: 24, margin: 7 }} src={icons[provider.name]} alt={provider.name} />} />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button onClick={handlePaymentModalClose} variant="contained" color="primary">
                      {t('cancel')}
                    </Button>
                    <Button variant="contained" color="primary" type="submit" style={{ marginLeft: 10 }} onClick={handlePaymentModalClose}>
                      {t('paynow_button')}
                    </Button>
                  </Grid>
                </form>
                : null}
            </Grid>
          </Modal>
          : null}
        <ConfirmationTime
          open={openConfirmTime}
          onClose={onConfirmTime}
          value={''}
        />
        <ConfirmationOrder
          open={openConfirmOrder}
          onClose={onConfirmOrder}
          value={''}
        />
        <DeleteOrder
          open={openConfirmDeleteOrder}
          onClose={onDeleteOrder}
          value={''}
        />
        <ConfirmationStatus
          open={openConfirmDomi}
          onClose={onConfirmCloseDomi}
          value={''}
        />
        <ConfirmationStatusAdmin
          open={openConfirmDomiAdmin}
          onClose={onConfirmCloseDomi}
          value={''}
        />
        <ConfirmmationStatusDomi
          open={openConfirm}
          onClose={onConfirmClose}
          value={''}
        />
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          onClose={handleClose}
          open={open}
          className={classes.modal}
          container={() => rootRef.current}
        >
          <div className={classes.paper}>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <Typography component="h1" variant="h5" className={classes.title}>
                  Seleccione repartidor
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {users ?
                  <UsersCombo
                    className={classes.items}
                    placeholder={t('select_user')}
                    users={users}
                    value={userCombo}
                    onChange={(event, newValue) => {
                      setUserCombo(newValue);
                    }}
                  />
                  : null}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button onClick={handleClose} variant="contained" color="primary">
                  Cancelar
                </Button>

                <Button
                  onClick={assignDriver}
                  variant="contained" color="primary" style={{ marginLeft: 10 }}>
                  Asignar
                </Button>

              </Grid>
            </Grid>
          </div>
        </Modal>
      </div>

  );
}

export default OrdersHistory;




























// import React, { useState, useEffect, useContext } from 'react';
// import MaterialTable from 'material-table';
// import CircularLoading from "../components/CircularLoading";
// import { useSelector, useDispatch } from "react-redux";
// import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
// // import {
// //   features,
// // //   dateStyle,
// //   language
// // } from 'config';
// import { FirebaseContext } from 'common';
// import { useTheme } from "@material-ui/core/styles";
// import { useTranslation } from "react-i18next";



// const BookingHistory = () => {
//   const { api } = useContext(FirebaseContext);
//   const {
//     cancelBooking
//   } = api;
//   const dispatch = useDispatch();
//   const auth = useSelector(state => state.auth);
//   const [role, setRole] = useState(null);

//   const columns = [
//     { title: "#", field: 'orderId' },
//     { title: "Ciudad", field: 'city', render: rowData => rowData.city ? rowData.city  : "ACTUALIZAR" },
//     { title: "Asignar", field: 'asignar', render: rowData => rowData.asignar === true  ? "Si" : "No" },
//     { title: "Tiempo", field: 'tiempoRecogida', render: rowData => rowData.tiempoRecogida &&  rowData.cart  ? rowData.tiempoRecogida : rowData.recojida &&  !rowData.cart  ? rowData.recojida : !rowData.tiempoRecogida &&  rowData.cart  ? "Sin tiempo"  : null },
//     { title: "Tipo", field: 'cart', render: rowData => rowData.cart  ? "Usuario" : "Domicilio"  },
//     { title: "Establecimiento", field: 'restaurantName' },
//     { title: "Barrio", field: 'cart', render: rowData => !rowData.cart  ? rowData.barrio.title : rowData.shippingAddress.area },
//     { title: "Fecha", field: 'createdAt' },
//     { title: "Responsable", field: 'DomicilioEncargado', render: rowData => !rowData.DomicilioEncargado  ? "No asignado" : rowData.DomicilioEncargado.nombreDomicilio },
//     { title: "Estado", field: 'status', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 16, color: rowData.status === "Pendiente" ? '#EF9701' : rowData.status === "Recibido" ? '#0099C5' : rowData.status === "Recogido" ? '#ffd500c7' : rowData.status === "Entregado" ? '#79AA00' : rowData.status === "Entregado" ? 'green' : rowData.status === "Entregado" ? '65079B' : rowData.status === "Cancelado" ? 'red': null }}>{rowData.status}</span> },
//     { title: "Total", field: 'grandTotal', render: rowData => <span>{rowData.paymentPropina && rowData.paymentPropina > 0 && !rowData.role  ? "$" + (rowData.grandTotal + rowData.paymentPropina).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : rowData.paymentPropina && rowData.paymentPropina > 0 && rowData.role  ? "$" + (rowData.barrio.valor).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : !rowData.paymentPropina  && !rowData.role  ? "$" + (rowData.grandTotal ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : !rowData.paymentPropina  && rowData.role  ? "$" + (rowData.barrio.valor ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'): ""}</span> },






//     // { title: language.booking_date, field: 'tripdate', render: rowData => rowData.tripdate ? new Date(rowData.tripdate).toLocaleString(dateStyle) : null },
//     // { title: language.booking_status, field: 'status', render: rowData => <span style={{ fontWeight: 'bold', fontSize: 16, color: rowData.status === "NEW" ? '#EF9701' : rowData.status === "ACCEPTED" ? '#0099C5' : rowData.status === "STARTED" ? '#ffd500c7' : rowData.status === "REACHED" ? '#79AA00' : rowData.status === "PAID" ? 'green' : rowData.status === "COMPLETE" ? '65079B' : rowData.status === "CANCELLED" ? 'red': null }}>{language[rowData.status]}</span> },
//     // { title: language.car_type, field: 'carType' },
//     // { title: language.customer_name, field: 'customer_name' },
//     // { title: language.pickup_address, field: 'pickupAddress' },
//     // { title: language.drop_address, field: 'dropAddress' },
//     // { title: language.assign_driver, field: 'driver_name' },
//     // // { title: language.trip_cost, field: 'trip_cost', render: rowData => <span>{"$"}</span> + rowData.trip_cost? (rowData.trip_cost).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'):null},
//     // { title: language.trip_cost, field: 'trip_cost', render: rowData => <span>{rowData.trip_cost ? "$" + rowData.trip_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
//     // { title: language.trip_start_time, field: 'trip_start_time' },
//     // { title: language.trip_end_time, field: 'trip_end_time' },
//     // // { title: language.total_time, field: 'total_trip_time' },
//     // { title: language.distance, field: 'estimateDistance', render: rowData => <span>{rowData.estimateDistance ? rowData.estimateDistance + " km" : ""}</span> },
//     // { title: language.vehicle_no, field: 'vehicle_number' },
//     // { title: language.trip_cost_driver_share, field: 'driver_share', render: rowData => <span>{rowData.driver_share ? "$" + rowData.driver_share.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
//     // { title: language.convenience_fee, field: 'convenience_fees', render: rowData => <span>{rowData.convenience_fees ? "$" + rowData.convenience_fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
//     // { title: language.discount_ammount, field: 'discount', render: rowData => <span>{rowData.discount ? "$" + rowData.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
//     // // { title: language.deliveryPerson, field: 'deliveryPerson' },
//     // { title: language.deliveryPersonPhone, field: 'deliveryPersonPhone' },
//     // // { title: language.pickUpInstructions, field: 'pickUpInstructions' },
//     // { title: language.deliveryInstructions, field: 'deliveryInstructions' },
//     // { title: language.parcel_type, render: rowData => <span>{rowData.parcelTypeSelected ? rowData.parcelTypeSelected.description + " (" + rowData.parcelTypeSelected.amount + ")" : ""}</span> },
//     // { title: language.parcel_option, render: rowData => <span>{rowData.optionSelected ? rowData.optionSelected.description + " (" + rowData.optionSelected.amount + ")" : ""}</span> },
//     // // { title: language.take_pickup_image,  field: 'pickup_image',render: rowData => rowData.pickup_image?<img alt='Pick Up' src={rowData.pickup_image} style={{width: 150}}/>:null, editable:'never'},
//     // // { title: language.take_deliver_image,  field: 'deliver_image',render: rowData => rowData.deliver_image?<img alt='Deliver' src={rowData.deliver_image} style={{width: 150}}/>:null, editable:'never'},
//     // { title: language.cancellation_reason, field: 'reason' },
//     // { title: language.otp, field: 'otp' },
//     // { title: language.Customer_paid, field: 'customer_paid', render: rowData => <span>{rowData.customer_paid ? "$" + rowData.customer_paid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
//     // { title: language.payment_mode, field: 'payment_mode' },
//     // { title: language.payment_gateway, field: 'gateway' },
//     // { title: language.cash_payment_amount, field: 'cashPaymentAmount', render: rowData => <span>{rowData.cashPaymentAmount ? "$" + rowData.cashPaymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
//     // { title: language.card_payment_amount, field: 'cardPaymentAmount', render: rowData => <span>{rowData.cardPaymentAmount ? "$" + rowData.cardPaymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
//     // { title: language.wallet_payment_amount, field: 'usedWalletMoney', render: rowData => <span>{rowData.usedWalletMoney ? "$" + rowData.usedWalletMoney.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ""}</span> },
//   ];
//   const { t } = useTranslation();
//   const settings = useSelector(state => state.settingsdata.settings);
//   const [data, setData] = useState([]);
//   const [openConfirm, setOpenConfirm] = useState(false);
//   const [selectedBooking, setSelectedBooking] = useState('');
//   const bookinglistdata = useSelector(state => state.bookinglistdata);
// //   console.log("bookinglistdata" , bookinglistdata)
//   const orderslistdata = useSelector(state => state.orderslistdata);
//   console.log("orderslistdata" , orderslistdata)

//   const theme = useTheme()

//   useEffect(() => {
//     if (orderslistdata.bookings) {
//       setData(orderslistdata.bookings);
//     } else {
//       setData([]);
//     }
//   }, [orderslistdata.bookings]);

//   useEffect(() => {
//     if (auth.info && auth.info.profile) {
//       setRole(auth.info.profile.usertype);
//     }
//   }, [auth.info]);

//   const onConfirmClose = (value) => {
//     if (value) {
//       dispatch(cancelBooking({
//         reason: value,
//         booking: selectedBooking
//       }));
//     }
//     setOpenConfirm(false);
//   }

//   return (
//     bookinglistdata.loading ? <CircularLoading /> :
//       <div style={{
//         maxWidth: '100%',
//         //  alignItems: 'center',
//         //  justifyContent: 'center',
//         //  display: 'flex'
//       }}>
//         <MaterialTable
//           title={"LLevo a Casa App"}
//           columns={columns}
//           data={data}
//           options={{
//             actionsColumnIndex: -1,
//             exportButton: settings.AllowCriticalEditsAdmin,
//             sorting: true,
//             pageSize:20,
//             headerStyle: {
//               backgroundColor: theme.palette.action.hover,
//               color: theme.palette.common.black,
//               fontWeight: 'bold',
//               textAlign: 'center',
//               flexDirection: 'row',
//               overflow: 'hidden',
//               textOverflow: 'ellipsis',
//             },
//             // paging: true,
//             // pageSize: 10,       // make initial page size
//             // emptyRowsWhenPaging: false,   // To avoid of having empty rows
//             // pageSizeOptions: [6, 12, 20, 50],    // rows selection options
//           }}
//           actions={[
//             rowData => ({
//               icon: 'cancel',
//               tooltip: "language.cancel_booking",
//               disabled: rowData.status === 'NEW' || rowData.status === 'ACCEPTED' || rowData.status === 'PAYMENT_PENDING' ? false : true,
//               onClick: (event, rowData) => {
//                 if (settings.AllowCriticalEditsAdmin && (role === 'rider' || role === 'admin')) {
//                   if (rowData.status === 'NEW' || rowData.status === 'ACCEPTED') {
//                     setSelectedBooking(rowData);
//                     setOpenConfirm(true);
//                   } else {
//                     setTimeout(() => {
//                       dispatch(cancelBooking({
//                         reason: t('cancelled_incomplete_booking'),
//                         booking: rowData
//                       }));
//                     }, 1500);
//                   }
//                 } else {
//                     alert(t('demo_mode'));
//                 }
//               }
//             }),
//           ]}
//         />
//         <ConfirmationDialogRaw
//           open={openConfirm}
//           onClose={onConfirmClose}
//           value={''}
//         />
//       </div>

//   );
// }

// export default BookingHistory;

