import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";

export default function Earningreports() {
  const { t } = useTranslation();

    const columns =  [
        { title: t('year'),field: 'year'},
        { title: t('months'), field: 'monthsName' },
        { title: t('Gross_trip_cost'), field: 'tripCost' , render: rowData => <span>{rowData.tripCost? "$" + rowData.tripCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') :""}</span> },
        { title: t('trip_cost_driver_share'), field: 'rideCost' , render: rowData => <span>{rowData.rideCost? "$" + rowData.rideCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') :""}</span> },
        { title: t('convenience_fee'), field: 'convenienceFee'  , render: rowData => <span>{rowData.convenienceFee? "$" + rowData.convenienceFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') :""}</span> },
        { title: 'Cantidad', field: 'count' , render: rowData => (rowData.count.length) },
        { title: t('Discounts'), field: 'discountAmount' , render: rowData => <span>{rowData.discountAmount? "$" + rowData.discountAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') :""}</span> },
        { title: t('Profit'),  render: rowData => "$" + ((rowData.convenienceFee) - (rowData.discountAmount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') , editable:'never'},
        
    ];

  const [data, setData] = useState([]);
  const earningreportsdata = useSelector(state => state.earningreportsdata);
  const theme = useTheme()

  useEffect(()=>{
        if(earningreportsdata.Earningreportss){
            setData(earningreportsdata.Earningreportss);
        }
  },[earningreportsdata.Earningreportss]);

  return (
    earningreportsdata.loading? <CircularLoading/>:
    <MaterialTable
      title={'Total x Domicilios'}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        exportAllData: true,
        headerStyle: {
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.common.black,
          fontWeight: 'bold',
          // textAlign: 'center',
          flexDirection: 'row',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      }}
      
    />
  );
}
