import React, {useRef} from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import LandingPage from "./views/LandingPage.js";
import LoginPage from "./views/LoginPage.js";
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import AboutUs from "./views/AboutUs";
import AuthLoading from './views/AuthLoading';
import { Provider } from "react-redux";
import ProtectedRoute from './views/ProtectedRoute';
import MyProfile from './views/MyProfile';
import BookingHistory from './views/BookingHistory';
import BookingHistoryPayment from './views/BookingHistoryPayment.js';
import OrdersHistory from './views/OrdersHistory';
import UsersApp from './views/UsersApp';
import Dashboard from './views/Dashboard';
import DashboardSector from './views/DashboardSector';
import CarTypes from './views/CarTypes';
import AddBookings from './views/AddBookings';
import Promos from './views/Promos';
import Bonos from './views/Bonos';
import Riders from './views/Riders';
import Drivers from './views/Drivers';
import FleetAdmins from './views/FleetAdmins';
import Notifications from './views/Notifications';
import Sms from './views/Sms';
import Counter from './views/Counter';
import SmsUsers from './views/SmsUsers';
import DriverEarning from './views/DriverEarning';
import DriverEarningorder from './views/DriverEarningorder';
import Earningreports from './views/Earningreports';
import Earningreportsorder from './views/Earningreportsorders';
import Settings from './views/Settings';
import Withdraws from './views/Withdraws';
import CancellationReasons from './views/CancellationReasons';
import Wp from './views/Wp';
import Lluvia from './views/Lluvia';
import BannerPpal from './views/BannerPpal';
import OrderValue from './views/OrderValue';
import Time from './views/Time';
import Sector from './views/Sector';
import Template from './views/TemplateSms';
import TemplateOneSignal from './views/TemplateOneSignal';
import RegisterPage from './views/RegisterPage';
import Invoice from './views/Invoice';
import AddMoney from "./views/AddMoney";
import { FirebaseProvider, store } from "common";
import { FirebaseConfig } from './config/FirebaseConfig';
import { GoogleMapApiConfig } from './config/GoogleMapApiConfig';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { AppCat } from './config/AppCat';
import UsersAppDelivery from "./views/UsersAppDelivery.js";
import UsersAppOneSignal from "./views/UsersAppOneSignal.js";
import OneSignalUsers from "./views/OneSignalUsers.js";
import UserDetail from "views/UserDetail.js";
import supercategorias from "./views/SuperCategorias.js";
import Filtros from "./views/Filtros";
import Payment from './views/Payment.js';

i18n
.use(initReactI18next) 
.init({
    resources: {},
    lng: "es",
    fallbackLng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
        escapeValue: false
    }
});

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

var hist = createBrowserHistory();

function App() {
  const loaded = useRef(false);
  if (typeof window !== 'undefined' && !loaded.current && !window.google) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=' + GoogleMapApiConfig + '&libraries=geometry,drawing,places',
        document.querySelector('head'),
        'google-maps',
      );
    }
    loaded.current = true;
  }

  return (
    <Provider store={store}>
      <FirebaseProvider config={FirebaseConfig} appcat={AppCat}>
        <AuthLoading>
          <Router history={hist}>
            <Switch>
              <ProtectedRoute exact component={BookingHistory} path="/bookings" permit={"rider,admin,adminPpal,driver,fleetadmin"} />
              <ProtectedRoute exact component={OrdersHistory} path="/orders" permit={"rider,admin,adminPpal,driver,fleetadmin"} />
              <ProtectedRoute exact component={BookingHistoryPayment} path="/bookingsPayment" permit={"rider,admin,adminPpal,driver,fleetadmin"} />
              <ProtectedRoute exact component={MyProfile} path="/profile" permit={"rider,admin,adminPpal,driver,fleetadmin"} />
              <ProtectedRoute exact component={Dashboard} path="/dashboard" permit={"admin,adminPpal,fleetadmin"} />
              <ProtectedRoute exact component={DashboardSector} path="/DashboardSector" permit={"admin,adminPpal,fleetadmin"} />
              <ProtectedRoute exact component={CarTypes} path="/cartypes" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Wp} path="/wp" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Lluvia} path="/lluvia" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={BannerPpal} path="/bannerppal" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={supercategorias} path="/supercategorias" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Filtros} path="/filtros" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={CancellationReasons} path="/cancelreasons" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={OrderValue} path="/ordervalue" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Time} path="/time" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Sector} path="/sector" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Template} path="/template" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={TemplateOneSignal} path="/templateonesignal" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={AddBookings} path="/addbookings" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Promos} path="/promos" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Bonos} path="/bonos" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Riders} path="/riders" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={UsersApp} path="/usersApp" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={UsersAppDelivery} path="/UsersAppDelivery" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={UsersAppOneSignal} path="/UsersAppOneSignal" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={OneSignalUsers} path="/onesignalusers" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Drivers} path="/drivers" permit={"admin,adminPpal,fleetadmin"} />
              <ProtectedRoute exact component={FleetAdmins} path="/fleetadmins" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={DriverEarning} path="/driverearning" permit={"admin,adminPpal,fleetadmin"} />
              <ProtectedRoute exact component={DriverEarningorder} path="/driverearningorder" permit={"admin,adminPpal,fleetadmin"} />
              <ProtectedRoute exact component={Notifications} path="/notifications" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Sms} path="/sms" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Counter} path="/counter" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={SmsUsers} path="/smsusers" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Earningreports} path="/earningreports" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Earningreportsorder} path="/earningreportsorder" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={AddMoney} path="/addtowallet" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Withdraws} path="/withdraws" permit={"admin,adminPpal"} />
              <ProtectedRoute exact component={Settings} path="/settings" permit={"admin,adminPpal"} />
              <Route path="/userDetail" component={UserDetail} />
              <Route path="/invoice" component={Invoice} />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/payment" component={Payment} />
              <Route path="/register" component={RegisterPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/" component={LandingPage} />
            </Switch>
          </Router>
        </AuthLoading>
      </FirebaseProvider>
    </Provider>
  );
}

export default App;